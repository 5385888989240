import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy  } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { take } from 'rxjs/operators';
import { Lender } from '@lender/lender.model';
import { LenderService } from '@lender/lender.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import {  ProductosService } from '../../../catalogos/productos/productos.service';
import { urlValidator } from '../../../shared/form-validation/validators';
import { Producto } from '../modelos/productos.model';

import { TutorialesService } from '../tutoriales.service'; // Cambia la ruta según tu proyecto
import { Tutorial } from '../modelos/tutorial.model';

@Component({
  selector: 'app-tutoriales-editar',
  templateUrl: './tutoriales-editar.component.html',
  styleUrls: ['./tutoriales-editar.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class TutorialesEditarComponent implements OnInit {
  title: string = '';
  public form: FormGroup;
  public lenders: Lender[];
  public saveButton = false;
  public hoy = new Date();
  public dataSource: any[] = [];
  public edit = false;
  idProducto: number = 0;
  productos: Producto[] = [];
  idTipoProducto: number = 0;
  idProductoYNombre: string | null = null;
  loadings: any = {
    productos: true,
    detalles: false,
    guardando: false
  }
  tiposContenido = null;
  tiposContenidoArray: { id: number; nombre: string }[] = [];
  private valoresOriginales: string = '';

  constructor(
    private fb: FormBuilder ,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private lenderService: LenderService,
    private app: ApplicationService,
    private productosService: ProductosService,
    private tutorialesService: TutorialesService
  ) {
    this.form = this.createForm(); // Llama al método para crear el formulario
  }

  ngOnInit() {
    this.loadLenders();
    this.loadTiposContenido();
     // Verificar si es edición o creación
    this.route.queryParams.subscribe((params) => {
    const idTutorial = params['idTutorial'];
      if (idTutorial) {
        this.edit = true;
        this.loadTutorialData(params); // Cargar datos del tutorial y detalles
      }
    });
    
    this.dataSource = this.detalles.controls;
    this.valoresOriginales = this.convertirObjetoABase64(this.form.getRawValue());
  }

  validarGuardar(): boolean {
    const valoresActuales = this.convertirObjetoABase64(this.form.getRawValue());
    return this.valoresOriginales !== valoresActuales;
  }

  private convertirObjetoABase64(obj: any): string {
    const jsonString = JSON.stringify(obj); // Convierte el objeto a JSON
    return btoa(jsonString); // Convierte JSON a Base64
  }

  private createForm(): FormGroup {
    return this.fb.group({
      idTutorial: null,
      idFinanciera: [null, Validators.required],
      idCliente: [null, Validators.required],
      idProducto: [null, Validators.required],
      titulo: [null, [Validators.required, Validators.maxLength(100)]],
      detalles: this.fb.array([this.createDetalleGroup()]) // Llama al método para crear un grupo de detalle
    });
  }

  private createDetalleGroup(): FormGroup {
    return this.fb.group({
      orden: [1, Validators.required],
      encabezado: [null], // Encabezado es opcional
      idTutorialTipoContenido: [1, Validators.required], // Tipo de contenido es obligatorio
      contenidoTexto: [null, [Validators.required, Validators.maxLength(255)]], // Validado según tipoContenido
      urlContenido: [null, [urlValidator('imagen'), Validators.maxLength(255)]], // Validado según tipoContenido
      activo: [true], // Por defecto activo
    });
  }
  

  loadTutorialData(params: any): void {
    const { idTutorial, titulo,  idCliente, idFinanciera, idProducto, nombreProducto, nombreCliente } = params;
     // Actualizar el formulario con los datos del tutorial
    this.form.patchValue({
      idTutorial: idTutorial,
      idFinanciera: idFinanciera,
      idCliente: idCliente,
      idProducto: idProducto,
      titulo: titulo,
    });
    // Cargar productos relacionados con el cliente
    if (idCliente) {
      this.loadProductos(idCliente);
    }
    //Cargar los detalles del tutorial
    this.loadDetallesTutorial(idCliente, idTutorial);
  }

  public loadDetallesTutorial(idCliente: number,idTutorial: number): void {
    if (!idTutorial) {
      this.app.showSnackbar('¡Aviso!', 'No se puede cargar los detalles: idTutorial no está definido.', 2000, 'warning');
      return;
    }
    this.loadings.detalles = true; // Mostrar el estado de carga
    this.tutorialesService.obtenerDetallesTutorial(idCliente, idTutorial)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          this.loadings.detalles = false;
          // Asegúrate de que res.data.detalles existe y es un arreglo
          const detalles = res.data?.detalles || [];
          if (!Array.isArray(detalles)) {
            this.app.showSnackbar('¡Aviso!', 'La propiedad detalles no es un arreglo', 2000, 'warning');
            return;
          }
           // Procesar los detalles y actualizarlos en el formulario
          this.detalles.clear(); // Limpiar detalles existentes
          detalles.forEach((detalle: any) => {
            this.detalles.push(this.fb.group({
              idTutorialDetalle: [detalle.idDetalle],
              orden: [detalle.orden, Validators.required],
              encabezado: [detalle.encabezado],
              idTutorialTipoContenido: [detalle.tipoContenido, Validators.required],
              contenidoTexto: [detalle.contenidoTexto || null, detalle.tipoContenido === 1 ? Validators.required : ''],
              urlContenido: [detalle.urlContenido || null, urlValidator(detalle.tipoContenido === 2 ? 'imagen' : 'general')]
            }));
          });
          // Sincronizar el DataSource
          this.dataSource = [...this.detalles.controls];
          this.ordenarDetalles();
        },
        (err: any) => {
          this.loadings.detalles = false;
          this.app.showSnackbar('¡Aviso!', 'Error al cargar los detalles del tutorial', 2000, 'warning');
          return;
        }
      );
  }

  ordenarDetalles(): void {
    // Obtener los controles actuales del FormArray
    const detalles = this.detalles.controls;
    // Ordenar los controles basados en el valor de `orden`
    detalles.sort((a, b) => {
      const ordenA = a.get('orden')?.value || 0;
      const ordenB = b.get('orden')?.value || 0;
      return ordenA - ordenB; // Orden ascendente
    });
    // Actualizar el índice de `orden` después de la ordenación
    this.detalles.controls.forEach((detalle, index) => {
      detalle.patchValue({ orden: index + 1 });
    });
    // Sincronizar el `dataSource` con los cambios
    this.dataSource = [...this.detalles.controls];
    // Forzar la detección de cambios para actualizar la vista
    this.cdr.detectChanges();
  }

  getTipoContenidoTexto(tipoId: number): string {
    const tipo = this.tiposContenido.find(t => t.id === tipoId);
    return tipo ? tipo.nombre : ''; // Retorna el nombre o una cadena vacía si no coincide
  }

  onSelectProduct(idProducto: number): void {
    let index: number = this.productos.findIndex(x=> x.idProducto == idProducto);
    if(index >=0){
      let producto: any = this.productos[index];
      this.idTipoProducto = producto.idTipoProducto;
      this.idProductoYNombre = producto.nombre + " (Promesa de pago)";
    }
    else{
      this.idTipoProducto = 0;
      this.idProductoYNombre = null;
    }
  }

  onSelectCliente(idFinanciera: any): void {
    const clienteSeleccionado = this.lenders.find(lender => lender.id === idFinanciera);
    if (clienteSeleccionado) {
      this.form.get('idCliente')?.patchValue(clienteSeleccionado.idCliente, { emitEvent: false });
      // Cargar productos relacionados con el cliente
      if(!this.edit){
        this.loadProductos(clienteSeleccionado.idCliente);
      }
    }
  }

  loadLenders(): void {
    this.lenderService.getAll('all').subscribe((lenders) => {
      lenders.sort(this.compare);
      this.lenders = lenders;
    }, (err: any) => {
      this.app.showError(err);
    });
  }

  public loadTiposContenido(): void {
    this.loadings.tutoriales = true;
    this.tutorialesService.obtenerTiposContenido()
    .pipe(
        take(1)
    ).subscribe((res: any) => {
        this.loadings.tutoriales = false;
        this.tiposContenido = res.data || [];
    }, (err: any) => {
        this.loadings.tutoriales = false;
        this.tiposContenido = [];
        this.app.showError(err);
    });
  }

  loadProductos(idCliente : number){
    this.loadings.productos = true;
    this.productosService.obtenerProductosPorCliente(idCliente)
    .pipe(
        take(1)
    ).subscribe((res: any) => {
        this.loadings.productos = false;
        res.data.forEach(element => {
          element.idProductoYNombre = element.idProducto + " - " + element.nomProducto;
        });
        this.productos = res.data;
    }, (err: any) => {
        this.loadings.productos = false;
        this.app.showError(err);
    });
  }

  // Accede al FormArray de detalles
  get detalles(): FormArray {
    return this.form.get('detalles') as FormArray;
  }
  // Método para convertir AbstractControl a FormControl
  asFormControl(control: AbstractControl): FormControl {
    return control as FormControl;
  }

   // Método para crear un nuevo detalle
  nuevoDetalle(): FormGroup {
    return this.fb.group({
      orden: [this.detalles.length + 1, Validators.required],
      encabezado: [null], // Encabezado es obligatorio
      idTutorialTipoContenido: [1, Validators.required], // Tipo de contenido es obligatorio
      contenidoTexto: [null, [Validators.required, Validators.maxLength(255)]], // Opcional, validado según tipoContenido
      urlContenido: [null], // Opcional, validado según tipoContenido
      activo: [true], // Por defecto activo
    });
  }
  // Agrega un nuevo detalle al FormArray
  agregarDetalle(): void {
    this.detalles.push(this.nuevoDetalle());
    this.dataSource = [...this.detalles.controls]; // Asegurar la referencia
    this.ordenarDetalles();
  }

  // Elimina un detalle del FormArray
  eliminarDetalle(index: number): void {
    // Remover el elemento del FormArray
    this.detalles.removeAt(index);

    // Actualizar el orden después de eliminar
    this.actualizarOrden();

    // Sincronizar el dataSource con el FormArray
    this.dataSource = [...this.detalles.controls];

    // Forzar la detección de cambios para actualizar la vista
    this.ordenarDetalles();
  }

  // Actualiza el orden de los detalles
  actualizarOrden(): void {
    this.detalles.controls.forEach((detalle, index) => {
      detalle.patchValue({ orden: index + 1 });
    });
  }

  // Maneja el evento Drag and Drop
  drop(event: CdkDragDrop<FormGroup[]>): void {
    const detalles = this.detalles.controls;

    // Reordenar el FormArray
    const movedItem = detalles[event.previousIndex];
    detalles.splice(event.previousIndex, 1); // Eliminar el elemento anterior
    detalles.splice(event.currentIndex, 0, movedItem); // Insertar en la nueva posición
    // Actualizar los índices y sincronizar
    this.actualizarOrden();

    // Sincroniza el DataSource con el nuevo orden del FormArray
    this.dataSource = [...detalles];

    // Forzar detección de cambios para reflejar el nuevo orden en la interfaz
    this.cdr.detectChanges();

  }

  cancel() {
    const currentParams = this.route.snapshot.queryParams;
    const fParam = currentParams['f'];
    // Eliminamos todos los parámetros existentes y luego agregamos el parámetro 'f'
    this.router.navigate(['/catalogos/tutoriales'], {
      queryParams: { f: fParam }
    });
  }

  actualizarValidaciones(indice: number): void {
    const detalle = this.detalles.at(indice);
    const tipoContenidoId = detalle.get('idTutorialTipoContenido')?.value;
    const tipoContenido = this.getTipoContenidoTexto(tipoContenidoId);
    if (tipoContenido === 'text') {
      detalle.get('contenidoTexto')?.setValidators([Validators.required, Validators.maxLength(255)]);
      detalle.get('urlContenido')?.clearValidators();
    } else if (tipoContenido === 'imagen') {
      detalle.get('urlContenido')?.setValidators([Validators.required, urlValidator('imagen'), Validators.maxLength(255)]);
      detalle.get('contenidoTexto')?.clearValidators();
      detalle.get('contenidoTexto').setValue('');
    } else if (tipoContenido === 'video') {
      detalle.get('urlContenido')?.setValidators([Validators.required, urlValidator('general'), Validators.maxLength(255)]);
      detalle.get('contenidoTexto').setValue('');
      detalle.get('contenidoTexto')?.clearValidators();
    } 
      else {
      detalle.get('contenidoTexto')?.clearValidators();
      detalle.get('urlContenido')?.clearValidators();
    }
    detalle.get('contenidoTexto')?.updateValueAndValidity();
    detalle.get('urlContenido')?.updateValueAndValidity();
  }

  // Envía el formulario
  onSubmit(): void {
    
    if(!this.form.value.idFinanciera){
      this.app.showSnackbar('¡Aviso!', 'Es necesario capturar el cliente.', 2000, 'warning');
      return;
    }
    if(!this.form.value.idProducto){
      this.app.showSnackbar('¡Aviso!', 'Es necesario capturar el producto.', 2000, 'warning');
      return;
    }
    if(!this.form.value.titulo){
      this.app.showSnackbar('¡Aviso!', 'Es necesario capturar el titulo.', 2000, 'warning');
      return;
    }
    // Validar detalles
    this.detalles.markAllAsTouched();
    // Validar que haya al menos un detalle
    if (this.detalles.length === 0) {
      this.app.showSnackbar('¡Aviso!', 'Debe haber al menos un detalle en el tutorial.', 2000, 'warning');
      return;
    }
    // Validar que los detalles sean válidos
    if (this.detalles.invalid) {
      this.app.showSnackbar('¡Aviso!', 'Revise los detalles, hay campos inválidos.', 2000, 'warning');
      return;
    }
    // Validar que la url es valida
    if (this.detalles.hasError('invalidUrl')) {
      this.app.showSnackbar('¡Aviso!', 'Debe ser una url valida.', 2000, 'warning');
      return;
    }
    if(!this.form.valid){
      this.app.showSnackbar('¡Aviso!', 'El formulario tiene algún dato faltante o requerido.', 2000, 'warning');
      return;
    }
    if(this.edit){
      this.updateTutorial(this.form.value.idCliente, this.form.value.idTutorial, this.form.value);
    }else{
      this.saveTutorial(this.form.value);
    }
  }

  enforceMaxLength(event: Event, fieldName: string, maxLength: number): void {
    const inputElement = event.target as HTMLInputElement | HTMLTextAreaElement;
    const value = inputElement.value;
  
    if (value.length > maxLength) {
      // Truncar el texto al límite permitido
      inputElement.value = value.slice(0, maxLength);
  
      // Actualizar el control en el formulario
      if (this.form.get(fieldName)) {
        this.form.get(fieldName)?.setValue(inputElement.value); // Para campos simples como 'titulo'
      } else {
        // Buscar dentro del FormArray de detalles
        const control = this.detalles.controls.find(ctrl => ctrl.get(fieldName));
        if (control) {
          control.get(fieldName)?.setValue(inputElement.value); // Para campos del FormArray
        }
      }
    }
  }
  
  

  saveTutorial(tutorial: Tutorial): void {
    this.loadings.guardando = true; // Mostrar loading si aplica
    this.tutorialesService.nuevoTutorial(tutorial.idCliente, tutorial).subscribe(
      (response) => {
        this.app.showSnackbar('¡Éxito!', 'El tutorial fue guardado correctamente.', 2000, 'success');
         // Redirigir al listado de tutoriales
        this.router.navigate(['/catalogos/tutoriales']);
      },
      (error) => {
        this.app.showSnackbar('¡Aviso!', 'Es necesario capturar el titulo.', 2000, 'warning');
      }
    );
  }

  updateTutorial(idCliente: number, idTutorial: number, tutorial: Tutorial): void {
    this.tutorialesService.actualizarTutorial(idCliente, idTutorial, tutorial).subscribe(
      (response) => {
        this.app.showSnackbar('¡Éxito!', 'El tutorial fue actualizado correctamente.', 2000, 'success');
      },
      (error) => {
        this.app.showSnackbar('¡Error!', 'No se pudo actualizar el tutorial. Intenta de nuevo.', 2000, 'error');
      }
    );
  }
  
  checkControl(control: string) {
    return this.form.controls[control].invalid && this.form.controls[control].touched;
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'required': true };
  }

  private compare(a, b) {
    const nombreA = a.nombre.toUpperCase();
    const nombreB = b.nombre.toUpperCase();
    let comparison = 0;
    if (nombreA > nombreB) {
      comparison = 1;
    } else if (nombreA < nombreB) {
      comparison = -1;
    }
    return comparison;
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  getErrorMessage(controlName: string, indice: number): string {
    const detalle = this.detalles.at(indice);
    const control = detalle.get(controlName);
    const tipoContenido = this.getTipoContenidoTexto(detalle.get('idTutorialTipoContenido')?.value);
    if (control?.hasError('required')) {
      return 'Este campo es requerido.';
    }
    if (control?.hasError('invalidUrl')) {
      if (tipoContenido === 'imagen') {
        return 'La URL debe ser válida de Google Drive.';
      } else if (tipoContenido === 'video') {
        return 'La URL debe ser válida y corresponder a un formato de video soportado (youtube, vimeo, etc.)';
      }
    }
    return '';
  }
}
