<h1 mat-dialog-title>Sucursales del cliente: {{data.nombreCliente}}</h1>
<mat-dialog-content>



  <mat-select-search 
    class="id-card-field" 
    [label]="'Sucursal'" 
    [placeHolder]="'Selecciona una sucursal'"
    [value]="'idSucursal'" 
    [labelField]="'nombreSucursal'" 
    [icon]="'store'" 
    [options]="data.sucursales"
    [(ngModel)]="selectedSucursal" 
    [loader]="loaderSucursales">
  </mat-select-search>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="boton-cancelar" (click)="onNoClick()"><mat-icon
      class="kobra-icon">close</mat-icon>Cancelar</button>
  <button mat-stroked-button class="boton-guardar"
    (click)="actualizaSucursal()"><mat-icon>save</mat-icon>Guardar</button>
</mat-dialog-actions>