<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; inactivarHijo();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros"
            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            matTooltip="Filtrar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
            <span *ngIf="idLender" class="notification-dot-filtros notification-dot-accion">1</span>

          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            (click)="getSaldosPaquetesFinancieras(1);"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda"
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda;">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <mat-menu #menu="matMenu">
      <button mat-menu-item [disabled]="itemsSelected.length !== 1"  (click)="onDetalle(itemsSelected[0])">
        <mat-icon class="kobra-icon">edit</mat-icon>
        <span>Editar</span>
      </button>
      <button mat-menu-item (click)="openConfiguration()" [disabled]="this.itemsSelected?.length !== 1">
        <mat-icon class="kobra-icon">settings</mat-icon>
        <span>Configuración</span>
      </button>
    </mat-menu>

    <app-table
      [classContainer]="'tabla-completa'"
      [loading]="cargandoLista"
      [columns]="tabla.columns"
      [items]="paquetes"
      [headers]="tabla.headers"
      [multiSelect]="tabla.multiSelect"
      (sortChange)="setSortField($event)"
      (onSelectedRows)="onSelected($event)"
      (onDoubleClick)="onDoubleClick($event)"
      >

      <div t-header>

      <mat-card *ngIf="ayuda" class="cardAyuda">
        <mat-card-content>
          <p><mat-icon color="primary">help</mat-icon>
            El módulo "Paquetes Clientes" es una herramienta esencial para monitorear y gestionar los paquetes contratados por
            los clientes, así como su saldo disponible y paquete actual que tienen contratado.

            Además, al seleccionar el detalle de cada cliente, los usuarios pueden ver un histórico completo de los paquetes
            contratados y el consumo que ha tenido cada cliente a lo largo del tiempo. Este registro histórico es invaluable
            para entender las tendencias de consumo, las preferencias y las necesidades cambiantes de los clientes.

            Otro aspecto importante de esta vista es la capacidad de editar los precios por cada concepto que existe en el
            paquete actual.
          </p>
          Acciones:
          <p>
            <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda permitiendo aplicar filtros
            basados en diversos criterios.
          </p>
          <p>
            <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de
            filtros aplicados.
          </p>

          <p>
            <mat-icon class="kobra-icon">edit</mat-icon><b> Editar: </b> Esta acción permite la actualización y modificación de
            los paquetes contratados y sus conceptos.
          </p>
        </mat-card-content>
      </mat-card>
      </div>

      <ng-container t-paginator>
        <mat-paginator
          showFirstLastButtons
          [length]="pagination?.totalRows"
          [pageIndex]="pagination?.page-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button>
    Filtros
  </mat-toolbar>
  <div class="accordion-filtros">
    <mat-accordion [multi]="false">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          Súper Administrador
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="'Cliente'"
              [placeHolder]="'Selecciona un cliente'"
              [value]="'idFinanciera'"
              [labelField]="'name'"
              [icon]="'account_balance'"
              [options]="lenders"
              [(ngModel)]="idLender"
            >
            </mat-select-search>

          </li>
        </ol>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <ol class="lista-filtros-buttons">
    <button
      style="width: 100%;"
      mat-stroked-button
      class="boton-accion-front"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; filterModal(true);">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="filterModal(false)">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>
