import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { PaqueteVisitasPreciosService } from 'src/app/paquete-visitas-precios/paquete-visitas-precios.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { map, take, catchError } from 'rxjs/operators';
import { PaquetesSaldosService } from '../paquetes-saldos/paquetes-saldos.service';

@Component({
  selector: 'app-paquetes-saldos-conceptos',
  templateUrl: './paquetes-saldos-conceptos.component.html',
  styleUrls: ['./paquetes-saldos-conceptos.component.css'],
  providers: [PaqueteVisitasPreciosService]
})
export class PaquetesSaldosConceptosComponent implements OnInit {
  public idPaquete: number;
  public paquetesConceptosForm: FormGroup;
  public items: FormArray;
  public conceptos: Array<any>;
  public conceptosAgregados: Array<any>;
  public saveButton = false;
  public cargandoLista = false;
  public loaderText = 'Cargando información...';
  public cargando = false;
  cargarPaquete = false;

  tabla: any = {
    headers: false,
    tableHeaders: false,
    multiSelect: false,
    columns: [
        {
            name: 'Concepto',
            key: 'nombre',
            hide: false
        }, {
            name: 'Precio',
            key: 'precio',
            hide: false,
            type: 'money'
        }, {
          name: ' ',
          key: 'eliminar',
          hide: false,
          icono:'delete_forever',
          tooltip: "Eliminar",
          type: 'icono',
      }
    ]
  }

  constructor(
    private acRoute: ActivatedRoute,
    private fb: FormBuilder,
    private paqueteVisitasPreciosService: PaqueteVisitasPreciosService,
    private app: ApplicationService,
    private saldosKobraServ: ServiciosSaldosKobraService,
    private paquetesSaldosService: PaquetesSaldosService,
    private router: Router
  ) {
    this.paquetesConceptosForm = this.fb.group({
      'nombre': ['', [Validators.required, Validators.maxLength(40)]],
      'precioPaquete': ['', Validators.required],
      'idConcepto': '',
      'precioConcepto': '',
      items: this.fb.array([])
    });
    this.conceptos = [];
    this.conceptosAgregados = [];
  }

  ngOnInit() {
    this.init();
  }

  init() {
    let paqueteId = this.acRoute.snapshot.queryParamMap.get('idPaquete');


    if( paqueteId !== null ) {
      this.idPaquete = +paqueteId;
    }

    this.cargarDatosEditar();
  }

  onClick({ row, columnPressed }){
    if(columnPressed == 'eliminar'){
      this.borrarConcepto(row);
    }
  }

  checkControl(control: string) {
    return this.paquetesConceptosForm.controls[control].invalid && this.paquetesConceptosForm.controls[control].touched;
  }

  cargarDatosEditar() {

    this.cargarConceptos();

    if (this.idPaquete >= 0) {
      this.cargarPaquete = true;
      this.paquetesSaldosService.obtenerPaquete(this.idPaquete)
        .pipe(
          take(1)
        ).subscribe((res: any) => {
          this.paquetesConceptosForm.controls['nombre'].setValue(res.data.nombre);
          this.paquetesConceptosForm.controls['precioPaquete'].setValue(res.data.precio);
          this.paquetesConceptosForm.controls['nombre'].enable();
          this.paquetesConceptosForm.controls['precioPaquete'].enable();
          if (this.idPaquete === 0) {
            this.paquetesConceptosForm.controls['nombre'].disable();
            this.paquetesConceptosForm.controls['precioPaquete'].disable();
          }
          this.cargarPaquete = false;
        }, (error: any) => {
          this.cargarPaquete = false;
          this.app.showError(error);
        });

      this.cargandoLista = true;
      this.paqueteVisitasPreciosService.obtenerPaqueteVisitasPrecios(this.idPaquete).subscribe(paquetesVisitasPrecios => {
        this.items = this.paquetesConceptosForm.get('items') as FormArray;

        this.conceptosAgregados = paquetesVisitasPrecios;
        this.conceptos = this.conceptos.filter(concepto =>
          !this.conceptosAgregados.some(agregado => agregado.idConcepto === concepto.idConcepto)
        );
        this.cargandoLista = false;
      },
        error => {
          this.cargandoLista = false;
          this.app.showError(error);
        });
    }
  }

  cargarConceptos() {
    this.cargando = true;
    this.saldosKobraServ.get('/conceptos-para-paquetes')
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.conceptos = res.data;
        this.conceptos = this.conceptos.filter(concepto => {
          return !this.conceptosAgregados.some(agregado => agregado.idConcepto === concepto.idConcepto);
        });
        this.conceptos.sort((a, b) => a.nombreConcepto.localeCompare(b.nombreConcepto));
        this.cargando = false;
      }, (error: any) => {
        this.app.showError(error);
        this.cargando = false;
      });
  }

  getErrorMessage(control: string) {
    return this.paquetesConceptosForm.controls[control].hasError('required') ? 'Este campo es requerido.' :
      this.paquetesConceptosForm.controls[control].hasError('maxlength') ? 'Sólo se permiten 40 caracteres.' : '';
  }

  agregarConcepto() {
    let msg = null;
    var precioNumRE = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;

    if( !this.paquetesConceptosForm.controls['idConcepto'].value ) {
      msg = 'Selecciona un concepto.';
    } else if( !this.paquetesConceptosForm.controls['precioConcepto'].value ) {
      msg = 'Ingresa el precio del concepto.';
    }

    if( msg ) {
      return this.app.showSnackbar('¡Aviso!', msg, 3000, 'warning');
    }

    let idxConceptos = this.conceptos.findIndex(x => x.idConcepto === +this.paquetesConceptosForm.controls['idConcepto'].value);
    let idxConceptosAgregados = this.conceptosAgregados.findIndex(x => x.idConcepto === +this.paquetesConceptosForm.controls['idConcepto'].value);

    if( idxConceptosAgregados >= 0 ) {
      return this.app.showSnackbar('¡Aviso!', 'Este concepto ya fue agregado.', 3000, 'warning');
    }

    const array = {
      'idConcepto': +this.paquetesConceptosForm.controls['idConcepto'].value,
      'nombre': this.conceptos[idxConceptos].nombreConcepto,
      'precio': +this.paquetesConceptosForm.controls['precioConcepto'].value,
    }
    this.conceptosAgregados = [...this.conceptosAgregados, array];

    this.conceptos.splice(idxConceptos, 1);

    this.paquetesConceptosForm.controls['idConcepto'].setValue('');
    this.paquetesConceptosForm.controls['precioConcepto'].setValue('');
  }

  borrarConcepto(registroSeleccionado: any) {
   // console.log(registroSeleccionado);
    this.conceptosAgregados = this.conceptosAgregados.filter((item) => item.idConcepto !== registroSeleccionado.idConcepto);

    if (registroSeleccionado) {
      const array = {
        'idConcepto': +registroSeleccionado.idConcepto,
        'nombreConcepto': registroSeleccionado.nombre,
      }
      this.conceptos.push(array);
    }

    this.conceptos.sort((a, b) => a.nombreConcepto.localeCompare(b.nombreConcepto));
  }

    onSubmit() {
        if( !this.conceptosAgregados.length ) {
            return this.app.showSnackbar('¡Aviso!', 'Debe agregar conceptos para este paquete.', 3000, 'warning');
        }

        let paquete = {
            nombre: this.paquetesConceptosForm.controls['nombre'].value,
            precio: this.paquetesConceptosForm.controls['precioPaquete'].value,
            conceptos: this.conceptosAgregados
        };

        this.saveButton = true;

        if( this.idPaquete !== 0 && !(this.idPaquete > 0) ) {
            this.agregarPaquete(paquete);
        } else {
            this.editarPaquete(paquete);
        }
    }

  agregarPaquete(paquete: any) {
    const loading = this.app.showLoading(
      `Guardando información...`
    );
    this.paquetesSaldosService.agregarPaquete(paquete)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.saveButton = false;
        this.app.showSnackbar('¡Aviso!', 'Paquete guardado correctamente.', 3000, 'success');
        this.app.hideLoading(loading);
        this.router.navigate([`catalogos/paquetes`], {
          queryParamsHandling: ''
        });
      }, (error: any) => {
        this.saveButton = false;
        this.app.hideLoading(loading);
        this.app.showError(error);
      });
  }

  editarPaquete(paquete: any) {
    this.paquetesSaldosService.editarPaquete(this.idPaquete, paquete)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.saveButton = false;
        this.app.showSnackbar('¡Aviso!', 'Paquete guardado correctamente.', 3000, 'success');

        this.router.navigate([`catalogos/paquetes`], {
          queryParamsHandling: ''
        });
      }, (error: any) => {
        this.saveButton = false;
        this.app.showError(error);
      });
  }
}
