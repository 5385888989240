<!-- <div [@routeAnimations]="prepareRoute(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
  </div> -->
<div class="app content kobra">

    <div class = "contenedorDetalleCuentaGeneral2">
        <div class="contenedorNombreFinanciera">
            <span class="nombreCliente"> {{client.name}} {{client.apellido_p}} {{client.apellido_m}}</span>
            <span class="nombreFinanciera">
                {{client.nombreFinanciera}}
            </span>
            <div style="margin-top: 5px;">
                <span class="idExterno"> {{idExterno}}</span>
                <span class="nombreFinanciera">
                    {{subIdExterno}}
                </span>
            </div>
        </div>
        <!-- <div style="margin-top: 5px" *ngIf="account.nombreGrupo">
            <span style="font-size: large; color: gray;">
                Grupo:
            </span>
             <span  style="color: gray;">
                {{account.nombreGrupo}}
            </span>
            <span class="etiqueta" *ngIf="nombreEtiquetasCuenta">{{nombreEtiquetasCuenta}}</span>
        </div> -->
        <div class="contenedorCuentaProducto">
            <span class="nombreProducto" style="margin-right: 20px;">
                {{account.product.name}}
            </span>
            <span class="etiqueta" *ngIf="nombreEtiquetasCuenta">{{nombreEtiquetasCuenta}}</span>
        </div>
        <!-- *ngIf="subirContratoAcreditado -->
        <div class="button-container-wrapper button-container">
            <div class="button-toggle-group-container right">
                <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" class="right-align">
                    <mat-button-toggle value="subMenu"
                        matTooltip="Acciones"
                        mat-tooltip-panel-above
                        [matMenuTriggerFor]="menu">
                        <mat-icon class="kobra-icon">more_vert</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <!-- <button mat-icon-button
                        vvalue="subMenu"
                        matTooltip="Acciones"
                        mat-tooltip-panel-above
                        [matMenuTriggerFor]="menu" >
                    <mat-icon class="kobra-icon">more_vert</mat-icon>
                </button> -->
            </div>
            <mat-menu #menu="matMenu">
                <button mat-menu-item
                    *ngIf="subirContratoAcreditado"
                    (click)="openDialogContracto()">
                    <mat-icon>picture_as_pdf</mat-icon>Contratos
                </button>
                <button mat-menu-item
                    [disabled]="!isFormatos"
                    (click)="openDialogFormatos()">
                    <mat-icon>description</mat-icon>Formatos
                </button>
                <button mat-menu-item
                    [disabled]="loadingProducts || loadingEtiquetasCuenta || !clienteActivo"
                    (click)="openDialogEtiqueta()">
                    <mat-icon class="kobra-icon">sync</mat-icon>Actualizar cuenta
                </button>
                <button mat-menu-item
                    [disabled]="!clienteActivo"
                    (click)="openDialogCuentaSucursal()">
                    <mat-icon class="kobra-icon">sync</mat-icon>Asignar sucursal
                </button>
                <button mat-menu-item
                    [disabled]=" sucursalCuenta == null"
                    (click)="openDialogDesvinculacion()">
                    <mat-icon class="kobra-icon">link_off</mat-icon>Desasignar sucursal
                </button>
            </mat-menu>
        </div>
    </div>

    <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="400px">
        <mat-grid-tile colspan="1" rowspan="1" >
            <mat-card class="alturaMaximaCard anchoCard kobra-card margenRight">
                <mat-card-header>
                    <mat-card-title>Información personal</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="contenidoConScroll detalle" *ngIf="!editingPersonalInfo && !cargandoInformacionPersonal">
                        <div class="row">
                            <div class="column">
                                <mat-label class="negrita">Nombre</mat-label>
                                <p>{{client?.name}}</p>
                            </div>
                            <div class="column">
                                <mat-label class="negrita">Apellido paterno</mat-label>
                                <p>{{client?.apellido_p}}</p>
                            </div>
                            <div class="column">
                                <mat-label class="negrita">Apellido materno</mat-label>
                                <p>{{client?.apellido_m}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <mat-label class="negrita">{{labelCurp}}</mat-label>
                                <p>{{client?.curp}}</p>
                            </div>
                            <div class="column">
                                <mat-label class="negrita">{{labelRFC}}</mat-label>
                                <p>{{client?.rfc}}</p>
                            </div>
                            <div class="column">
                                <mat-label class="negrita">Fecha de nacimiento</mat-label>
                                <p>{{client?.fechaNacimiento | date:'dd/MM/yyyy'}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <mat-label class="negrita">Teléfono celular</mat-label>
                                <p>{{client?.telefonoCelular}}</p>
                            </div>
                            <div class="column">
                                <mat-label class="negrita">Teléfono fijo</mat-label>
                                <p>{{client?.telefonoFijo}}</p>
                            </div>
                            <div class="column" *ngIf="pais=='MX'">
                                <mat-label class="negrita">INE</mat-label>
                                <p>{{client?.claveElectoralIfe}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <mat-label class="negrita">Correo</mat-label>
                                <p>{{client?.email}}</p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="editingPersonalInfo">
                        <detail-form [client]="client"
                        (onCancel)="editingPersonalInfo = !editingPersonalInfo"
                        (onSave)="savePersonalInformation($event)">
                        </detail-form>
                    </div>
                </mat-card-content>
                <div *ngIf="!editingPersonalInfo && !cargandoInformacionPersonal && clienteActivo">
                  <mat-divider inset ></mat-divider>
                <div mat-card-actions align="right" style="margin-top: 15px; margin-right: 15px;">
                    <button  *ngIf="verificarEstatus()" mat-stroked-button (click)="habilitarBtn(true);">
                        <mat-icon>edit</mat-icon>Editar
                    </button>
                </div>
                </div>
                <mat-card-footer *ngIf="cargandoInformacionPersonal" class="kobra-card-footer">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card-footer>
              </mat-card>
        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="alturaMaximaCard anchoCard kobra-card anchoCard margenLeft">
                <mat-card-header>
                    <mat-card-title>Detalles de la cuenta</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="ui form contenidoConScroll detalle" *ngIf="!editarCuentaInfo && !loadingAccountDetail">
                        <h3 *ngIf="account.nombreGrupo || sucursalCuenta?.nombreSucursal ">General</h3>
                        <div class="detalle-grid">
                            <div *ngIf="account.nombreGrupo"><strong>Grupo:</strong> {{account.nombreGrupo}}</div>
                            <div *ngIf="sucursalCuenta?.nombreSucursal"><strong>Sucursal:</strong> {{sucursalCuenta?.nombreSucursal}}</div>
                        </div>
                        <div class="dotted-separator" *ngIf="account.nombreGrupo || sucursalCuenta?.nombreSucursal " ></div>
                        <h3  *ngIf="totalCuentaDetalle != 0">Detalle</h3>
                        <table class="ui striped table responsive-table">
                          <tbody>
                            <tr *ngFor="let detalle of cuentaDetalles">
                              <td><strong>{{ detalle.descripcion }}</strong></td>
                              <td>
                                <ng-container [ngSwitch]="detalle.idCuentaCampoTipoDato">
                                  <ng-container *ngSwitchCase="1">
                                    {{ utils.formatearNumero(detalle.valor) }}
                                  </ng-container>
                                  <ng-container *ngSwitchCase="2">
                                    {{ utils.formatearNumero(detalle.valor) }}
                                  </ng-container>
                                  <ng-container *ngSwitchCase="4">
                                    {{ momentVista(detalle.valor).format('DD/MM/YYYY') }}
                                  </ng-container>
                                  <ng-container *ngSwitchCase="5">
                                    {{ momentVista(detalle.valor).format('DD/MM/YYYY HH:mm') }}
                                  </ng-container>
                                  <ng-container *ngSwitchCase="6">
                                    <span *ngIf="detalle.valor == 1">Sí</span>
                                    <span *ngIf="detalle.valor == 0">No</span>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="7">
                                    {{ momentVista(detalle.valor).format('HH:mm') }}
                                  </ng-container>
                                  <ng-container *ngSwitchDefault>
                                    {{ detalle.valor }}
                                  </ng-container>
                                </ng-container>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div *ngIf="totalCuentaDetalle === 0">
                          <i>Sin información para mostrar</i>
                        </div>
                      </div>


                    <div *ngIf="editarCuentaInfo">
                        <form-account-detail [cuentaDetalles]="cuentaDetalles" [idFinanciera]="idFinanciera" [idCuenta]="idCuenta"
                            (onCancel)="editarCuentaInfo = !editarCuentaInfo"
                            (onSave)="editarCuentaDetalleInfo($event)">
                        </form-account-detail>
                    </div>

                </mat-card-content>
                <div *ngIf="!editarCuentaInfo && !loadingAccountDetail && clienteActivo">
                    <mat-divider inset></mat-divider>
                    <div *ngIf="detalleCuentadiv" mat-card-actions align="right" style="margin-top: 15px; margin-right: 15px;">
                        <button  *ngIf="verificarEstatus()" mat-stroked-button (click)="habilitarBtn(false)"><mat-icon>edit</mat-icon>Editar</button>
                    </div>
                </div>
                <mat-card-footer *ngIf="loadingAccountDetail" class="kobra-card-footer">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card-footer>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list-responsive>
    <!--aqui termina-->
    <mat-card class="card kobra-card">
        <mat-card-header>
            <mat-card-title>Domicilios</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-tab-group  *ngIf="!cargandoHistoriaDomiclios" (selectedTabChange)="onTabChange($event)">
                <mat-tab label="Primarios" >
                    <div *ngIf="domicilios">
                        <div *ngIf="domicilios" >
                            <div class="button-container">
                                <div class="button-toggle-group-container">
                                    <!--  -->
                                    <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" class="right-align">
                                        <mat-button-toggle value="subMenu"
                                            matTooltip="Acciones"
                                            mat-tooltip-panel-above
                                            [matMenuTriggerFor]="menuDomicilio">
                                            <mat-icon class="kobra-icon">more_vert</mat-icon>
                                        </mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                                <mat-menu #menuDomicilio="matMenu">
                                    <button mat-menu-item
                                        [disabled]="!clienteActivo"
                                        (click)="openDialogAsignar()"
                                        ><mat-icon class="kobra-icon">add_thick</mat-icon>Asignar
                                    </button>
                                    <button mat-menu-item
                                        [disabled]="!clienteActivo"
                                        (click)="openDialogEstatus()">
                                        <mat-icon class="kobra-icon">sync</mat-icon>Estatus</button>
                                    <button mat-menu-item
                                        [disabled]="!clienteActivo || itemsSelected.length !== 1"
                                        (click)="openDialogEditar()">
                                        <mat-icon class="kobra-icon">edit</mat-icon>Editar</button>
                                </mat-menu>
                            </div>
                        </div>
                        <div style="overflow-x: auto;">
                        <app-table [loading]="cargandoHistoriaDomiclios" [columns]="tablaDomicilios.columnas"
                            [items]="domicilios" [headers]="tablaDomicilios.headers"
                            [tableHeaders]="tablaDomicilios.tableHeaders" [ignoreSortChange]="true"
                            [multiSelect]="tablaDomicilios.multiSelect" (onLinkRow)="onDomicilioClick($event)"
                            (onSelectedRows)="onSelected($event)" >
                        </app-table>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Borrados">
                    <div >
                        <div class="button-container">
                            <div class="button-toggle-group-container">
                                <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" class="right-align">
                                    <mat-button-toggle value="subMenu"
                                        matTooltip="Acciones"
                                        mat-tooltip-panel-above
                                        [matMenuTriggerFor]="menuDomicilioBorrados">
                                        <mat-icon class="kobra-icon">more_vert</mat-icon>
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                            <mat-menu #menuDomicilioBorrados="matMenu">
                                <button mat-menu-item
                                    [disabled]="!clienteActivo || itemsSelected.length !== 1"
                                    (click)="openDialogActivar()">
                                    <mat-icon class="kobra-icon">check</mat-icon>Activar</button>
                            </mat-menu>
                        </div>
                        <app-table [loading]="cargandoHistoriaDomiclios" [columns]="tablaDomiciliosBorrados.columnas"
                            [items]="domiciliosBorrados" [headers]="tablaDomiciliosBorrados.headers"
                            [tableHeaders]="tablaDomiciliosBorrados.tableHeaders" [ignoreSortChange]="true"
                            [multiSelect]="tablaDomiciliosBorrados.multiSelect" (onLinkRow)="onclikDomicilioBorrados($event)"
                            (onSelectedRows)="onSelected($event)">
                        </app-table>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
        <mat-card-footer *ngIf="cargandoHistoriaDomiclios" class="kobra-card-footer">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
    </mat-card>

    <mat-card style="margin-top: 20px;">
        <!-- <mat-card-header>
            <mat-card-title>Mapa</mat-card-title>
        </mat-card-header> -->
        <mat-card-content>
            <div style="height: 600px; position: relative;" *ngIf="!cargandoHistoriaDomiclios">
                <div class="mapa-radio">
                  <mat-radio-group [(ngModel)]="tipoMapa">
                    <mat-radio-button class="kobra-radio" [value]="1">Normal</mat-radio-button>
                    <mat-radio-button class="kobra-radio" [value]="2">Satélite</mat-radio-button>
                  </mat-radio-group>
                </div>
                <mapbox [tipoMapa]="tipoMapa" [coordenadas]="coordenadasMapa" [coordenadasUltima]="coordenadasUltimaVisita"></mapbox>
            </div>
        </mat-card-content>
        <mat-card-footer *ngIf="cargandoHistoriaDomiclios" class="kobra-card-footer">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
    </mat-card>


    <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="545px" style="margin-top:10px;">
        <mat-grid-tile colspan="1" rowspan="1">
            <ng-container *ngIf="account && account.idCuentaPG && account.idCliente">
                <app-timeline-cuenta #timelineCuenta style="width: 100%;" height="330px" [idCuenta]="account.idCuentaPG" [idCliente]="account.idCliente" [idAcreditado]="account.idAcreditadoPG"></app-timeline-cuenta>
            </ng-container>
            <!--mat-card class="kobra-card anchoCard margenRight" style="height: 420px;">
                <mat-card-title>Historial de movimientos</mat-card-title>
                <mat-card-content class="contenidoConScroll" style="max-height: 320px;">
                  <app-loader *ngIf="loadingAccountHistory"></app-loader>
                  <div *ngIf="historialCuenta.length > 0 && !loadingAccountHistory">
                    <div *ngFor="let historial of historialCuenta">
                        <mat-card style="margin-top: 10px;">
                          <mat-card-content>
                            <p><b>Estatus:</b>
                                <span [ngClass]="{'cuentaActiva': historial.estatus === 'Activa'
                                    , 'cuentaEnEspera' : historial.estatus === 'Visitada'
                                    , 'cuentaApartada' : historial.estatus === 'Apartada'
                                    , 'cuentaEnEsperaDeConfirmacion' : historial.estatus === 'En Espera de Confirmación'
                                    , 'cuentaInactiva' : historial.estatus === 'Inactiva'}">
                                    {{historial.estatus}}
                                </span>
                            </p>
                            <p><b>Fecha:</b> {{historial.fecMovimiento | date:'dd/MM/yyyy HH:mm' }}</p>
                            <p><b>Usuario:</b> {{historial.nombre}} {{historial.apellidoPaterno}}</p>
                            <p><b>Folio Domicilio:</b> {{historial.folioDomicilio}}</p>
                          </mat-card-content>
                        </mat-card>
                    </div>
                  </div>
                  <div *ngIf="historialCuenta.length === 0">
                    No hay datos para mostrar.
                  </div>
                </mat-card-content>
              </mat-card-->


        </mat-grid-tile>

        <mat-grid-tile colspan="1" rowspan="1">
          <mat-card class="card kobra-card anchoCard margenLeft" style="height: 515px;">
            <mat-card-header>
                <mat-card-title>Historial de mensajes</mat-card-title>
            </mat-card-header>

            <mat-card-content>
                <!-- Lista de mensajes -->
                <div *ngIf="totalMensajes > 0; else noMensajes" class="mensajes-container">
                    <ng-container *ngFor="let mensaje of historialMensajes">
                        <!-- Si el mensaje tiene contenido, se muestra -->
                        <div *ngIf="mensaje.comentario" [ngClass]="mensaje.sender === 'AdministradorFinanciera' ? 'mensaje mensaje-administrador' : 'mensaje mensaje-cobrador'">
                            <span class="mensaje-autor">{{ mensaje.senderName }}</span>
                            <div>{{ mensaje.comentario }}</div>
                            <div class="mensaje-fecha">{{ mensaje.createdAt | date:'dd/MM/yyyy HH:mm:ss' }}</div>
                        </div>
                    </ng-container>
                </div>

                <!-- No hay mensajes -->
                <ng-template #noMensajes>
                    <div class="no-mensajes">No hay mensajes para mostrar.</div>
                </ng-template>
            </mat-card-content>

            <!-- Indicador de carga -->
            <mat-card-footer *ngIf="cargandoMensajes" class="kobra-card-footer">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>
        </mat-grid-tile>
    </mat-grid-list-responsive>


    <mat-card class="kobra-card" style="margin-top: 10px;">
        <mat-card-header>
            <mat-card-title>Historial de visitas</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <app-container *ngIf="visitas && !loadingLastVisit">
                <app-table [columns]="tablaVisitas.columnas" [items]="visitas"
                    [headers]="tablaVisitas.headers" [tableHeaders]="tablaVisitas.tableHeaders"
                    [ignoreSortChange]="true" [multiSelect]="tablaVisitas.multiSelect"
                    (onLinkRow)="onLinkRow($event)"
                    (onDoubleClick)="onDoubleClick($event)">
                </app-table>
            </app-container>
            <div *ngIf="!visitas">
                <i>Sin visitas registradas</i>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="kobra-card" style="margin-top: 20px;">
        <mat-card-header>
            <mat-card-title>Historial de etiquetas de la cuenta</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <app-container  *ngIf="etiquetasCuentaHistorial && !loadingHistorialEtiquetasCuenta">
                <app-table [columns]="tablaEtiquetasCuentas.columnas"
                    [items]="etiquetasCuentaHistorial" [headers]="tablaEtiquetasCuentas.headers"
                    [tableHeaders]="tablaEtiquetasCuentas.tableHeaders" [ignoreSortChange]="true"
                    [multiSelect]="tablaEtiquetasCuentas.multiSelect" (onLinkRow)="onLinkRow($event)">
                </app-table>
            </app-container>
            <div *ngIf="!etiquetasCuentaHistorial">
                <i>Sin etiquetas registradas</i>
            </div>
        </mat-card-content>

    </mat-card>

    <mat-card *ngIf="contadorPagos > 0" class="kobra-card" style="margin-top: 20px;">
        <mat-card-header>
            <mat-card-title>Historial de pagos recabados</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div align="right">
                <span class ="montoTotal">
                    Total recabado: ${{montoTotal | number}}
                </span>
            </div>
            <app-container>
                <app-table [columns]="tablaDineroRecuperado.columnas"
                    [items]="dineroRecuperado" [headers]="tablaDineroRecuperado.headers"
                    [tableHeaders]="tablaDineroRecuperado.tableHeaders" [ignoreSortChange]="true"
                    [multiSelect]="tablaDineroRecuperado.multiSelect">
                </app-table>
            </app-container>
        </mat-card-content>
    </mat-card>
</div>

