<div class="app content tabla kobra">
    <mat-card>
        <form [formGroup]="form">
            <mat-card-header>
                <mat-card-title>{{title}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ng-template let-lender #financieraTemplate>
                    {{ lender.nombre }}
                </ng-template>
                
                    <div class="row kobra two columns">
                        <div class="column">
                            <mat-select-search
                                appearance="fill"
                                class="id-card-field"
                                [label]="'Cliente'"
                                [placeHolder]="'Selecciona un cliente'"
                                [value]="'idFinanciera'"
                                [labelField]="'name'"
                                [icon]="'account_balance'"
                                [options]="lenders"
                                formControlName="idFinanciera"
                                (ngModelChange)="onSelectCliente($event)"
                            > 
                            <mat-error *ngIf="checkControl('idFinanciera')">
                                El cliente es <strong>requerido</strong>
                            </mat-error>
                            </mat-select-search>
                            
                            
                        </div>
                        <div class="column">
                            <mat-select-search
                                appearance="fill"
                                [label]="'Producto'"
                                [placeHolder]="'Selecciona un producto'"
                                [value]="'idProducto'"
                                [labelField]="'nomProducto'"
                                [icon]="'inventory_2'"
                                [options]="productos"
                                formControlName="idProducto"
                                (ngModelChange)="onSelectProduct($event)"
                                class="id-card-field"
                                >
                                <mat-error *ngIf="checkControl('idProducto')" style="margin-top: -20px; font-size: 12px; margin-left:15px">
                                    El producto es <strong>requerido</strong>
                                </mat-error>
                            </mat-select-search>
                            
                            
                        </div>    
                    </div>
                    <div class="row">
                        <div class="column">
                            <mat-form-field appearance="fill" style="width: 100%;">
                                <mat-label>Titulo</mat-label>
                                    <input 
                                        matInput 
                                        textSanitizer  
                                        placeholder="Escribir titulo del tutorial" 
                                        formControlName="titulo"
                                        (input)="enforceMaxLength($event, 'titulo', 100)"
                                    >
                                <mat-icon matSuffix>title</mat-icon>
                                <mat-error *ngIf="checkControl('titulo')">
                                    El titulo es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>       
                    <div class="action-buttons" align="left">
                        <button mat-stroked-button class="boton-accion-front" (click)="agregarDetalle()">
                        <mat-icon>add</mat-icon>
                        Agregar detalle
                        </button>
                    </div>
                    <div class="tabla-scroll">
                        <table mat-table [dataSource]="dataSource" cdkDropList (cdkDropListDropped)="drop($event)">     
                            <ng-container matColumnDef="encabezado">
                                <th mat-header-cell *matHeaderCellDef class="small-column"> Encabezado </th>
                                <td mat-cell *matCellDef="let detalle; let i = index">
                                    <mat-form-field appearance="outline">
                                        <input 
                                        matInput
                                        placeholder="Encabezado"
                                        [formControl]="detalle.get('encabezado')"
                                        (mousedown)="$event.stopPropagation()" 
                                        (input)="enforceMaxLength($event, 'encabezado', 100)"
                                        />
                                        {{ getErrorMessage('encabezado', i) }}
                                    </mat-form-field>
                                </td>
                            </ng-container>              
                            <ng-container matColumnDef="tipoContenido">
                                <th mat-header-cell *matHeaderCellDef class="medium-column"> Tipo Contenido </th>
                                <td mat-cell *matCellDef="let detalle; let i = index">
                                    <mat-form-field appearance="outline">
                                        <mat-select 
                                            [formControl]="detalle.get('idTutorialTipoContenido')" 
                                            (selectionChange)="actualizarValidaciones(i)" 
                                            (mousedown)="$event.stopPropagation()" 
                                        >
                                            <mat-option
                                                *ngFor="let tipo of tiposContenido"
                                                [value]="tipo.id"
                                            >
                                                {{ tipo.nombre }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </ng-container>
                            <!-- Columna Contenido Condicional -->
                            <ng-container matColumnDef="contenido">
                                <th mat-header-cell *matHeaderCellDef class="large-column"> Contenido </th>
                                <td mat-cell *matCellDef="let detalle; let i = index">
                                    <mat-form-field appearance="outline">
                                        <ng-container   [ngSwitch]="getTipoContenidoTexto(detalle.get('idTutorialTipoContenido')?.value )">
                                            <!-- Contenido Texto -->
                                            <div *ngSwitchCase="'text'">
                                                <textarea 
                                                    class="textarea-content" 
                                                    matInput 
                                                    [formControl]="detalle.get('contenidoTexto')" 
                                                    placeholder="Escribe el contenido"
                                                    (mousedown)="$event.stopPropagation()"
                                                    (input)="enforceMaxLength($event, 'contenidoTexto', 255)"
                                                ></textarea>
                                                <mat-error *ngIf="detalle.get('contenidoTexto')?.invalid && detalle.get('contenidoTexto')?.touched">
                                                    {{ getErrorMessage('contenidoTexto', i) }}
                                                </mat-error>
                                            </div>
                                            <!-- URL Imagen o Video -->
                                            <div *ngSwitchCase="'imagen'">                                    
                                                <input 
                                                    class="input-content" 
                                                    matInput 
                                                    [formControl]="detalle.get('urlContenido')"
                                                    placeholder="URL de la imagen"
                                                    (mousedown)="$event.stopPropagation()"
                                                    (input)="enforceMaxLength($event, 'urlContenido', 255)"
                                                >
                                                <mat-error *ngIf="detalle.get('urlContenido')?.invalid && detalle.get('urlContenido')?.touched">
                                                    {{ getErrorMessage('urlContenido', i) }}
                                                </mat-error>
                                            </div>
                                            <div *ngSwitchCase="'video'">
                                                <input 
                                                    class="input-content" 
                                                    matInput 
                                                    [formControl]="detalle.get('urlContenido')" 
                                                    placeholder="URL del video"
                                                    (mousedown)="$event.stopPropagation()"
                                                    (input)="enforceMaxLength($event, 'urlContenido', 255)"
                                                >
                                                <mat-error *ngIf="detalle.get('urlContenido')?.invalid && detalle.get('urlContenido')?.touched">
                                                    {{ getErrorMessage('urlContenido', i) }}
                                                </mat-error>  
                                            </div>
                                        </ng-container>
                                    </mat-form-field>
                                </td>
                            </ng-container>                 
                            <ng-container matColumnDef="acciones">
                                <th mat-header-cell *matHeaderCellDef class="small-column"> Acciones </th>
                                <td mat-cell *matCellDef="let detalle; let i = index">
                                        <!-- Área de arrastre -->
                                        <div cdkDragHandle class="drag-handle">
                                            <mat-icon>drag_indicator</mat-icon>
                                            <button mat-icon-button color="warn" (click)="eliminarDetalle(i)" (mousedown)="$event.stopPropagation()">
                                                <mat-icon>delete_forever</mat-icon>
                                            </button>
                                        </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="[ 'encabezado', 'tipoContenido', 'contenido',  'acciones']"></tr>
                            <tr mat-row *matRowDef="let detalle; columns: ['encabezado', 'tipoContenido', 'contenido', 'acciones'];" cdkDrag></tr>       
                        </table>
                    </div>
            </mat-card-content>
            <mat-card-actions align="end" class="button-gap">
                <button 
                    class="boton-cancelar" mat-flat-button type="submit" (click)="cancel()">
                    <mat-icon class="kobra-icon">
                    close
                    </mat-icon> Cancelar</button>
                <button 
                    mat-stroked-button 
                    class="boton-accion-guardar" 
                    type="submit" 
                    [ngClass]="{'boton-guardar': validarGuardar()}"
                    [disabled]="!validarGuardar()"
                    (click)="onSubmit()">
                    <mat-icon>
                    save
                    </mat-icon> 
                    Guardar
                </button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>