import { SessionData } from './../shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RequestPaginator } from './request.paginator';
import { RequestService } from './request.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { AgentService } from '@agent/agent.service';
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/internal/operators/map';;
import { Estatus } from './estatus.model';
import { EstatusFinancieras } from './estatus-financieras.model';
import { TiposCredito } from './tipos-credito.model';
import { RequestStatus } from './request-status.model';
import { LenderService } from '@lender/lender.service';
import { Request } from './request.model';
import { environmentSelector } from 'src/environments/environment.selector';
import { Lender } from '@lender/lender.model';
import { Subscription } from 'rxjs';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';
import { Configuracion } from './configuracion.interface';
import { TableComponent } from 'src/app/shared/components/table/table.component';

declare var $: any;

@Component({
  selector: 'app-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.css']
  ,
  providers: [
    LenderService,
    AgentService,
    RequestService
  ]
})
export class RequestListComponent implements OnInit {
  private environment = environmentSelector();

  public contabilizadorFiltros: FiltersContabilizer;

  public totalFiltros: number = 0;

  public ayuda: boolean = false;
  filtrosAbiertos: boolean = false;
  public hijosActivos: boolean = false;
  sessionData: SessionData;
  public showCalifButton = false;
  public isAllSelected = false;
  public listFields: any = {};
  public sortField: string;
  public sortAsc: string = '';
  public requestPaginator: RequestPaginator;
  private visibility: string = null;
  public listFiltered: boolean;
  private $filterModal: any;
  public lenders: Lender[];
  public estatus: Estatus[];
  public estatusFinancieras: EstatusFinancieras[];
  public tiposCredito: TiposCredito[];
  public status: RequestStatus;
  public cambiarTextos = false;
  public labelMunicipio: string = 'Municipio';
  public labelEstado: string = 'Estado';
  cargandoLista = false;
  tablaSolicitudes:any;
  queryParamsGlobal:string;
  isSolicitudGeneral: boolean = false;
  @ViewChild('tablaRef', { static: false }) tablaComponent: TableComponent;

  public today: Date = new Date();
  public fechaInicioDefault: Date = new Date(this.today.getTime() - 30 * 24 * 60 * 60 * 1000);
  public httpError: any;
  private slt: string = 'idSolicitudCredito, folio, nombre, primerApellido, segundoApellido, idGenero, fechaNacimiento, telefonoCelular, telefonoTrabajo, telefonoCasa,' +
    ' email, curp, rfc, calle, numeroExterior, numeroInterior, entreCalles, idColonia, colonia, idCodigoPostal,' +
    ' codigoPostal, idMunicipio, municipio, idEstado, estado, latitudFin, longitudFin, archivoFirmaRuta, archivoFirmaNombre, montoSolicitado, idEstatus, estatus, idAgente, nombreCompletoAgente,' +
    ' idCliente, nombreCliente, fechaRegistro, nombreTipoCredito, idTipoCredito, montoPrestado, idFinanciera, estatusFinanciera, idTipoPersona, idCobrador';

  pageSize: number = 50;
  requests: Request[];

  public filtros:any = {
    excludeFinancierasDemo : true,
    agente:  null,
    agentAvailable:  null,
    idAcreditado: null,
    solicitante: null,
    idCliente:  null,
    requestStartDate: this.fechaInicioDefault,
    requestEndDate: this.today,
    status:  null,
    idTipoCredito:  null,
    requestID:  null,
    lender:  null,
    statusClient: null,
    page: 1
  }

  constructor(
    private router: ActivatedRoute,
    private routeNavigate: Router,
    private rootElement: ElementRef,
    private app: ApplicationService,
    private filtrosService: FiltrosService,
    private lenderService: LenderService,
    private agenteServ: AgentService,
    private requestService: RequestService,
    private localStorageService: LocalStorageService,

  ) {
    this.sessionData = this.localStorageService.getSessionData();
    if(!this.sessionData.isSuperAdmin){
      this.filtros.lender = this.sessionData.idFinanciera;
    }
    this.requestPaginator = new RequestPaginator();
    this.labelMunicipio = this.environment.labelMunicipio;
    this.labelEstado = this.environment.labelEstado;
    this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.subscribeRouterParams.bind(this));
    this.sessionData = this.localStorageService.getSessionData();
    this.requests = [];
    this.crearTabla();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
  }

  private subscribeRouterParams(filtrosNuevos: any): void {
    if(filtrosNuevos){
      this.listFiltered = true;
      this.filtros = filtrosNuevos;
    }
    this.requestPaginator.pagination.currentPage = this.filtros.page || 1;

    this.initListColumns();
    this.loadLenders();
    this.loadTiposCredito();
    if(this.sessionData.isSuperAdmin && !this.filtros.lender){
      //this.app.showSnackbar("Aviso", "Favor de seleccionar un cliente.", 3000, 'warning');
      return;
    }
    this.obtenerConfiguracion();
    this.loadRequests(this.requestPaginator.pagination.currentPage);
  }

  ngOnInit() {
  }

  crearTabla() {
    this.tablaSolicitudes = {
        headers: false,
        multiSelect: false,
        columns: [
          {
            name: 'Cliente',
            key: 'nombreCliente',
            hide: false,
            sortName: 'sol.nombreCliente'
          },
          {
            name: 'Folio',
            key: 'folio',
            hide: false,
            type: 'link',
            sortName: 'sol.idSolicitudCredito'
          },
          {
            name: 'Tipo solicitud',
            key: 'nombreTipoCredito',
            hide: false,
            sortName: 'sol.nombreTipoCredito'
          },
          {
            name: 'Tipo Persona',
            key: 'tipoPersona',
            hide: false,
            sortName: 'sol.idTipoPersona'
          },{
            name: 'Nombre Solicitante',
            key: 'nombreCompleto',
            hide: false,
            sortName: 'sol.nombre'
          }, {
            name: 'Estatus',
            key: 'estatus',
            hide: false,
            type: 'estatus',
            sortName: 'sol.estatus'
          }, {
            name: 'Estatus Cliente',
            key: 'estatusFinanciera',
            hide: false,
            sortName: 'sol.estatusFinanciera'
          }, {
            name: 'Monto Solicitado',
            key: 'montoSolicitado',
            hide: false,
            type: 'money',
            sortName: 'sol.montoSolicitado'
          }, {
            name: 'Monto Prestado',
            key: 'montoPrestado',
            hide: false,
            type: 'money',
            sortName: 'sol.montoPrestado'
          }, {
            name: 'Fecha solicitud',
            key: 'fechaRegistro',
            hide: false,
            type: 'date',
            sortName: 'sol.fechaRegistro'
          }, {
            name: 'Agente',
            key: 'agente',
            hide: false,
            sortName: 'sol.nombreCompletoAgente'
          }, {
            name: this.labelMunicipio,
            key: 'municipio',
            hide: false,
            sortName: 'sol.municipio'
          }, {
            name: this.labelEstado,
            key: 'estado',
            hide: false,
            sortName: 'sol.estado'
          }
        ]
    }
  }
  private agregarContabilizadoresDeFiltros(){
    this.contabilizadorFiltros.add('Súper Administrador', 'superAdmin', [
        'lender',
        'excludeFinancierasDemo'
    ]);

    this.contabilizadorFiltros.add('Generales', 'generales', [
        'requestID',
        'status',
        'statusClient',
        'idTipoCredito',

    ]);

    this.contabilizadorFiltros.add('Solicitante', 'solicitante', [
        'solicitante'
    ]);

    this.contabilizadorFiltros.add('Fechas', 'fechas', [
        'requestStartDate',
        'requestEndDate'
    ]);

    this.contabilizadorFiltros.add('Agente', 'agente', [
        'agente'
    ]);
  }

  public contabilizadorDeFiltros(filtroName: string){
    return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
  }

  public contabilizadorDeFiltrosTotales():void{

    let totalFiltrosArray: number[] = [];
    if(this.sessionData.isSuperAdmin){
        totalFiltrosArray.push( this.contabilizadorDeFiltros('superAdmin') );
    }
    totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
    totalFiltrosArray.push( this.contabilizadorDeFiltros('solicitante') );
    totalFiltrosArray.push( this.contabilizadorDeFiltros('fechas') );
    totalFiltrosArray.push( this.contabilizadorDeFiltros('agente') );

    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  public loadLenders(): void {

    this.lenderService.getAll('all').subscribe(
        res => {
            this.lenders = res;
        },
        err => {
            this.app.showError(err);

        }
    );
  }

  public loadEstatusFinancieras(): void {
      //let loading = this.app.showLoading('Cargando estatus...');
      if(this.filtros.lender) {
        this.obtenerConfiguracion();
        this.requestService.getEstatusFinancieras( this.filtros.lender ).subscribe(
          res => {
            this.estatusFinancieras = res;
            localStorage.setItem('estatusFinancieras', JSON.stringify(res));
          },
          err => {
            this.app.showError(err);
            //this.app.hideLoading(loading);
          },
          //() => this.app.hideLoading(loading)
        );
      }
  }

  public obtenerConfiguracion(): void {

    if (this.filtros.lender) {
      this.requestService.obtenerConfiguracionValor(this.filtros.lender).pipe(
        take(1)
      ).subscribe({
        next: (res: Configuracion[]) => {
          res.forEach(element => {
            if (element.valor === 'solicitudes_generales') {
              this.isSolicitudGeneral = true;
              this.mostrarOcultarColumnas(false);
            }else{
              this.isSolicitudGeneral = false;
              this.mostrarOcultarColumnas(true);
            }
          });
        },
        error: (err) => {
          this.app.showError(err);
          //this.cargandoConfiguracion = false;
          this.isSolicitudGeneral = false;
        },
        complete: () => {
          //this.cargandoConfiguracion = false;
          //this.isSolicitudGeneral = false;
        }
      });
    }
  }


  public loadTiposCredito(): void {
    let tiposCredito: TiposCredito[] = <TiposCredito[]>JSON.parse(localStorage.getItem('tipos-credito'));
    if (tiposCredito) {
      this.tiposCredito = tiposCredito;
    } else {
      //let loading = this.app.showLoading('Cargando...');
      this.requestService.getTiposCredito().subscribe(
        res => {
          this.tiposCredito = res;
          localStorage.setItem('tipos-credito', JSON.stringify(res));
        },
        err => {
          this.app.showError(err);
          //this.app.hideLoading(loading);
        },
        //() => this.app.hideLoading(loading)
      );
    }
  }


  public descargarListado(conCuestionario: number): void {
    let queryParams = `?start=0&cuestionario=${conCuestionario}&idClienteAdministrador=${this.sessionData.idClienteAdministrador}` + this.queryParamsGlobal;
    queryParams += '&rows=' + this.pageSize;
    if (this.requests && this.requests.length > 0) {
      this.requestService.descargarSolicitudConCuestionario(queryParams)
        .pipe(take(1))
        .subscribe((respuesta) => {
            this.app.showSnackbar("Aviso", "Tu archivo estará listo en unos momentos, para poder descargarlo ir a la opción de descargas.", 3000, 'success');
        }, (error: any) => {
            this.app.showError(error);
        });
    } else {
        this.app.showSnackbar("Aviso", "No hay resultados para descargar", 3000, 'warning');
    }
  }

  public setSortField(sort: any) {
    this.sortAsc = sort.direction;
    console.log(this.sortAsc);
    if (sort.active != this.sortField) {
      this.sortField = sort.active;
    }
    this.loadRequests(1);
  }

  public agenteFilterSearch = function(term: string) {
    return this.agenteServ.obtenerAgentes(term)
        .pipe(
            take(1),
            map((res: any) => res.data.map(
                ({ idCobrador, nombreCompleto, idAgente }) => (
                    { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente }
                )
            ))
        );
  }.bind(this);


  solicitanteFilterSearch = function (term: string) {
    return this.agenteServ.obtenerSolicitantes(term)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idSolicitante, nombreCompleto }) => (
            { idSolicitante: idSolicitante,nombre: nombreCompleto }
          )
        ))
      )
  }.bind(this);

  public clearFilter(): void {
    //this.resetSolicitanteFilter();
    Object.keys(this.filtros).forEach(key => {
      this.filtros[key] = null;
    });

    this.filtrosService.removerFiltros();
    this.filtros.agentAvailable = false;
    this.listFiltered = false;
    this.filtros.page = 1;
    this.requestPaginator.pagination.currentPage = 1;
    //this.loadRequests(1);

  }

  public setFilter(): void {
    if(this.sessionData.isSuperAdmin) {
      if(!this.filtros.lender){
        this.app.showSnackbar("Aviso", "Favor de seleccionar un cliente.", 3000, 'warning');
        return;
      }
    }
    /*if(this.filtros.requestID){
      if(!this.filtros.lender){
        this.app.showSnackbar("Aviso", "Si filtras por un ID solicitud debes seleccionar un Cliente", 3000, 'warning');
        return;
      }
    }*/
    this.listFiltered = true;
    this.filtros.page = 1;
    this.filtrosService.guardarFiltros(this.filtros);
    this.loadRequests(1);
    this.contabilizadorDeFiltrosTotales();


  }

  checkSelected() {
    this.showCalifButton = this.requests.findIndex(x => x.selected === true) >= 0;
  }

  public loadRequests(page: number): void {

    if (!this.sortField) {
      this.sortField = 'sol.fechaRegistro desc';
    }
    let queryParams: string = '&rows=' + this.pageSize;
    queryParams += this.crearURLQuery();
    this.requestPaginator.pagination.nextPage = page || 1;

    this.requestPaginator.pagination.currentPage = page;
    if (!page) {
      page = 1;
    }

    if(page != this.filtros.page){
      this.filtros.page = page;
      this.filtrosService.guardarFiltros(this.filtros);
    }
    queryParams += '&page=' + page;
    queryParams += '&slt=' + this.slt
    //let loading: number = this.app.showLoading('Cargando...');
    this.cargandoLista = true;
    let subscription: Subscription = this.requestService.obtenerListado(this.requestPaginator.pagination, queryParams, this.pageSize).subscribe(
      (paginator: RequestPaginator) => {
        this.requestPaginator = paginator;
        var requests: Request[] = paginator.data;
        requests.map(request => request.selected = false);
        this.requests = requests;
        //this.cargandoLista = false;
        this.contabilizadorDeFiltrosTotales();

      },
      (error) => {
        this.app.showError(error);
        this.cargandoLista = false;
        subscription.unsubscribe();
      },
      () => {
        this.cargandoLista = false;
        subscription.unsubscribe();
      }
    );

  }


  private crearURLQuery(): string {

    this.queryParamsGlobal = '';

    if (this.sortField) {
      this.queryParamsGlobal += '&orderBy=' + this.sortField + (this.sortAsc ? ' ' + this.sortAsc : '');
    }

    if (this.filtros.requestID) {
      this.queryParamsGlobal += `&folio=${this.filtros.requestID}`;
    }

    if (this.filtros.lender) {
      this.queryParamsGlobal += `&idFinanciera=${this.filtros.lender}`;
    }

    if(this.filtros.requestStartDate){
      this.queryParamsGlobal += '&inicioRegistroSolicitud='+ this.filtros.requestStartDate.toISOString().substr(0, 10);
    }
    if(this.filtros.requestEndDate){
      this.queryParamsGlobal += '&finRegistroSolicitud='+ this.filtros.requestEndDate.toISOString().substr(0, 10);
    }

    if (this.filtros.agente) {
      this.queryParamsGlobal += `&idCobrador=${this.filtros.agente.idCobrador}`;
    }

    if (this.filtros.solicitante){
      this.queryParamsGlobal += `&idSolicitante=${this.filtros.solicitante.idSolicitante}`;
    }

    if (this.filtros.status != null) {
      this.queryParamsGlobal += `&idEstatus=${this.filtros.status}`;
    }

    if (this.filtros.statusClient != null) {
      this.queryParamsGlobal += `&idEstatusCliente=${this.filtros.statusClient}`;
    }

    if (this.filtros.idTipoCredito) {
      this.queryParamsGlobal += `&idTipoCredito=${this.filtros.idTipoCredito}`;
    }

    return this.queryParamsGlobal;
  }

  private initListColumns(): void {
    let listFields: any = JSON.parse(localStorage.getItem('requestListFields')) || {};
    this.listFields.idSolicitudCredito = listFields.idSolicitudCredito == false ? false : true;
    this.listFields.nombreTipoCredito = listFields.nombreTipoCredito == false ? false : true;
    this.listFields.nombre = listFields.nombre == false ? false : true;
    this.listFields.primerApellido = listFields.primerApellido == false ? false : true;
    this.listFields.segundoApellido = listFields.segundoApellido == false ? false : true;
    this.listFields.estatus = listFields.estatus == false ? false : true;
    this.listFields.montoSolicitado = listFields.montoSolicitado == false ? false : true;
    this.listFields.montoPrestado = listFields.montoPrestado == false ? false : true;
    this.listFields.nombreCliente = listFields.nombreCliente == false ? false : true;
    this.listFields.fechaRegistro = listFields.fechaRegistro == false ? false : true;
    this.listFields.nombreCompletoAgente = listFields.nombreCompletoAgente == false ? false : true;
    this.listFields.colonia = listFields.colonia == false ? false : true;
    this.listFields.municipio = listFields.municipio == false ? false : true;
    this.listFields.estado = listFields.estado == false ? false : true;
  }

  onPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !==  this.requestPaginator.pagination.currentPage || newPagina.pageSize !== this.pageSize){
      this.pageSize = newPagina.pageSize;
        this.loadRequests(newPagina.pageIndex+1);
    }
  }

  onClick({ row, columnPressed }) {
    if(columnPressed === 'folio') {
         this.linkCredito(row);
     }
  }

  onCtrlClick({ row, columnPressed }) {

    if(columnPressed === 'folio') {
         this.linkCredito(row, true);
     }
  }

  linkCredito(visita: any, openInNewTab: boolean = false): void {
    // Construcción de la URL
    const url = `/requests/${visita.idSolicitudCredito}/cliente/${visita.idFinanciera}`;

    if (openInNewTab) {
      // Abre en una nueva pestaña
      window.open(url, '_blank');
    } else {
      // Navega en la misma pestaña
      this.routeNavigate.navigate([visita.idSolicitudCredito, 'cliente', visita.idFinanciera], {
        relativeTo: this.router,
      });
    }
  }

  inactivarHijo(){
    this.filtrosService.asignarOpcion();
    if(this.filtros && this.filtros.financiera){
        this.filtrosService.guardarFiltros(this.filtros, true);
    }
    this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.subscribeRouterParams.bind(this));
  }

  mostrarOcultarColumnas(visibility:boolean) {
    this.tablaComponent.toggleColumnVisibility('estatus', visibility);
    this.tablaComponent.toggleColumnVisibility('estatusFinanciera', visibility);
    this.tablaComponent.toggleColumnVisibility('montoSolicitado', visibility);
    this.tablaComponent.toggleColumnVisibility('montoPrestado', visibility);
  }

}

