import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApplicationService } from "../../../application/shared/application.service";
import { SucursalesService } from '../../../catalogos/usuarios/sucursales/sucursales.service';
import { SucursalCuenta } from './sucursal-cuenta.interface';


@Component({
  selector: 'sucursal-cuenta.component',
  templateUrl: 'sucursal-cuenta.component.html',
  styleUrls: ["sucursal-cuenta.component.css"],
  providers: [
    SucursalesService
  ]
})
export class ModalSucursalCuentaComponent {
  public selectedSucursal: SucursalCuenta["idSucursalCuenta"];
  loaderSucursales: boolean;
  constructor(
    public dialogRef: MatDialogRef<ModalSucursalCuentaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private app: ApplicationService,
    private sucursalesService: SucursalesService,
  ) {

  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {
   
    this.obtenerSucursalesCliente();
    if (this.data.idAcreditadoPG) {
      this.obtenerSucursalCuenta();
    } 


  }

  obtenerSucursalesCliente() {
    this.loaderSucursales = true;
    this.sucursalesService.obtieneSucursalesPorCliente(this.data.idCliente).subscribe({
      next: (res) => {
        if (res && res.length > 0) {
          this.data.sucursales = res;
          this.loaderSucursales = false;
        }
      },
      error: (err) => {
        this.app.showError(err);
        this.loaderSucursales = false;
      },
      complete: () => {
        this.loaderSucursales = false;
      }
    });
  }
  

  obtenerSucursalCuenta() {
    this.sucursalesService.sucursalCuenta(this.data.idAcreditadoPG, this.data.idCliente, this.data.idCuentaPG).subscribe({
      next: (res) => {
        if (res && res.length > 0) {
          // Asigna directamente el idSucursal recibido
          this.selectedSucursal = res[0].idSucursal;
    
          // Opcional: Si necesitas usar idSucursalKobra en otra parte
          this.data.idSucursalKobra = res[0].idSucursalKobra; 
        }
      },
      error: (err) => {
        this.app.showError(err);
      },
      complete: () => {
       
      }
    });
  }
  
  
  

  public actualizaSucursal(): void {
    if (!this.selectedSucursal) {
      this.app.showSnackbar(
        "Aviso",
        "Debe seleccionar una sucursal para actualizar.",
        3000,
        "error"
      );
      return;
    }
  
    // Filtrar las cuentas que tengan una sucursal seleccionada
    const cuentasAActualizar = this.data.items
      .map(item => {
        const sucursalSeleccionada = this.data.sucursales.find(
          sucursal => sucursal.idSucursal === this.selectedSucursal
        );
  
        if (!sucursalSeleccionada) {
          return null; // En caso de no encontrar la sucursal, ignorar este item
        }
  
        return {
          idCliente: this.data.idCliente,
          idAcreditado: item.idAcreditado,
          idExterno: item.idExterno,
          idSucursal: sucursalSeleccionada.idSucursal,
          idGrupo: sucursalSeleccionada.idGrupo,
          idCuentaPG: item.idCuentaPG
        };
      })
      .filter(item => item !== null); // Remover los valores nulos
  
    if (cuentasAActualizar.length === 0) {
      this.app.showSnackbar(
        "Aviso",
        "No hay cuentas válidas para actualizar.",
        3000,
        "error"
      );
      return;
    }
  
    // Llamada al servicio para actualizar las sucursales en lote
    const loading: number = this.app.showLoading('Actualizando cuentas.');
    this.sucursalesService.actualizarSucursalCuentas(this.data.idCliente, { cuentas: cuentasAActualizar }).subscribe({
      next: (res) => {
        this.app.hideLoading(loading);
        if (res.success) {
          this.app.showSnackbar(
            "Aviso",
            "Las cuentas se actualizaron correctamente.",
            3000,
            "success"
          );
          this.dialogRef.close(true);
        } else {
          this.app.showSnackbar(
            "Aviso",
            "Ocurrió un Error: " + res.error.message,
            5000,
            "error"
          );
          this.dialogRef.close(false);
        }
      },
      error: (err) => {
        this.app.hideLoading(loading);
        this.app.showError(err);
      },
      complete: () => {
       
      }
    });
  }
  
  

  limpiarsucursal(){
    this.selectedSucursal = null;
  }


}
