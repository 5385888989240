import { IUtcTime } from '../interfaces/utc-time';
export class UtcConversor {

  /**
   * Convierte una fecha en la zona horaria de México (America/Mexico_City) a UTC.
   * @param mexicoDateString Fecha en formato ISO o legible por Date.
   * @returns Fecha en UTC en formato ISO.
   */
  static mexicoToUTC(mexicoDateString: string): string {
    const mexicoTimeZone = 'America/Mexico_City';

    // Interpretar la fecha como si estuviera en la zona horaria de México
    const mexicoDate = new Date(
      new Intl.DateTimeFormat('en-US', {
        timeZone: mexicoTimeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      }).format(new Date(mexicoDateString))
    );

    // Convertir la fecha interpretada a UTC
    const utcDate = new Date(mexicoDate.toISOString());
    return utcDate.toISOString();
  }

  /**
   * Convierte una fecha en UTC a la zona horaria del navegador.
   * @param utcDateString Fecha en formato ISO o legible por Date en UTC.
   * @returns Objeto con la fecha local y la diferencia con UTC.
   */
  static utcToLocalWithOffset(utcDateString: string): IUtcTime {
    const utcDate = new Date(utcDateString);

    const localDate = new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }).format(utcDate);

    const timezoneOffset = new Date().getTimezoneOffset();
    const offsetHours = -timezoneOffset / 60;

    const formattedHours = Math.abs(Math.floor(offsetHours)).toString();

    const utcOffset = `UTC${offsetHours > 0 ? '+' : offsetHours < 0 ? '-' : ' '}${formattedHours}`;

    return { localDate, utcOffset };
  }

  /**
   * Convierte una fecha en De mexico completamente a la zona horaria del navegador.
   * @param utcDateString Fecha en formato ISO o legible por Date en UTC.
   * @param utc Determina si es utc o no
   * @returns Objeto con la fecha local y la diferencia con UTC.
   */
  static convertDateToLocal(dateString: string, utc: boolean = false): IUtcTime {
    const utcDate: string = utc ? dateString : this.mexicoToUTC(dateString);

    const localInfo:IUtcTime = this.utcToLocalWithOffset(utcDate);

    return localInfo;
  }

  /**
   * Convierte una fecha en De mexico completamente a la zona horaria del navegador para mostrar con todo y diferencias de horas.
   * @param utcDateString Fecha en formato ISO o legible por Date en UTC.
   * @param utc Determina si es utc o no
   * @returns Regresa un string con la información de diferencia de hora UTC.
   */
  static convertDateToLocalView(dateString: string, utc: boolean = false): string {
    // Agregar la "Z" si es UTC y no está presente
    const utcDateString = utc && !dateString.endsWith('Z') ? `${dateString}Z` : dateString;
  
    // Llamar a convertDateToLocal con la cadena ajustada
    const localInfo: IUtcTime = this.convertDateToLocal(utcDateString, utc);
    
    return `${localInfo.localDate} (${localInfo.utcOffset})`;
  }
  
}
