import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { ApplicationService } from 'src/app/application/shared/application.service';
import { ServiciosKobraService } from '@servicios/servicios-kobra.service';
import { VisitService } from 'src/app/visit/visit.service';
import { NgxStarsComponent } from 'ngx-stars';

@Component({
  selector: 'calificacion-visita-estrellas',
  templateUrl: './calificacion-visita-estrellas.component.html',
  styleUrls: ['./calificacion-visita-estrellas.component.css'],
  providers: [VisitService] // Solo se necesita VisitService
})
export class CalificacionVisitaEstrellasComponent {
  @Input() calificacion: number;
  @Input() idAgent: number;
  @Input() idFinanciera: number;
  @Input() idCliente: number;
  @Input() idVisita: number;
  @Input() visitaIndex: number;
  @Input() disabled: boolean;
  @Input() isSuperAdmin: boolean;
  @Input() etiquetasCuenta: any;
  @ViewChild(NgxStarsComponent) starsComponent: NgxStarsComponent;
  @Output() siguienteVisita: EventEmitter<any> = new EventEmitter<any>();


  public form: FormGroup;
  public comentarios: Observable<any>;
  public estrellas: number;

  constructor(
    private formBuilder: FormBuilder,
    private app: ApplicationService,
    private visitService: VisitService
  ) {
    this.form = this.formBuilder.group({
      idVisita: [''],
      idAgent: [''],
      idFinanciera: [''],
      idCliente: [''],
      idSuperAdministrador: [''],
      calificacion: ['', Validators.required],
      comentLibre: ['', Validators.required],
      idEtiquetaCuenta: [null],
      comentario: ['']
    });
  }

  ngOnInit() {
    this.obtenerComentarios();
    //this.crearRating();
    this.form.get('idVisita').patchValue(this.idVisita);
    this.form.get('idAgent').patchValue(this.idAgent);
    this.form.get('idFinanciera').patchValue(this.idFinanciera);
    this.form.get('idCliente').patchValue(this.idCliente);
    this.form.get('idSuperAdministrador').patchValue(localStorage.getItem('userID'));
  }

  ngAfterViewInit() {
    this.crearRating();
  }

  asignarCalificacion(event: any){
    this.estrellas = event;
    this.form.get('calificacion').patchValue(this.estrellas);
  }
  

  private crearRating(): void {
    if (this.starsComponent) {
      this.starsComponent.setRating(this.calificacion);
      this.starsComponent.readonly = this.disabled;
    }
  }
  

  public calificar(): void {
    this.form.get('comentario').patchValue(this.generaComentario());
    const loading = this.app.showLoading('Guardando calificación...');
    this.form.value.idAgente = this.form.value.idAgent;

    const callBack = (res: any) => {
      this.form.reset();
      this.form.get('comentLibre').patchValue('');
      this.app.hideLoading(loading);

      if (this.visitaIndex >= 0) {
        this.estrellas = 0;
        this.crearRating();
        this.siguienteVisita.emit('siguiente');
      } else {
        this.starsComponent.readonly = true;
        this.siguienteVisita.emit('loadInfo');
      }

      this.app.showSnackbar('¡Aviso!', 'Se ha calificado la visita', 3000, 'success');
    };

    const errorBack = (error: any) => {
      this.app.hideLoading(loading);
      this.app.showError(error);
    };

    this.visitService
      .post(`/${this.form.get('idVisita').value}/calificaciones`, this.form.value)
      .pipe(take(1))
      .subscribe(callBack, errorBack);
  }

  onCalificarVisita() {
    if (this.visitaIndex >= 0) {
      this.form.get('idSuperAdministrador').patchValue(localStorage.getItem('userID'));
      this.form.get('idVisita').patchValue(this.idVisita);
      this.form.get('idAgent').patchValue(this.idAgent);
      this.form.get('idFinanciera').patchValue(this.idFinanciera);
      this.form.get('idCliente').patchValue(this.idCliente);
      this.calificar();
    } else {
      this.form.get('idVisita').patchValue(this.idVisita);
      this.form.get('idAgent').patchValue(this.idAgent);
      this.form.get('idFinanciera').patchValue(this.idFinanciera);
      this.form.get('idCliente').patchValue(this.idCliente);
      this.calificar();
    }
  }

  generaComentario(): string {
    let comentario = this.form.get('comentLibre').value.trim() || '';
    comentario = comentario.trim();
    const lastChar: string = comentario.substr(comentario.length - 1);

    if (lastChar === ',') {
      comentario = comentario.slice(0, -1);
    }

    return comentario;
  }

  obtenerComentarios() {
    this.comentarios = this.visitService.get('/comentarios-calificacion', true).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        this.app.showError(error);
        return of([]);
      })
    );
  }

  validaSubmit() {
    return this.form.get('calificacion').valid && (this.form.get('comentLibre').valid && this.form.get('comentLibre').value.trim());
  }
}
