import { TutorialesService } from './../tutoriales/tutoriales.service';
import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import {ConfirmacionDialog} from "src/app/modales-genericos/confirmacion/confirmacion-dialog.component";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environmentSelector } from '../../../environments/environment.selector';
import { take } from 'rxjs/operators';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { LenderService } from './../..//lender/lender.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { Lender } from '@lender/lender.model';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';
import { Tutorial } from './modelos/tutorial.model';
import { FormGroup } from '@angular/forms';
import { TutorialPagination } from '../tutoriales/pagination.interface';

import {  ProductosService } from '../../catalogos/productos/productos.service';


@Component({
  selector: 'app-tutoriales',
  templateUrl: './tutoriales.component.html',
  styleUrls: ['./tutoriales.component.css'],
  providers: [LenderService]
})

export class TutorialesComponent {
  @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;
  public form: FormGroup;
  private environment = environmentSelector();
  sessionData: SessionData;
  public tutoriales: Tutorial[] = [];
  public itemsSelected: any[] = [];
  public pagination: TutorialPagination;
  public filtrosAbiertos: boolean = false;
  public queryParams: URLSearchParams;
  public listFields: any = {};
  public lenders: Lender[] = [];
  public idLender: number = 0;
  private slt: string = '*';
  public ayuda: boolean = false;
  public hijosActivos: boolean = false;
  public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltros: number = 0;

  tabla: any = {
      headers: false,
      multiSelect: false,
      columns: []
  };

  idProducto: number = 0;
  productos: any[] = [];
  idTipoProducto: number = 0;
  idProductoYNombre: string | null = null;

  public filters: any = {
      financiera: null,
      idCliente: null,
      idProducto: null,
      activa: true,
      filtered: false,
      page: 1
  };

  public datosConsulta: any = {
    pageSize: 100,
    rows: 50,
    sortField: 'idTutorial',
    sortAsc: true,
    query: "",
    fromRowPage: 0,
    toRowPage: 50
  };

  public loadings: any = {
      tutoriales: false,
      borrando: false,
      financieras: true
  };

  constructor(
    private filtrosService: FiltrosService,
    private lenderService : LenderService,
    private app: ApplicationService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private productosService: ProductosService,
    private tutorialesService: TutorialesService,
  ){
    this.limpiarFiltros();
    this.sessionData = this.localStorageService.getSessionData();
    this.tabla.columns = this.getTablaColumns();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();
  }

  ngOnInit() {
    if (!this.sessionData.isSuperAdmin) {
        this.filters.excluirDemo = false;
    }
    this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
  }

  private getTablaColumns(): Array<any> {
    return [
      {
        name: 'ID Tutorial',
        key: 'idTutorial',
        hide: true,
      },
      {
        name: 'Tutorial',
        key: 'titulo',
        hide: false,
      },
      {
        name: 'ID Cliente',
        key: 'idFinanciera',
        hide: true,
      },
      {
        name: 'Cliente',
        key: 'nombreCliente',
        hide: this.sessionData.isSuperAdmin ? false : true,
      },
      {
        name: 'ID Producto',
        key: 'idProducto',
        sortName: 'p.id_producto',
        hide: true,
      },
      {
        name: 'Producto',
        key: 'nombreProducto',
        hide: false,
      },
      {
        name: 'Creador',
        key: 'nombreUsuario',
        hide: false,
      },
      {
        name: 'Fecha Creación',
        key: 'fechaCreacion',
        hide: false,
        type: 'dateTime',
      },
    ];
  }
  
  checkControl(control: string) {
    return this.form.controls[control].invalid && this.form.controls[control].touched;
  }
  
  private agregarContabilizadoresDeFiltros(){
      this.contabilizadorFiltros.add('Generales', 'generales', [
          'financiera',
          'nombre'
        
      ]);

  }

  resultadoFiltros(filtrosNuevos: any) {
      if (filtrosNuevos) {
          this.filters = filtrosNuevos;
          this.filters.filter = true;
          this.filters.filtered = true;
      }
      this.idLender = this.filters.financiera ? this.filters.financiera.idFinanciera : null;
      this.loadLenders();
  }

  public loadLenders(): void {
    this.loadings.financieras = true;
    this.lenderService.getAll('active').subscribe(
        res => {
            this.lenders = res;
            this.onChangeLender(this.idLender);
        },
        err => {
            this.app.showError(err);
            this.loadings.financieras = false;
        },
        () => this.loadings.financieras = false
    );
  }

  onSelectProduct(idProducto: number): void {
    this.filters.idProducto = idProducto;
    let index: number = this.productos.findIndex(x=> x.idProducto == idProducto);
    if(index >=0){
      let producto: any = this.productos[index];
      this.idTipoProducto = producto.idTipoProducto;
      this.idProductoYNombre = producto.nombre + " (Promesa de pago)";
      this.filtrar(this.filters.page || 1); // Refresca la lista de tutoriales
    }
    else{
      this.idTipoProducto = 0;
      this.idProductoYNombre = null;
    }
    if(!idProducto){
      this.clearProductFilter();
    }
  }

  clearProductFilter(): void {
    this.filters.idProducto = null; // O undefined si el backend requiere que no esté en absoluto
    this.idProductoYNombre = null; // Limpia el nombre visualmente
    this.filtrosService.guardarFiltros(this.filters);
  }

  //INICIO EVENTOS TABLA
  onSelected(rows) {
    this.itemsSelected = rows.slice();
  }

  sortChange(sort: any) {
     if (sort.active == this.datosConsulta.sortField) {
         this.datosConsulta.sortAsc = !this.datosConsulta.sortAsc;
     } else {
         this.datosConsulta.sortField = sort.active;
         this.datosConsulta.sortAsc = true;
     }
     this.loadProductos(1);
  }

  public onChangeLender(event) {
    if (event) {
      
        const clienteSeleccionado = this.lenders.find(lender => lender.id === event);
        if (clienteSeleccionado) {
          // Cargar productos relacionados con el cliente
          this.lenders.forEach(lender => {
            if (lender.idFinanciera == event) {
                this.filters.financiera = lender;
            }
          });
          this.loadProductos(clienteSeleccionado.idCliente);
        }
    }
    else {
        this.filters.financiera = null;
    }
  }

  truncateInput(event: any, field: string): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value.length > 30) {
      inputElement.value = inputElement.value.slice(0, 30); // Trunca el texto en el campo de entrada
      this.filters[field] = inputElement.value; // Actualiza el modelo
    } else {
      this.filters[field] = inputElement.value; // Actualiza normalmente si está dentro del límite
    }
  }
  

  public contabilizadorDeFiltros(filtroName: string){
    return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filters);
  }

  public contabilizadorDeFiltrosTotales():void{
    let totalFiltrosArray: number[] = [];
    totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  onDoubleClick({ row }) {
    if(this.itemsSelected[0].editable){
        this.editarTutorial();
    }else{
        this.app.showSnackbar("¡Aviso!", "Este tutorial no es editable.", 0, "warning");
    }
  }

  inactivarHijo(){
      this.filtrosService.asignarOpcion();
      if(this.filters && (this.filters.financiera || this.filters.filtered)){
          this.filtrosService.guardarFiltros(this.filters, true);
      }
      this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
  }

  public filtrar(page: number){
    if(this.idLender){
        this.loadTutoriales(page);
    }else{
        this.filtrosSideNav.toggle();
        this.app.showSnackbar("Error", "Es necesario seleccionar un cliente.", 0, "warning");
    }
    this.contabilizadorDeFiltrosTotales();+
    this.filtrosService.guardarFiltros(this.filters);
  }

  public limpiarFiltros(){
    this.idLender = null;
    this.filters.nombre = null;
    this.idProducto = null;
    this.idProductoYNombre = null;
    this.filters = {
        financiera: this.filters.financiera,
        idProducto: this.filters.idProducto,
        nombre: null,
        activa: true,
        excluirDemo: false,
        filtered: false,
        page: 1
    }
    this.filtrosService.guardarFiltros(this.filters);
    this.loadProductos(1);
  }

  loadProductos(idCliente : number){
    this.loadings.productos = true;
    this.productosService.obtenerProductosPorCliente(idCliente)
    .pipe(
        take(1)
    ).subscribe((res: any) => {
        this.loadings.productos = false;
        res.data.forEach(element => {
          element.idProductoYNombre = element.idProducto + " - " + element.nomProducto;
        });
        this.productos = res.data;
    }, (err: any) => {
        this.loadings.productos = false;
        this.app.showError(err);
    });
  }

  public loadTutoriales(page: number): void {
      this.loadings.tutoriales = true;
      let query: string = this.crearURLQuery(page);
      this.tutorialesService.obtener(this.filters.financiera.idCliente, query)
      .pipe(
          take(1)
      ).subscribe((res: any) => {
          this.loadings.tutoriales = false;
          this.pagination = res.data;
          this.tutoriales = Tutorial.mapArray(res.data.pageData);
      }, (err: any) => {
          this.loadings.tutoriales = false;
          this.app.showError(err);
      });
      this.contabilizadorDeFiltrosTotales();
  }

  onPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !== this.pagination.page || newPagina.pageSize !== this.datosConsulta.rows){
        this.datosConsulta.rows = newPagina.pageSize;
        this.loadTutoriales(newPagina.pageIndex+1);
    }
  }

  calcFromToRowPage() {
    if (this.pagination.pageData.length <= 0) {
        this.datosConsulta.fromRowPage = 0;
        this.datosConsulta.toRowPage = 0;
    } else {
        this.datosConsulta.fromRowPage = (this.datosConsulta.rows * this.pagination.page) - (this.datosConsulta.rows - 1);

        if (this.pagination.page === this.pagination.pages) {
            this.datosConsulta.toRowPage = this.pagination.totalRows;
        } else {
            this.datosConsulta.toRowPage = this.pagination.page * this.datosConsulta.rows;
        }
    }
  }

  private crearURLQuery(page: number): string {
    let queryParams: string = '';
    let guardarFiltros: boolean = false;
    if (page != this.filters.page) {
        this.filters.page = page;
        guardarFiltros = true;
    }
    queryParams += `?slt=${this.slt}&paginate=true&page=${page}&rows=${this.datosConsulta.rows}`;
    if (this.datosConsulta.sortField) {
        queryParams += '&orderBy=' + this.datosConsulta.sortField + (this.datosConsulta.sortAsc ? ' asc' : ' desc');
    }
    if(this.filters.idProducto){
        queryParams += `&idProducto=${this.filters.idProducto}`;
        this.filters.filtered = true;
        guardarFiltros = true
    }
    if(guardarFiltros){
        this.filtrosService.guardarFiltros(this.filters);
    }
    return queryParams;
  }

    agregarTutorial() {
      if(this.filtrosAbiertos)
        {
            this.filtrosAbiertos = !this.filtrosAbiertos;
        }
        this.router.navigate(
            ['nuevo', {}],
            {
                relativeTo: this.route,
                queryParams: {} 
            }).then(() => {
                this.itemsSelected = [];
        });
    }
    // Función de edición del tutorial
    editarTutorial() {
      if(this.filtrosAbiertos)
        {
            this.filtrosSideNav.toggle();
            this.filtrosAbiertos = !this.filtrosAbiertos;
        }
      this.router.navigate(
        ['editar', {}],
        {
            relativeTo: this.route,
            queryParams: {
              idTutorial: this.itemsSelected[0].idTutorial,
              titulo: this.itemsSelected[0].titulo,
              idCliente: this.itemsSelected[0].idCliente,
              idFinanciera: this.itemsSelected[0].idFinanciera,
              idProducto: this.itemsSelected[0].idProducto,
              nombreProducto: this.itemsSelected[0].nombre,
              nombreCliente: this.itemsSelected[0].nombreCliente
            }
        }).then(() => {
            this.itemsSelected = [];
        });
    }

    openDialogConfirmacion(tutorial: Tutorial): void {
      if(this.filtrosAbiertos)
      {
          this.filtrosSideNav.toggle();
          this.filtrosAbiertos = !this.filtrosAbiertos;
      }
      const dialogRef = this.dialog.open(ConfirmacionDialog, {
        data:{
                titulo: "Confirmar",
                mensaje: "¿Deseas eliminar el tutorial seleccionado?",
                icono: "delete_forever",
                colorIcono: "warn",
                boton1: "No",
                boton2: "Sí",
                // texto: producto.nombre,
                claseAccion: "boton-accion-eliminar"
            }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result) {
            this.borrarTutorial(tutorial);
        }   
      });
    }
    //Elimina el tutorialactual
    borrarTutorial(tutorial: Tutorial) {
        this.loadings.borrando = true;
        this.app.showLoading("Eliminando registro...");
        this.tutorialesService.eliminarTutorial(tutorial.idCliente, tutorial.idTutorial)
        .pipe(
            take(1)
        ).subscribe((res: any) => {
            this.loadings.borrando = false;
            this.app.hideLoading();
            this.loadTutoriales(1);
        }, (err: any) => {
            this.loadings.borrando = false;
            this.app.showSnackbar("Error", "Ha ocurrido un error.", 0, "error");
            this.app.hideLoading();
            this.app.showError(err);
        });
    }
}

