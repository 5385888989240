
<router-outlet (activate)="activarHijo()" (deactivate)="inactivarHijo();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
  <div class="button-container">
    <div class="button-toggle-group-container">
      <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" class="right-align">
        <mat-button-toggle value="filtros" (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
          matTooltip="Filtrar cuentas" mat-tooltip-panel-above>
          <mat-icon class="kobra-icon">filter_alt</mat-icon>
          <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>

        </mat-button-toggle>
        <mat-button-toggle value="actualizar" [disabled]="!listFiltered" (click)="loadVisitasCalificadas(filtros.page)"
          matTooltip="Refrescar" mat-tooltip-panel-above>
          <mat-icon class="kobra-icon">sync</mat-icon>
        </mat-button-toggle>

        <mat-button-toggle value="ayuda" matTooltip="Ayuda" mat-tooltip-panel-above (click)="ayuda = !ayuda">
          <mat-icon color="primary">help</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <app-grid [loading]="loadingVisitas" [columns]="tablaVisitasCalificadas.columns" [items]="visitasCalificadas" (sortChange)="setSortField($event)"
    [headers]="tablaVisitasCalificadas.headers" [multiSelect]="tablaVisitasCalificadas.multiSelect" (onLinkRow)="onClick($event)">

    <div t-header>

      <mat-card *ngIf="ayuda" class="cardAyuda">

        <mat-card-content>
         <p><mat-icon color="primary">help</mat-icon>
            El módulo "Visitas Calificadas" es una herramienta para visualizar las visitas realizadas por los agentes dentro de fechas específicas y obligatorias.


          </p>
          Acciones:
          <p>
            <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda de las visitas realizadas.
          </p>
          <p>
            <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de filtros aplicados.
          </p>

        </mat-card-content>
      </mat-card>
    </div>

    <ng-container t-paginator>
      <mat-paginator showFirstLastButtons
        [length]="visitaCalificadaPaginator.pagination?.totalCount"
        [pageIndex]="visitaCalificadaPaginator.pagination?.currentPage-1" [pageSize]="pageSize" (page)="onPage($event)"
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </ng-container>
  </app-grid>
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button matTooltip="Ocultar filtros" mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()">
      <mat-icon>cancel</mat-icon>
    </button>
    Filtros
  </mat-toolbar>
  <div class="accordion-filtros">
  <mat-accordion [multi]="false">
    <mat-expansion-panel *ngIf="sessionData.isSuperAdmin" [expanded]="true">
      <mat-expansion-panel-header>
        Súper Administrador
        <span *ngIf="contabilizadorDeFiltros('superAdmin') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('superAdmin') }}</span>

      </mat-expansion-panel-header>
      <ol class="lista-filtros-side">
        <li class="filtro-side">
          <mat-select-search
            class="id-card-field"
            [label]="'Cliente'"
            [placeHolder]="'Selecciona un cliente'"
            [value]="'idFinanciera'"
            [labelField]="'name'"
            [icon]="'account_balance'"
            [options]="lenders"
            [(ngModel)]="idFinanciera"
            (ngModelChange)="onChangeLender($event);"
          >
          </mat-select-search>

        </li>

      </ol>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        Folio
        <span *ngIf="contabilizadorDeFiltros('folio') >= 1" class="notification-dot-filtros">{{ contabilizadorDeFiltros('folio') }}</span>

      </mat-expansion-panel-header>
      <ol class="lista-filtros-side">
        <li class="filtro-side">
          <mat-form-field style="width: 100%;">
            <mat-label>Folio Visita</mat-label>
            <input matInput textSanitizer
            [(ngModel)]="filtros.folioVisita"
            (ngModelChange)="this.contabilizadorDeFiltros('folio');"
            placeholder="Capture el folio de la visita">
            <mat-icon matSuffix  class="icono-limpiar"
            *ngIf="filtros.folioVisita"
            (click)="filtros.folioVisita = null; this.contabilizadorDeFiltros('folio');">clear</mat-icon>
            <mat-icon matSuffix>account_circle</mat-icon>
          </mat-form-field>

        </li>

      </ol>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        Fechas
        <span *ngIf="this.contabilizadorDeFiltros('fechas') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechas') }}</span>

      </mat-expansion-panel-header>
      <ol class="lista-filtros-side">
        <li class="filtro-side">

              <ol class="lista-filtros-side">
                <li class="filtro-side">
                  <mat-date-picker-formater
                    [label]="'Fecha inicio'"
                    [placeHolder]="'Capture la fecha inicio'"
                    [max]="filtros.fechaFin ? filtros.fechaFin : today"
                    [(ngModel)]="filtros.fechaInicio"
                    (ngModelChange)="this.contabilizadorDeFiltros('fechas');"
                  />

                </li>
                <li class="filtro-side">
                  <mat-date-picker-formater
                    [label]="'Fecha fin'"
                    [placeHolder]="'Capture la fecha fin'"
                    [min]="filtros.fechaInicio"
                    [max]="today"
                    [(ngModel)]="filtros.fechaFin"
                    (ngModelChange)="this.contabilizadorDeFiltros('fechas');"
                  />

                </li>
              </ol>

        </li>
      </ol>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        Agente
        <span *ngIf="this.contabilizadorDeFiltros('agente') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('agente') }}</span>

      </mat-expansion-panel-header>
      <ol class="lista-filtros-side">
        <li class="filtro-side">
          <mat-select-search
            class="id-card-field"
            [label]="'Agente calificado'"
            [placeHolder]="'Busca por id o nombre'"
            [value]="'option'"
            [labelField]="'nombre'"
            [icon]="'account_circle'"
            [realizarBusqueda]="agenteCalificadoFilterSearch"
            [(ngModel)]="filtros.agenteCalificado"
            [minBusqueda]="1"
            (ngModelChange)="this.contabilizadorDeFiltros('agente');"


          >
          </mat-select-search>

        </li>
        <li class="filtro-side">
          <mat-select-search
            class="id-card-field"
            [label]="'Agente calificador'"
            [placeHolder]="'Busca por id o nombre'"
            [value]="'option'"
            [labelField]="'nombre'"
            [icon]="'account_circle'"
            [realizarBusqueda]="agenteCalificadorFilterSearch"
            [(ngModel)]="filtros.agenteCalificador"
            (ngModelChange)="this.contabilizadorDeFiltros('agente');"

            [minBusqueda]="1"

          >
          </mat-select-search>
        </li>
      </ol>
    </mat-expansion-panel>
  </mat-accordion>
</div>


<ol class="lista-filtros-buttons">
  <button
  class="boton-accion-front"
    style="width: 100%;"
    mat-stroked-button
    color="primary"
    (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; setFilter(); ">
      <mat-icon>filter_alt</mat-icon>
      Filtrar
  </button>
  <button
    style="width: 100%; margin-top: 10px;"
    mat-stroked-button
    (click)="clearFilter();">
      <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
      Limpiar Filtros
  </button>
</ol>
</mat-sidenav>
