import { Injectable } from '@angular/core';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { environmentSelector } from 'src/environments/environment.selector';
import { Tutorial } from '../tutoriales/modelos/tutorial.model';

@Injectable({
  providedIn: 'root'
})
export class TutorialesService {
  baseURL: string = environmentSelector().kobraServices.visitas;
  
  constructor(
    private serviciosVisitas: ServicesVisitasService
  ) { }

  public obtener(idCliente: number, queryParams: string){
    return this.serviciosVisitas.get(`/tutoriales/${idCliente}/tutoriales${queryParams}`);
  }

  public nuevoTutorial(idCliente: number, tutorial: Tutorial) {
    const url = `/tutoriales/${idCliente}/tutoriales`; // Ruta dinámica basada en idCliente
    return this.serviciosVisitas.post(url, tutorial); // Realizar POST con el tutorial
  }

  public obtenerTiposContenido(){
    return this.serviciosVisitas.get(`/tutoriales/tipos-contenido`);
  }

  public obtenerDetallesTutorial(idCliente: number, idTutorial: number){
    return this.serviciosVisitas.get(`/tutoriales/${idCliente}/tutoriales/${idTutorial}`);
  }

  public actualizarTutorial(idCliente: number, idTutorial: number, tutorial: Tutorial) {
    return this.serviciosVisitas.patch(`/tutoriales/${idCliente}/tutoriales/${idTutorial}`, tutorial);
  }

  public eliminarTutorial(idCliente: number, idTutorial: number){
    return this.serviciosVisitas.delete(`/tutoriales/${idCliente}/tutoriales/${idTutorial}`);
  }
}
