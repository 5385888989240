<app-loader *ngIf="cargandoVisita && visitasACalificar" [message]="'Cargando detalle de visita...'"></app-loader>
<div class="app content kobra" [ngClass]="{'contenido-principal': visitasACalificar}" *ngIf="!cargandoVisita || !visitasACalificar">
    <div class="minimal-header-custom">
        <mat-icon class="icon-custom kobra-icon" aria-hidden="false" aria-label="Icono folio">description</mat-icon>
        <span class="label-custom">Folio:</span>
        <span class="folio-number-custom">{{visit.folio}}</span>
    </div>

    <div class="super-rating" *ngIf="((!calificacionSuperAdministrador && sessionData.isSuperAdmin)
    || (!calificacionAdministradorFinanciera && !sessionData.isSuperAdmin))
    && !visit.cancelled">
        <div>
            <h1 *ngIf="sessionData.isSuperAdmin">Calificación súper administrador</h1>
            <h1 *ngIf="!sessionData.isSuperAdmin">Califica la visita</h1>
            <calificacion-visita-estrellas [etiquetasCuenta]="etiquetasCuenta" [idVisita]="idVisita" [idAgent]="idAgent"
                [idFinanciera]="idFinanciera" [visitaIndex]="visitaIndex" [calificacion]="resetRate" [idCliente]="idCliente"
                [isSuperAdmin]="sessionData.isSuperAdmin" (siguienteVisita)="obtenerVisita($event)">
            </calificacion-visita-estrellas>
        </div>
    </div>

    <div class="custom-negative-message" *ngIf="visit.generalChecklist && visit.generalChecklist.mockLocation">
        Esta visita se ha realizado utilizando ubicaciones falsas.
    </div>

    <div class="custom-info-message" *ngIf="visit.cancelled">
        <div style="font-weight: bold;">
            Visita cancelada
        </div>
    </div>

    <div class="contenedorDetalleCuentaGeneral2">
        <div class="contenedorCuentaProducto">
          <span class="nombreProducto" *ngIf="visit.nombreProducto">
            {{visit.nombreProducto}}
          </span>

        </div>
    </div>

    <div class="button-container-wrapper button-container-v2">
        <div class="button-toggle-group-container right">
            <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" class="right-align">
                <mat-button-toggle value="subMenuAclaracion"
                *ngIf="visit.puedeAclarar || clarification?.idStatus === CLARIFICATION_STATUS.REVISION"
                    matTooltip="Aclaración"
                    mat-tooltip-panel-above
                    [matMenuTriggerFor]="menuClarification">
                    <mat-icon class="kobra-icon">info_outline</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="subMenu"
                    matTooltip="Acciones"
                    mat-tooltip-panel-above
                    [matMenuTriggerFor]="menu">
                    <mat-icon class="kobra-icon">more_vert</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <mat-menu #menu="matMenu">
            <button mat-menu-item
                [disabled]="!formatos"
                (click)="openDialogFormato()">
                <mat-icon class="kobra-icon">description</mat-icon>
                <span>Formatos</span>
            </button>
            <button mat-menu-item
                [disabled]="revisita || cargandoHistorialDeDcomiclios || !clienteActivo"
                (click)="openDialogAsignar()" >
                <mat-icon class="kobra-icon"> add_thick</mat-icon>
                <span>Solicitar revisita</span>
            </button>
            <button mat-menu-item
                [disabled]=" clienteActivo"
                (click)="openDialogEstatus()" >
                <mat-icon class="kobra-icon">sync</mat-icon>
                <span>Estatus</span>
            </button>
            <!-- <button mat-menu-item
                [disabled]=" clienteActivo"
                (click)="openDialogEtiqueta()">
                <mat-icon class="kobra-icon">sync</mat-icon>
                <span>Actualizar cuenta</span>
            </button> -->
            <button mat-menu-item
                *ngIf="!visitasACalificar" (click)="downloadPDF()">
                <mat-icon class="kobra-icon">download</mat-icon>Descargar visita
            </button>
            <button mat-menu-item
                *ngIf="!visitasACalificar && archivoGestor"
                (click)="downloadContrato()">
                <mat-icon class="kobra-icon">download</mat-icon>Descargar contrato
            </button>
        </mat-menu>

        <mat-menu #menuClarification="matMenu">
            <button *ngIf="visit.puedeAclarar && !clarification" mat-menu-item (click)="requestClarification()" matTooltip="Solicitar aclaración">
                <mat-icon mat-list-icon class="kobra-icon">feedback</mat-icon>
                Solicitar
            </button>

            <button *ngIf="clarification?.idStatus === CLARIFICATION_STATUS.REVISION" mat-menu-item (click)="approveClarification()" matTooltip="Aprobar aclaración">
                <mat-icon mat-list-icon class="kobra-icon">done</mat-icon>
                Aprobar
            </button>

            <button *ngIf="clarification?.idStatus === CLARIFICATION_STATUS.REVISION" mat-menu-item (click)="rejectClarification()" matTooltip="Rechazar aclaración">
                <mat-icon mat-list-icon class="kobra-icon">clear</mat-icon>
                Rechazar
            </button>
        </mat-menu>
    </div>

    <div style="margin-right: 20px;" *ngIf="!loadingClarification && clarification">
        <!-- <div class="warning">
            <div class="warning">
                <div class="container-aclaracion">
                    <div style="margin-bottom: 10px;">
                        <span class="span-aclaracion">Aclaración ({{ clarification.status }})</span>
                    </div>
                    <div class="icon-text-container">
                        <mat-icon class="mat-icon-aclara">account_balance</mat-icon>
                        <span class="description-text">{{ clarification.description }}</span>
                    </div>
                    <div class="icon-text-container">
                        <mat-icon class="mat-icon-aclara" color="warn" *ngIf="clarification.idStatus === CLARIFICATION_STATUS.REJECTED" >clear
                        </mat-icon>
                        <mat-icon class="kobra-icon mat-icon-aclara" *ngIf="clarification.idStatus === CLARIFICATION_STATUS.APPROVED" >done
                        </mat-icon>
                        <span class="reason-text">{{ clarification.reason }}</span>
                    </div>
                </div>
            </div>

        </div>       -->

        <mat-card class="warning">
            <mat-card-header>
                <mat-card-title>Aclaración ({{ clarification.status }})</mat-card-title>
            </mat-card-header>
            <mat-card-content class="contendor-clara-mat">

                <div class="icon-text-container">
                    <mat-icon class="mat-icon-aclara">account_balance</mat-icon>
                    <span class="description-text">{{ clarification.description }}</span>

                </div>
                <div style="margin-left: 30px;">
                    <span class="fechaAclaracion">{{clarification.date | date:'dd/MM/yyyy HH:mm'}}</span>
                </div>
                <div class="icon-text-container" style="margin-left: 30px; margin-top: 10px;">
                    <mat-icon class="mat-icon-aclara" color="warn" *ngIf="clarification.idStatus === CLARIFICATION_STATUS.REJECTED">clear
                    </mat-icon>
                    <mat-icon class="kobra-icon mat-icon-aclara" *ngIf="clarification.idStatus === CLARIFICATION_STATUS.APPROVED">done</mat-icon>
                    <span class="reason-text">{{ clarification.reason }}</span>

                </div>
                <div style="margin-left: 60px;">
                    <span class="fechaAclaracion">{{clarification.fechaActualizado | date:'dd/MM/yyyy HH:mm'}}</span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="435px">

        <!-- Visitada por -->
        <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card-section margen-card">
                <mat-card-header>
                    <mat-card-title>Visitada por</mat-card-title>
                </mat-card-header>

                <mat-card-content class="card-content">
                    <div class="content">
                        <div>
                            <span style="font-weight: bold;">{{visit.agent.idCobrador}} - {{visit.agent.nombreCompleto}}</span>
                        </div>
                        <div class="meta">
                            <div *ngIf="visit.cancelled; else elseDate"><i>{{visit.createdAt | date:'dd/MM/yyyy HH:mm'}} Hrs.</i></div>
                            <ng-template #elseDate>
                                {{visit.generalChecklist.id ?
                                (visit.generalChecklist.createdAt | date:'dd/MM/yyyy HH:mm') + ' Hrs.' :
                                'No se ha agregado un checklist'
                                }}
                            </ng-template>
                        </div>
                        <div class="mat-description">
                            <div *ngIf="visit.motivoCancelacion">
                                <strong>Comentario de cancelación</strong>
                                <p>{{visit.motivoCancelacion}}</p>
                            </div>
                            <div *ngIf="visit.generalChecklist?.id">
                                <!-- <div style="padding: 10px 0;">
                                    <mat-icon class="kobra-icon"> local_phone</mat-icon>
                                    {{visit.agent['telCel'] || 'No tiene celular registrado'}}
                                </div> -->
                                <div>
                                    <strong class="comment-title">Comentario de Visita</strong>

                                    <p class="comment-info" *ngIf="visit.comentariosIA">Corregido por IA</p>

                                    <ng-container *ngIf="visit.comentariosIA">
                                        <p class="comment-text">
                                            {{ isComentarioIA ? visit.comentariosIA : visit.comentarios }}
                                            <a class="toggle-link" (click)="toggleCommentView(visit)"><i>{{ isComentarioIA ? 'Ver original' : 'Ver corrección por IA' }}</i></a>
                                        </p>
                                    </ng-container>
                                    <ng-container *ngIf="!visit.comentariosIA">
                                        <p class="comment-text">
                                            {{ visit.comentarios }}
                                        </p>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <div class="form">
                            <div class="row">
                                <div class="column">
                                    <mat-label style="font-weight: bold;">Telefonos Recopilados</mat-label>
                                    <div *ngFor="let phoneCollected of phonesCollected">
                                        {{phoneCollected.phone}}
                                    </div>
                                    <div *ngIf="!phonesCollected.length">
                                        La visita no cuenta con teléfonos recopilados
                                    </div>
                                </div>
                                <div class="column">
                                    <mat-label style="font-weight: bold;">Emails Recopilados</mat-label>
                                    <div *ngFor="let emailCollected of emailsCollected">
                                        {{emailCollected.email}}
                                    </div>
                                    <div *ngIf="!emailsCollected.length">
                                        La visita no cuenta con emails recopilados
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                              <div class="column">
                                <mat-label style="font-weight: bold;">Mock Location</mat-label>
                                <div>
                                    <div style="word-break: break-word;">
                                        {{visit.mock ? 'Sí' : 'No'}}
                                    </div>
                                </div>
                              </div>
                              <div class="column">
                                <mat-label style="font-weight: bold;">Offline</mat-label>
                                <div>
                                    <div style="word-break: break-word;">
                                        {{visit.offline ? 'Sí' : 'No'}}
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                                <div class="column">
                                    <mat-label style="font-weight: bold;">Calificación Administrador Financiera</mat-label>
                                    <div *ngIf="calificacionAdministradorFinanciera; else sinCalificarAdministrdorFinanciera">
                                        <calificacion-visita-estrellas
                                            [calificacion]="calificacionAdministradorFinanciera.calificacion"
                                            [disabled]="true">
                                        </calificacion-visita-estrellas>
                                        <div style="word-break: break-word;">
                                            {{calificacionAdministradorFinanciera.comentario}}
                                        </div>
                                    </div>
                                    <ng-template #sinCalificarAdministrdorFinanciera>
                                        Sin calificar
                                    </ng-template>
                                </div>

                                <div class="column" *ngIf="calificacionSuperAdministrador">
                                    <mat-label style="font-weight: bold;">Calificación Súper Administrador</mat-label>
                                    <div *ngIf="calificacionSuperAdministrador; else calificar">
                                        <calificacion-visita-estrellas
                                            [calificacion]="calificacionSuperAdministrador.calificacion"
                                            [disabled]="true">
                                        </calificacion-visita-estrellas>
                                        <div style="word-break: break-word;">
                                            {{calificacionSuperAdministrador?.comentario}}
                                        </div>
                                    </div>
                                    <ng-template #calificar>
                                        <calificacion-visita-estrellas [idVisita]="idVisita" [disabled]="true"></calificacion-visita-estrellas>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-footer *ngIf="loaderRates" class="kobra-card-footer">
                    <i>Cargando información...</i>
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card-footer>
            </mat-card>
        </mat-grid-tile>

        <!-- informacion de la cuenta -->
        <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card-section margen-card">
                <mat-card-header>
                    <mat-card-title>Información de la cuenta</mat-card-title>
                </mat-card-header>

                <mat-card-content *ngIf="!detalleAcreditadoStack.length" class="card-content">
                    <div *ngIf="!detalleAcreditadoStack.length">
                        <div style="font-weight: bold;">
                            {{client['idAcreditado']}} - {{client.fullName}}
                        </div>
                        <div style="margin-top: 10px;">
                            <strong>Cliente:</strong>
                            <p>{{financiera.nombre}}</p>
                        </div>
                        <div class="warning-message"  *ngIf="visit.rebound">
                            Este domicilio ha sido repunteado por el Agente Kobra.
                        </div>
                        <div>
                            <strong>Folio Domicilio:</strong>
                            <p>{{clientAddress.folioDomicilio}}</p>
                        </div>
                        <div>
                            <strong>Domicilio:</strong>
                            <p>{{clientAddress.fullAddress}}</p>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-footer *ngIf="detalleAcreditadoStack.length" class="kobra-card-footer">
                  <i>Cargando detalle de la cuenta...</i>
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card-footer>
            </mat-card>
        </mat-grid-tile>

    </mat-grid-list-responsive>

    <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="600px">
        <!--Cuestionario -->
        <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card-section-600 margen-card">
                <mat-card-header>
                  <mat-card-title>Cuestionario</mat-card-title>
                  <mat-card-subtitle>{{ visit.nombreProducto }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content class="card-content-cuestionario">
                  <div *ngFor="let checklist of checklists.preguntas">
                    <div class="row">
                      <div class="column">
                        <strong>{{ checklist.nombrePregunta }}</strong>
                        <div *ngIf="checklist.respuestas.length > 1">
                            <mat-chip-listbox aria-label="Fish selection" class="mat-chip-respuestas">
                                <div style="margin-left: 10px; margin-top: 5px;" *ngFor="let respuesta of checklist.respuestas">
                                    <mat-chip *ngIf="(respuesta.idTipoRespuesta != 11 || !respuesta.fullPath) && respuesta.idTipoRespuesta != 12 || !respuesta.valorRespuesta" class=" chip-checklists-respuestas" >
                                      <b>{{ respuesta.nombreRespuesta }}</b><br>
                                      {{ getAnswerValue(respuesta) }}
                                    </mat-chip>
                                    <div *ngIf="respuesta.idTipoRespuesta === 11 && respuesta.fullPath" >
                                        <div class="foto">
                                            <mat-chip>{{respuesta.nombreRespuesta}}</mat-chip>
                                        </div>
                                        <foto-zoom style="text-align: center !important;" [foto]="respuesta.fullPath" [posicion]="1" [height]="150" (click)="cargarFotoCompleta(respuesta.fullPath)"></foto-zoom>
                                    </div>
                                    <div *ngIf="respuesta.idTipoRespuesta == 12 && respuesta.valorRespuesta" style="max-height: 380px; overflow: auto;">
                                      <div *ngIf="respuesta.valorRespuesta && respuesta.valorRespuesta.length > 0" class="button-container tipo-tabla">
                                        <div class="button-toggle-group-container">
                                          <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
                                            <mat-button-toggle value="subMenu"
                                              matTooltip="Expandir"
                                              mat-tooltip-panel-above
                                              (click)="abrirDialogoTabla(checklist, respuesta);">
                                              <mat-icon class="kobra-icon">open_in_new</mat-icon>
                                            </mat-button-toggle>
                                          </mat-button-toggle-group>
                                        </div>
                                      </div>

                                      <div class="foto">
                                          <mat-chip>{{respuesta.nombreRespuesta}}</mat-chip>
                                      </div>
                                      <app-table
                                        *ngIf="respuesta.valorRespuesta"
                                        [columns]="respuesta.columnsTabla"
                                        [items]="respuesta.valorRespuesta"
                                        [headers]="false"
                                        [tableHeaders]="false"
                                        [ignoreSortChange]="true"
                                        [multiSelect]="false"
                                        >
                                      </app-table>
                                    </div>
                                  </div>
                            </mat-chip-listbox>

                        </div>
                        <div style="margin-left: 10px; margin-top: 5px;" *ngIf="checklist.respuestas.length === 1">
                            <mat-chip *ngIf="!getSingleAnswerValue(checklist).hasPhoto && !getSingleAnswerValue(checklist).hasTabla" class="chip-checklists-respuestas"> {{ getSingleAnswerValue(checklist).valor }}</mat-chip>
                            <div *ngIf="getSingleAnswerValue(checklist).hasPhoto">
                                <foto-zoom *ngIf="checklist.respuestas[0].fullPath" [foto]="checklist.respuestas[0].fullPath" [posicion]="1" [height]="150" (click)="cargarFotoCompleta(checklist.respuestas[0].fullPath)"></foto-zoom>
                                <mat-chip *ngIf="!checklist.respuestas[0].fullPath" class="chip-checklists-respuestas"> {{ getSingleAnswerValue(checklist).valor }}</mat-chip>
                            </div>
                            <div *ngIf="getSingleAnswerValue(checklist).hasTabla" style="max-height: 380px; overflow: auto;">
                              <div class="button-container tipo-tabla" *ngIf="checklist.respuestas[0].valorRespuesta && checklist.respuestas[0].valorRespuesta.length > 0">
                                <div class="button-toggle-group-container">
                                  <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
                                    <mat-button-toggle value="subMenu"
                                      matTooltip="Expandir"
                                      mat-tooltip-panel-above
                                      (click)="abrirDialogoTabla(checklist, checklist.respuestas[0]);">
                                      <mat-icon class="kobra-icon">open_in_new</mat-icon>
                                    </mat-button-toggle>
                                  </mat-button-toggle-group>
                                </div>
                              </div>
                              <app-table
                                *ngIf="checklist.respuestas[0].valorRespuesta"
                                [columns]="checklist.respuestas[0].columnsTabla"
                                [items]="checklist.respuestas[0].valorRespuesta"
                                [headers]="false"
                                [tableHeaders]="false"
                                [ignoreSortChange]="true"
                                [multiSelect]="false"
                                >
                              </app-table>
                              <mat-chip *ngIf="!checklist.respuestas[0].valorRespuesta" class="chip-checklists-respuestas">{{ getAnswerValue(checklist.respuestas[0]) }}</mat-chip>
                            </div>
                        </div>
                      </div>
                      <div class="column" *ngIf="checklist.nombrePregunta == 'Etiqueta' && sessionData.isSuperAdmin && checklist.respuestas[0]['options'].length > 0">
                        {{ etiquetaActualObtener(checklist) }}
                        <button style="margin-top: 6%; " mat-stroked-button  (click)="openDialogCheckListsEtiqueta()">
                            <mat-icon class="kobra-icon">sync</mat-icon>
                            Cambiar etiqueta
                        </button>
                    </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
        </mat-grid-tile>

        <!-- Fotografias -->
        <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card-section-600 margen-card">
                <mat-card-header>
                    <mat-card-title>Fotografías capturadas</mat-card-title>
                </mat-card-header>

                <mat-card-content class="card-content-600">
                    <div class="kobra two columns">
                        <div class="kobra column" *ngFor="let photo of visit.visitPhoto" align="center">
                            <foto-zoom [foto]="photo.fullPath" [height]="250" [posicion]="0" (click)="cargarFotoCompleta(photo.fullPath)"></foto-zoom>
                        </div>
                    </div>
                    <div *ngIf="!visit.visitPhoto?.length">
                        No se caputuraron fotografías
                    </div>
                </mat-card-content>
                <mat-card-footer *ngIf="cargarFotos" class="kobra-card-footer">
                   <i>Cargando imagenes...</i>
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card-footer>
            </mat-card>
        </mat-grid-tile>

        <!-- detalle de movimientos -->
        <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="card-section-600 margen-card">
                <mat-card-header>
                    <mat-card-title>Historial de ubicaciones</mat-card-title>
                </mat-card-header>

                <mat-card-content class="card-content-600">
                        <app-table  [columns]="tablaCoordenadas.columnas"
                            [items]="visit.coordenadasVisita" [headers]="tablaCoordenadas.headers"
                            [tableHeaders]="tablaCoordenadas.tableHeaders" [ignoreSortChange]="true"
                            [multiSelect]="tablaCoordenadas.multiSelect" [classContainer]="'table-container withover without-max-height'" (onSelectedRows)="onSelected($event)" (onLinkRow)="onLinkRowCoordenada($event)">
                        </app-table>
                </mat-card-content>
                <mat-card-footer *ngIf="loadingCoordenadas" class="kobra-card-footer">
                   <i>Cargando movimientos de la visita...</i>
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card-footer>
            </mat-card>
        </mat-grid-tile>

        <!-- mapa -->
        <mat-grid-tile colspan="1" rowspan="1" style=" width: 50%;">
            <mat-card class="card-section-600 margen-card">
                <mat-card-header>
                    <mat-card-title>Ubicación</mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <div style="height: 440px;  width: 86%;">
                        <mapa-detalle [coordenadas]="coordenadasSeleccionadas"></mapa-detalle>
                    </div>
                </mat-card-content>
                <mat-card-footer *ngIf="loadingCoordenadas" class="kobra-card-footer">
                   <i>Cargando mapa...</i>
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card-footer>
            </mat-card>
        </mat-grid-tile>

    </mat-grid-list-responsive>

    <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="545px">
        <mat-grid-tile colspan="1" rowspan="1">
            <ng-container *ngIf="visit && visit.account && visit.account.idCuentaPG && idCliente">
                <app-timeline-cuenta #timelineCuenta style="width: 100%; margin-right: 20px !important;" height="300px" [idCuenta]="visit.account.idCuentaPG" [idCliente]="idCliente" [idAcreditado]="visit.account.idAcreditadoPG"></app-timeline-cuenta>
            </ng-container>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
        </mat-grid-tile>
    </mat-grid-list-responsive>

    <!-- <mat-card *ngIf="!loadingClarification && clarification" class="card-section-table" style="margin-top: 20px !important;" >
        <mat-card-header>
            <mat-card-title>Aclaración ({{ clarification.status }})</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div>
                <mat-list  class="content-display">
                    <mat-list-item class="content-display">
                        <mat-icon *ngIf="clarification.idStatus === CLARIFICATION_STATUS.REJECTED" mat-list-icon>clear
                        </mat-icon>
                        <mat-icon *ngIf="clarification.idStatus === CLARIFICATION_STATUS.APPROVED" mat-list-icon>done
                        </mat-icon>
                        <h2 mat-line> {{ clarification.reason }} </h2>
                    </mat-list-item>

                    <mat-list-item class="content-display">
                        <mat-icon mat-list-icon>info</mat-icon>
                        <h2 mat-line style="white-space: normal;"> {{ clarification.description }} </h2>
                    </mat-list-item>
                </mat-list>
            </div>
        </mat-card-content>
        <mat-card-footer *ngIf="loadingClarification" class="kobra-card-footer">
           <i>Cargando información...</i>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
    </mat-card> -->

    <!-- Historial de etiquetas de la cuenta -->
    <mat-card *ngIf="etiquetasCuentaHistorial.length" style="margin-top: 20px !important;">
        <mat-card-header>
            <mat-card-title>Historial de etiquetas de la cuenta</mat-card-title>
        </mat-card-header>

        <mat-card-content style="max-height: 300px; overflow: auto;">
            <app-container>
                <app-container>
                    <app-table
                        [columns]="tablaEtiquetasCuentas.columnas"
                        [items]="etiquetasCuentaHistorial"
                        [headers]="tablaEtiquetasCuentas.headers"
                        [tableHeaders]="tablaEtiquetasCuentas.tableHeaders"
                        [ignoreSortChange]="true"
                        [multiSelect]="tablaEtiquetasCuentas.multiSelect"

                        (onLinkRow)="onLinkRowVisita($event)">
                    </app-table>
                </app-container>
            </app-container>
        </mat-card-content>
        <mat-card-footer *ngIf="loadingHistorialEtiquetasCuenta">
           <i>Cargando información...</i>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
    </mat-card>

    <!-- Historial de pagos recabados -->
    <mat-card *ngIf="contadorPagos" style="margin-top: 20px !important;">
        <mat-card-header>
            <mat-card-title>Historial de pagos recabados</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div align="right">
                <span class ="montoTotal">
                    Total recabado: ${{montoTotal | number}}
                </span>
            </div>
            <app-container style="max-height: 300px; overflow: auto;">
                <app-table [columns]="tablaDineroRecuperado.columnas"
                    [items]="dineroRecuperado" [headers]="tablaDineroRecuperado.headers"
                    [tableHeaders]="tablaDineroRecuperado.tableHeaders" [ignoreSortChange]="true"
                    [multiSelect]="tablaDineroRecuperado.multiSelect">
                </app-table>
            </app-container>
        </mat-card-content>
        <mat-card-footer *ngIf="loadingDineroRecuperado">
           <i>Cargando información...</i>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
    </mat-card>

</div>





