import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from "rxjs/internal/operators/map";
import { AgentService } from "../../../agent/agent.service";
import { take } from "rxjs/internal/operators/take";
import { ApplicationService } from "../../../application/shared/application.service";
import moment from 'moment';
import { FormGroup, FormBuilder } from "@angular/forms";
import { ConfirmacionDialog } from "../../../modales-genericos/confirmacion/confirmacion-dialog.component";
import { AccountService } from "../../account.service";
import { Subscription } from "rxjs";
import { Account } from "../../account.model";
import { LenderService } from '@lender/lender.service';
import { Utils } from 'src/app/shared/components/utils';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
    selector: 'asignar-cuenta.component',
    templateUrl: 'asignar-cuenta.component.html',
    styleUrls: ["asignar-cuenta.component.css"],
    providers: [
        AgentService,
        AccountService,
        LenderService

    ]
})
export class ModalAsignarCuentaComponent {

    public idCobradorAsignado: number;
    public assignComment: any;
    public momentFecha;
    public momentVista;
    public formAsignacion: FormGroup;
    public establecerVisita: boolean = true;
    public domicilioAsignarWarning: boolean = false;
    public establecerFecha: boolean = true;
    public itemsDomcilio: boolean = true;
    public idDomicilioAsignar: any = null;
    public idCobrador: number;
    public horariosVisitas = {};
    public fechaVisita: any;
    public fechaLimite: any;
    public assignMessage: string;
    public account: Account;
    public fechaAsignacionMaxDate: Date;
    public fechaAsignacionMinDate: Date = new Date();
    public hoy = new Date();

    public idAgenteRevisita: any;
    public asignada: boolean = false;
    public mostrarScroll: boolean = false;
    public idAgente: any;
    public agente: any;
    public mensajeAviso: string[] = []; // Define un array para los mensajes de alerta
    public idClientes: number[];
    mensajeDisctancia:string;
    distanciaCuenta: number;
    utils = Utils;
    idsClientes:string;
    idsProductos:string;

    constructor(
        public dialogRef: MatDialogRef<ModalAsignarCuentaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private agenteServ: AgentService,
        private app: ApplicationService,
        private formBuilder: FormBuilder,
        private accountService: AccountService,
        private lenderService: LenderService,
        public dialog: MatDialog
    ) {
        this.createForms();
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    ngOnInit() {
        this.account = this.data.account;
        this.obtenerIds();
        if (this.data.domicilios.length === 1) {
            this.formAsignacion.get("idDomicilio").patchValue(this.data.domicilios[0].idDomicilioAcreditado);
        }
        this.inicializarAgente();
        this.obtenerDiasVencimiento();

        this.formAsignacion.get('fechaVisita').valueChanges.subscribe(selectedDate => {
            if (selectedDate ) {
                this.actualizarMensajesConFechaLimite(selectedDate);
            }
        });

    }

    obtenerIds() {
        if (this.data.opcion === 3) {
            // Caso: Array de cuentas
            this.idsClientes = this.obtenerIdsClientesUnicos(this.data.accounts);
            this.idsProductos = this.obtenerIdsProductosUnicos(this.data.accounts);
        } else if (this.data.opcion === 1 || this.data.opcion === 2) {
            // Caso: Objeto único
            this.idsClientes = this.obtenerIdsClientesUnicos([this.account]); // Envuelve en un array
            this.idsProductos = this.obtenerIdsProductosUnicos([this.account]); // Envuelve en un array
        }

        //this.probarAgenteFilterSearch();
    }


    probarAgenteFilterSearch() {
        this.agenteFilterSearch('alex').subscribe((result) => {
          console.log('Resultado:', result);
        });
    }

    obtenerIdsClientesUnicos(data: Account[]): string {
        const idsUnicos = new Set(
            data.map(item => item.idCliente).filter(idCliente => idCliente != null) // Filtra valores válidos
        );
        return Array.from(idsUnicos).join(',');
    }

    obtenerIdsProductosUnicos(data: Account[]): string {
        const idsUnicos = new Set(
            data.map(item => item.product?.id).filter(productId => productId != null) // Filtra valores válidos
        );
        return Array.from(idsUnicos).join(',');
    }

    mostrarMensaje() {
        if(this.data.opcion !=3 && (this.data.domiciliosSeleccionados.length == 1 || this.data.domicilios.length == 1 || this.formAsignacion.value.idDomicilio)) {
            if(this.formAsignacion.value.agente) {
                this.distanciaCuenta = this.formAsignacion.value.agente.distanciaCuenta;

                if(this.distanciaCuenta){
                    this.mensajeDisctancia =  `Este agente puede estar ubicado en un rango de ${Utils.formatearNumero(this.distanciaCuenta)}km de la cuenta.`
                }else {
                    this.mensajeDisctancia = null;
                }
            }else {
                this.mensajeDisctancia = null;
            }
        }
    }

    private obtenerDiasVencimiento(): void {
        const RED_INTERNA = "Red Interna";
        const { opcion, account, accounts, domicilios } = this.data;

        let idClientesSet = new Set<number>();
        let cuentasInternasSet = new Set<string>();
        let idClientesNoInternas = new Set<number>();
        let mensajesAviso: string = '';

        if (opcion === 1 || (opcion === 2 && account)) {
            idClientesSet.add(account.idCliente);

            if (domicilios && domicilios[0].idTipoVisibilidad === RED_INTERNA) {
                cuentasInternasSet.add(domicilios.folioDomicilio);
            } else {
                idClientesNoInternas.add(account.idCliente);
            }
        } else if (opcion === 3 && accounts) {
            accounts.forEach(account => {
                idClientesSet.add(account.idCliente);
                if (account.visibility === RED_INTERNA) {
                    cuentasInternasSet.add(account.client.fullName);
                } else {
                    idClientesNoInternas.add(account.idCliente);
                }
            });
        }

        this.idClientes = Array.from(idClientesSet);

        // Procesar cuentas internas
        if (cuentasInternasSet.size > 0) {
            const cuentasList = Array.from(cuentasInternasSet).join(' | ');
            const mensaje = cuentasInternasSet.size === 1
                ? `La cuenta <b>${cuentasList}</b> es interna, por lo que esta cuenta no tendrá fecha de vencimiento. <br>`
                : `Las cuentas  <b>| ${cuentasList} |</b>  son internas, por lo que estas cuentas no tendrán fecha de vencimiento. <br>`;
            mensajesAviso += mensaje;
        }

        // Si hay cuentas no internas, obtener configuración de cliente
        if (idClientesNoInternas.size > 0) {

            // Mapeo para almacenar la cantidad de cuentas no internas por cliente
            const cuentasNoInternasPorCliente: { [idCliente: number]: number } = {};

            const accountArray = Array.isArray(accounts) ? accounts : [account];

            accountArray.forEach(account => {
                if (idClientesNoInternas.has(account.idCliente) && account.visibility !== RED_INTERNA) {
                    cuentasNoInternasPorCliente[account.idCliente] =
                        (cuentasNoInternasPorCliente[account.idCliente] || 0) + 1;
                }
            });

            this.lenderService.obtenerConfigCliente(Array.from(idClientesNoInternas)).subscribe(
                response => {
                    if (response.success && response.data) {
                        response.data.forEach(cliente => {
                            const fechaLimite = this.calculateFechaLimite(cliente.diasVencimiento);
                            const numCuentasNoInternas = cuentasNoInternasPorCliente[cliente.idCliente] || 0;

                            const mensaje = numCuentasNoInternas === 1
                                ? `La cuenta de ${cliente.nombreCliente} podrá ser gestionada en máximo ${cliente.diasVencimiento} días. Su fecha máxima para ser visitada es ${fechaLimite}.<br>`
                                : `Las cuentas de ${cliente.nombreCliente} podrán ser gestionadas en máximo ${cliente.diasVencimiento} días. Su fecha máxima para ser visitada es ${fechaLimite}.<br>`;

                            mensajesAviso += mensaje;
                        });

                        this.mensajeAviso = [mensajesAviso];  // Asignar el mensaje concatenado
                    }
                },
                error => console.error('Error obtenerConfigCliente', error)
            );
        } else {
            this.mensajeAviso = [mensajesAviso];  // Asignar el mensaje concatenado si solo hay cuentas internas
        }
    }


    private actualizarMensajesConFechaLimite(selectedDate: any): void {
        this.mensajeAviso = [];
        var cuenta = 'Esta cuenta podrá';

        // Asumiendo que cuentas se refiere a this.data.accounts
        const numCuentas = this.data.accounts ? this.data.accounts.length : 0;

        console.log('Número de cuentas:', numCuentas); // Para depuración

        if (numCuentas > 1) {
            cuenta = 'Estas cuentas podrán';
        }

        const fechaLimite = moment(selectedDate).startOf('day');
        const hoy = moment().startOf('day');

        const fechaLimiteFormatted = fechaLimite.format('DD/MM/YYYY');
        const diasRestantes = fechaLimite.diff(hoy, 'days') + 1;
        const mensajeNuevo = `${cuenta} ser gestionada en máximo ${diasRestantes} días. Su fecha máxima para ser visitada es ${fechaLimiteFormatted}.`;

        this.mensajeAviso.push(mensajeNuevo);
    }


    private calculateFechaLimite(diasVencimiento: string): string {
        const fechaLimite = moment().add(parseInt(diasVencimiento, 10), 'days');
        return fechaLimite.format('DD/MM/YYYY');
    }

    private createForms(): void {
        this.formAsignacion = this.formBuilder.group({
            agente: [null, []],
            idCobradorAsignado: [null, []],
            horarioInicio_1: ["", []],
            horarioFin_1: ["", []],
            horarioInicio_2: ["", []],
            horarioFin_2: ["", []],
            fechaVisita: [null, []],
            fechaLimite: [null, []],
            idDomicilio: [null, []],
            comentario: ["", []]
        });

        this.momentVista = moment;
        this.momentFecha = moment;

    }

    resetAsignarAgenteFilter() {
        this.formAsignacion.patchValue({
            agente: null
        });
    }

    limpiarDomicilio() {
        this.formAsignacion.value.idDomicilio = null;
        this.formAsignacion.reset("idDomicilio");
    }

    public inicializarAgente() {
        if (this.data.opcion == 2) {
            var domicilioVisita = this.data.domicilios.filter((item) => item.idDomicilioAcreditado == this.data.idDomicilioAcreditado);

            if (domicilioVisita.length > 0) {
                this.formAsignacion.get("idDomicilio").patchValue(this.data.idDomicilioAcreditado);
            } else {
                if (this.data.domicilios.length == 1) {
                    this.formAsignacion.get("idDomicilio").patchValue(this.data.domicilios[0].idDomicilioAcreditado);
                }
            }

            this.idAgenteRevisita = this.data.idAgenteRevisita;
            this.agenteFilterSearch(String(this.data.idCobrador)).subscribe((resultados) => {
                this.formAsignacion.get('agente').setValue(resultados[0]);
                this.mostrarMensaje();
            });

        }
    }

    public agenteFilterSearch = function (term: string) {
        const getCoordinates = (): { lat: number | null, long: number | null } => {
            let lat = null;
            let long = null;

            if (this.formAsignacion.value.idDomicilio) {
                const domicilio = this.data.domicilios.find(
                    item => item.idDomicilioAcreditado === this.formAsignacion.value.idDomicilio
                );

                if (domicilio?.latitud && domicilio?.longuitud) {
                    lat = parseFloat(domicilio.latitud);
                    long = parseFloat(domicilio.longuitud);
                }
            } else if (this.data.domiciliosSeleccionados.length === 1) {
                const selectedDomicilio = this.data.domiciliosSeleccionados[0];
                if (selectedDomicilio.latitud && selectedDomicilio.longuitud) {
                    lat = parseFloat(selectedDomicilio.latitud);
                    long = parseFloat(selectedDomicilio.longuitud);
                }
            }

            return { lat, long };
        };

        const mapResponse = (res: any) =>
            res.data.map(({ idCobrador, nombreCompleto, idAgente, interno, distanciaCuenta, disponible }) => ({
                idCobrador,
                nombre: `${idCobrador} - ${nombreCompleto} ${!disponible ? '(No disponible)' : ''}`,
                idAgente,
                interno,
                distanciaCuenta,
            }));

        const shouldIncludeCoordinates =
            this.data.opcion !== 3 &&
            (this.data.domiciliosSeleccionados.length === 1 ||
                this.data.domicilios.length === 1 ||
                this.formAsignacion.value.idDomicilio);

        const request$ = shouldIncludeCoordinates
            ? this.agenteServ.obtenerAgentesConDistanciaDeCuenta(
                term,
                true,
                getCoordinates().lat,
                getCoordinates().long,
                this.idsClientes,
                this.idsProductos
            )
            : this.agenteServ.obtenerAgentesConDistanciaDeCuenta(term, false, null, null, this.idsClientes, this.idsProductos);

        return request$.pipe(
            take(1),
            map(mapResponse),
            catchError((error) => {
                // Manejo del error
                if (error.error?.message) {
                    this.app.showSnackbar(
                        "Error",
                        error.error.message,
                        3000,
                        "error"
                    );
                } else {
                    this.app.showSnackbar(
                        "Error",
                        error,
                        3000,
                        "error"
                    );
                }
                return of([]); // Retorna un array vacío para no romper el flujo del observable
            })
        );
    }.bind(this);


    // public agenteFilterSearch = function (term: string) {
    //     if(this.data.opcion !=3 &&
    //         (this.data.domiciliosSeleccionados.length == 1
    //         || this.data.domicilios.length == 1
    //         || this.formAsignacion.value.idDomicilio)
    //     ) {
    //         let lat = null;
    //         let long = null;
    //         let domicilio = null;

    //         if(this.formAsignacion.value.idDomicilio) {
    //             domicilio = this.data.domicilios.filter((item) => item.idDomicilioAcreditado == this.formAsignacion.value.idDomicilio);
    //             if(domicilio[0].latitud && domicilio[0].longuitud) {
    //                 lat = parseFloat(domicilio[0].latitud);
    //                 long = parseFloat(domicilio[0].longuitud);
    //             }

    //         }else if( this.data.domiciliosSeleccionados.length == 1 ) {
    //             if(this.data.domiciliosSeleccionados[0].latitud && this.data.domiciliosSeleccionados[0].longuitud) {
    //                 lat = parseFloat(this.data.domiciliosSeleccionados[0].latitud);
    //                 long = parseFloat(this.data.domiciliosSeleccionados[0].longuitud);
    //             }
    //         }

    //         return this.agenteServ.obtenerAgentesConDistanciaDeCuenta(term, true, lat, long)
    //         .pipe(
    //             take(1),
    //             map((res: any) => res.data.map(
    //                 ({ idCobrador, nombreCompleto, idAgente, interno, distanciaCuenta }) => (
    //                     { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente, interno:interno, distanciaCuenta:distanciaCuenta }
    //                 )
    //             ))
    //         );
    //     } else {
    //         return this.agenteServ.obtenerAgentesConDistanciaDeCuenta(term, false, null, null)
    //         .pipe(
    //             take(1),
    //             map((res: any) => res.data.map(
    //                 ({ idCobrador, nombreCompleto, idAgente, interno, distanciaCuenta }) => (
    //                     { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente, interno:interno, distanciaCuenta:distanciaCuenta }
    //                 )
    //             ))
    //         );
    //     }

    // }.bind(this);

    public establecerHorarioVisita(visita: boolean): void {
        this.establecerVisita = visita;

        if (visita) {
            const fechaLimite = this.formAsignacion.value.fechaLimite;
            this.formAsignacion.patchValue({
                horarioInicio_1: null,
                horarioFin_1: null,
                horarioInicio_2: null,
                horarioFin_2: null,
                fechaVisita: fechaLimite,  // Asignar fechaLimite a fechaVisita
            });
        }
        this.obtenerDiasVencimiento()


    }


    public showConfirmaModal(): void {

        const dialogRef = this.dialog.open(ConfirmacionDialog, {
            data: {
                titulo: "Confirmar",
                mensaje: "¿Deseas que este horario quede definido para futuras visitas?",
                icono: "save",
                boton1: "No",
                boton2: "Sí",
                claseAccion: "boton-accion-guardar"
            }
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {
                if (this.data.opcion == 1) {
                    this.confirmaVisita(false);
                } else if (this.data.opcion == 2) {
                    this.confirmaRevisita(false);
                } else {
                    this.confirmaVisitaMultiple(false);
                }
            } else {
                if (this.data.opcion == 1) {
                    this.confirmaVisita(true);
                } else if (this.data.opcion == 2) {
                    this.confirmaRevisita(true);
                } else {
                    this.confirmaVisitaMultiple(true);
                }
            }
        });
    }

    public closeModals(): void {
        this.clear();
        this.establecerVisita = true;
        this.establecerFecha = true;
        this.domicilioAsignarWarning = false;
        this.itemsDomcilio = true;

    }

    public clear(): void {
        this.formAsignacion.patchValue({
            idCobradorAsignado: null,
            horarioInicio_1: null,
            horarioFin_1: null,
            horarioInicio_2: null,
            horarioFin_2: null,
            fechaVisita: null,
            fechaLimite: null,
            comentario: "",
            idDomicilio: null
        });
    }

    public limpiarHorariosYfechaLimite() {
        this.horariosVisitas = {};
        this.fechaLimite = null;
        this.fechaVisita = null;
        this.idDomicilioAsignar = null;
        this.formAsignacion.patchValue({
            fechaLimite: null
        });
    }

    onAsignarAgenteFilterSelected() {
        //this.asignarAgenteFilterFlag = true;
        if (!this.formAsignacion.value.agente) {
            this.app.showSnackbar(
                "Aviso",
                "Es necesario elegir un gestor",
                3000,
                "warning"
            );
            return;
        }

        this.agente = this.formAsignacion.value.agente;
        this.formAsignacion.get("idCobradorAsignado").patchValue(this.agente.idCobrador);
        this.idAgenteRevisita = this.agente.idAgente;
        //this.mostrarScroll = false;
    }

    public asignacion(): void {
        this.onAsignarAgenteFilterSelected();
        this.asignacionConHorario();
    }

    public asignacionConHorario(): void {
        let horario_1 = {};
        let horario_2 = {};
        let horaInicio_1 = this.formAsignacion.value.horarioInicio_1
            ? this.formAsignacion.value.horarioInicio_1
            : "";
        let horaFin_1 = this.formAsignacion.value.horarioFin_1
            ? this.formAsignacion.value.horarioFin_1
            : "";

        let horaInicio_2 = this.formAsignacion.value.horarioInicio_2
            ? this.formAsignacion.value.horarioInicio_2
            : "";
        let horaFin_2 = this.formAsignacion.value.horarioFin_2
            ? this.formAsignacion.value.horarioFin_2
            : "";
        let validaHoras = true;
        let validaHorario_1 = false;
        let validaHorario_2 = false;
        let validaFecha = false;

        if (!!horaInicio_1 || !!horaFin_1) {
            validaHorario_1 = true;
        }

        if (!!horaInicio_2 || !!horaFin_2) {
            validaHorario_2 = true;
        }

        if (!this.formAsignacion.value.idCobradorAsignado) {
          this.app.showSnackbar(
              "Aviso",
              "Es necesario elegir un gestor",
              3000,
              "warning"
          );
          return;
        }

        if (this.data.opcion == 2) {

            if (!this.formAsignacion.value.comentario) {
                this.app.showSnackbar(
                    "Aviso",
                    "Es necesario agregar un comentario al cobrador.",
                    3000,
                    "warning"
                );
                return;
            }

        }

        if (this.data.opcion != 3) {
            if (this.data.domiciliosSeleccionados.length === 0) {
                if (!this.formAsignacion.value.idDomicilio) {
                    this.domicilioAsignarWarning = true;
                    return;
                }
            }
        }

        if (this.formAsignacion.value.comentario) {
          // Eliminar espacios al inicio y final
          this.formAsignacion.patchValue({
              comentario: this.formAsignacion.value.comentario.trim()
          });

          // Validar longitud después de limpiar espacios
          if (this.formAsignacion.value.comentario.length < 30) {
              this.app.showSnackbar('Aviso', 'El comentario debe tener al menos 30 caracteres.', 300, 'warning');
              return;
          }
        }


        this.fechaVisita = this.formAsignacion.value.fechaVisita ? this.formAsignacion.value.fechaVisita : '';
        this.fechaLimite = this.formAsignacion.value.fechaLimite ? this.formAsignacion.value.fechaLimite : null;

        if (this.fechaLimite != null) {
            this.fechaLimite = this.formAsignacion.value.fechaLimite
                .toISOString()
                .split("T")[0];
        }

        if (!!this.fechaVisita) {
            validaFecha = true;
            this.fechaVisita = this.formAsignacion.value.fechaVisita
                .toISOString()
                .split("T")[0];
        }

        if (validaHorario_1 || validaHorario_2) {
            if (!this.validaModal(horaInicio_1, horaFin_1, horaInicio_2, horaFin_2)) {
                validaHoras = this.validaHoras(validaHorario_1, validaHorario_2);

                if (!validaHoras) {
                    if (validaHorario_1) {
                        let val_horaInico_1 = this.momentVista(this.formAsignacion.value.horarioInicio_1, ["h:mm A"]).format("HH:mm");
                        let val_horaFin_1 = this.momentVista(this.formAsignacion.value.horarioFin_1, ["h:mm A"]).format("HH:mm");
                        let arrayHoraInicio = val_horaInico_1.split(':');
                        let arrayHoraFIn = val_horaFin_1.split(':');

                        horario_1 = {
                            revisitaHorarioInicio:
                                arrayHoraInicio[0] + ":" + arrayHoraInicio[1]
                            ,
                            revisitaHorarioFin:
                                arrayHoraFIn[0] + ":" + arrayHoraFIn[1]
                        };
                    }

                    if (validaHorario_2) {
                        let val_horaInicio = this.momentVista(this.formAsignacion.value.horarioInicio_2, ["h:mm A"]).format("HH:mm");
                        let val_horaFin = this.momentVista(this.formAsignacion.value.horarioFin_2, ["h:mm A"]).format("HH:mm");
                        let arrayHoraInicio_2 = val_horaInicio.split(':');
                        let arrayHoraFin_2 = val_horaFin.split(':');

                        horario_2 = {
                            revisitaHorarioInicio:
                                arrayHoraInicio_2[0] + ":" + arrayHoraInicio_2[1]
                            ,
                            revisitaHorarioFin:
                                arrayHoraFin_2[0] + ":" + arrayHoraFin_2[1]
                        };
                    }

                    if (Object.keys(horario_1).length > 0 && Object.keys(horario_2).length > 0) {
                        this.horariosVisitas = [horario_1, horario_2];
                    } else {
                        if (Object.keys(horario_1).length > 0) {
                            this.horariosVisitas = [horario_1];
                        }

                        if (Object.keys(horario_2).length > 0) {
                            this.horariosVisitas = [horario_2];
                        }
                    }

                    this.showConfirmaModal();
                }
            }
        } else {
            if (this.data.opcion == 1) {
                this.confirmaVisita(false);
            } else if (this.data.opcion == 2) {
                this.confirmaRevisita(false);
            } else {
                this.confirmaVisitaMultiple(false);
            }

        }
    }

    public validaModal(horaInicio_1: string, horaFin_1: string, horaInicio_2: string, horaFin_2: string): boolean {
        let respuesta = false;

        if (!!horaInicio_1 || !!horaFin_1 || !!horaInicio_2 || !!horaFin_2) {
            if (horaInicio_1 == '') {
                this.app.showSnackbar(
                    "Aviso",
                    "Es necesario elegir una hora inicio",
                    3000,
                    "warning"
                );
                respuesta = true;
            } else if (horaFin_1 == '') {
                this.app.showSnackbar(
                    "Aviso",
                    "Es necesario elegir una hora fin",
                    3000,
                    "warning"
                );
                respuesta = true;
            } else if (!!horaInicio_1 && !!horaFin_1) {
                if (horaInicio_2 == '' && horaFin_2 != '') {
                    this.app.showSnackbar(
                        "Aviso",
                        "Es necesario elegir una hora inicio",
                        3000,
                        "warning"
                    );
                    respuesta = true;
                } else if (horaInicio_2 != '' && horaFin_2 == '') {
                    this.app.showSnackbar(
                        "Aviso",
                        "Es necesario elegir una hora fin",
                        3000,
                        "warning"
                    );
                    respuesta = true;
                }
            }
        }

        return respuesta;
    }

    public validaHoras(validaHorario_1: boolean, validaHorario_2: boolean): boolean {
        let respuesta = false;

        if (validaHorario_1) {
            let horarioInicio_1 = this.momentVista(this.formAsignacion.value.horarioInicio_1, ["h:mm A"]).format("HH:mm");
            let horarioFin_1 = this.momentVista(this.formAsignacion.value.horarioFin_1, ["h:mm A"]).format("HH:mm");
            let arrayHoraInicio = horarioInicio_1.split(':');
            let arrayHoraFIn = horarioFin_1.split(':');
            let primerHorarioInicio = arrayHoraInicio[0] + arrayHoraInicio[1];
            let primerHorarioFIn = arrayHoraFIn[0] + arrayHoraFIn[1];
            let Param_1 = parseInt(arrayHoraInicio[0]);//horas
            let Param_2 = parseInt(arrayHoraFIn[0]);
            let Param_3 = parseInt(arrayHoraInicio[1]);//minutos
            let Param_4 = parseInt(arrayHoraFIn[1]);

            if (this.validaHorarios(Param_1, Param_2, Param_3, Param_4)) {
                respuesta = true;
            } else if (parseInt(primerHorarioInicio) >= parseInt(primerHorarioFIn)) {
                this.app.showSnackbar(
                    "Aviso",
                    "Favor de seleccionar una hora inicio menor a la hora fin",
                    3000,
                    "warning"
                );
                respuesta = true;
                //return;
            }
        }

        if (validaHorario_2) {
            let horarioInicio_2 = this.momentVista(this.formAsignacion.value.horarioInicio_2, ["h:mm A"]).format("HH:mm");
            let horarioFin_2 = this.momentVista(this.formAsignacion.value.horarioFin_2, ["h:mm A"]).format("HH:mm");
            let arrayhoraInicio_2 = horarioInicio_2.split(':');
            let arrayhoraFin_2 = horarioFin_2.split(':');
            let segundoHorarioInicio = arrayhoraInicio_2[0] + arrayhoraInicio_2[1];
            let segundoHorarioFin = arrayhoraFin_2[0] + arrayhoraFin_2[1];

            let Param_1 = parseInt(arrayhoraInicio_2[0]);//horas
            let Param_2 = parseInt(arrayhoraFin_2[0]);
            let Param_3 = parseInt(arrayhoraInicio_2[1]);//minutos
            let Param_4 = parseInt(arrayhoraFin_2[1]);

            if (this.validaHorarios(Param_1, Param_2, Param_3, Param_4)) {
                respuesta = true;
            } else if (parseInt(segundoHorarioInicio) >= parseInt(segundoHorarioFin)) {
                this.app.showSnackbar(
                    "Aviso",
                    "Favor de seleccionar una hora inicio menor a la hora fin",
                    3000,
                    "warning"
                );
                respuesta = true;
                //return;
            }
        }
        return respuesta;
    }

    public validaHorarios(horaInicio, horaFin, minutosInicio, minutosFin): boolean {
        let respuesta = false;

        if (
            horaInicio <= 6 &&
            minutosInicio <= 59) {
            this.app.showSnackbar(
                "Aviso",
                "Las visitas sólo se pueden realizar en un horario después de las 7AM",
                3000,
                "warning"
            );
            respuesta = true;
        }
        if (
            horaFin >= 22 &&
            minutosFin > 0
        ) {
            this.app.showSnackbar(
                "Aviso",
                "Las visitas sólo se pueden realizar en un horario antes a las 10 PM",
                3000,
                "warning"
            );
            respuesta = true;
        }
        return respuesta;
    }

    private reloadAccountList(response: any): void {

        if (response.isAsignacion) {
            this.app.showSnackbar(
                "Cuentas asignadas",
                `${response.message} \nEn espera de confirmación por parte del agente.`,
                5000,
                "success"
            );
            this.dialogRef.close(true);
        } else {
            this.app.showSnackbar(
                "Cuentas no asignadas",
                `${response.message}`,
                5000,
                "warning"
            );
            this.dialogRef.close(false);
        }

    }

    public onChangeDomicilioAsignar() {
        let idCobrador = null;
        if (!this.formAsignacion.value.idDomicilio) {
            this.domicilioAsignarWarning = true;
        } else {
            this.domicilioAsignarWarning = false;
        }

        if(this.data.idCobrador) {
            idCobrador = this.data.idCobrador;
        }else {
            idCobrador = this.formAsignacion.value.agente.idCobrador;
        }

        this.agenteFilterSearch(String(idCobrador)).subscribe((resultados) => {
            this.formAsignacion.get('agente').setValue(resultados[0]);
            this.mostrarMensaje();
        });
    }

    public limpiarFecha(): void {
        this.formAsignacion.patchValue({
            fechaVisita: null
        });
    }

    public limpiarHorario(): void {
        this.formAsignacion.patchValue({
            horarioInicio_2: null,
            horarioFin_2: null
        });
    }

    public limpiarHorarioInicio(): void {
        this.formAsignacion.patchValue({
            horarioInicio_1: null,
            horarioFin_1: null
        });
    }

    public confirmaVisita(respuesta: boolean): void {
        this.asignada = true;
        let horarioAgenteConfirmado = respuesta;
        let comentario = this.formAsignacion.value.comentario ? this.formAsignacion.value.comentario : null;
        this.idCobrador = +this.formAsignacion.value.idCobradorAsignado;
        let loading: number = this.app.showLoading(
            "Asignando cuenta al agente..."
        );
        let arrayDomicilios: any = [];

        if (this.data.domiciliosSeleccionados.length > 0) {
            this.data.domiciliosSeleccionados.forEach(item => {
                //alex
                arrayDomicilios.push({ idDomicilioAcreditado: item.idDomicilioAcreditado });
            });
        } else {
            arrayDomicilios.push({ idDomicilioAcreditado: this.formAsignacion.value.idDomicilio });
        }

        let subscription: Subscription = this.accountService
            //alex
            .asignarAgente(
                this.account.id,
                this.idCobrador,
                comentario,
                this.horariosVisitas,
                this.fechaVisita,
                horarioAgenteConfirmado,
                this.fechaLimite,
                this.account.idCliente,
                this.account.idAcreditadoPG,
                this.account.idCuentaPG,
                arrayDomicilios
            )
            .subscribe(
                (response) => {
                    this.app.hideLoading(loading);
                    this.limpiarHorariosYfechaLimite();
                    this.closeModals();
                    this.asignada = false;
                    if (!response.success) {
                        this.assignMessage = response.message;
                        this.dialogRef.close(false);
                    } else {
                        this.reloadAccountList(response);
                    }

                },
                (error) => {
                    this.limpiarHorariosYfechaLimite();
                    this.app.showError(error);
                    this.app.hideLoading(loading);
                    subscription.unsubscribe();
                    this.asignada = false;
                },
                () => {
                    this.app.hideLoading(loading);
                    subscription.unsubscribe();
                    this.asignada = false;
                }
            );
    }

    public confirmaRevisita(respuesta: boolean): void {
        this.asignada = true;
        let horarioAgenteConfirmado = respuesta;
        let comentario = this.formAsignacion.value.comentario ? this.formAsignacion.value.comentario : null;
        this.idCobrador = +this.formAsignacion.value.idCobradorAsignado;
        let arrayDomicilios: any = [];

        if (this.data.domiciliosSeleccionados.length > 0) {
            this.data.domiciliosSeleccionados.forEach(item => {
                //alex
                arrayDomicilios.push({ idDomicilioAcreditado: item.idDomicilioAcreditado });
            });
        } else {
            arrayDomicilios.push({ idDomicilioAcreditado: this.formAsignacion.value.idDomicilio });
        }

        let loading: number = this.app.showLoading(
            "Guardando revisita..."
        );
        let subscription: Subscription = this.accountService
            .revisita(this.account.lenderID,
                this.account.idCuenta,
                this.data.folioVisita,
                this.idCobrador,
                this.account.id,
                this.data.nombreAcreditado,
                comentario,
                this.data.idAgente,
                this.data.idVisitaPsg, // idviditapsg
                this.horariosVisitas,
                this.fechaVisita,
                horarioAgenteConfirmado,
                this.idAgenteRevisita,
                this.account.idCliente,
                this.account.idAcreditadoPG,
                this.account.idCuentaPG,
                arrayDomicilios
            )//alex
            .subscribe(
                (response) => {
                    this.app.hideLoading(loading);
                    this.asignada = false;
                    this.limpiarHorariosYfechaLimite();
                    this.closeModals();
                    if (!response.success) {
                        this.app.showSnackbar(
                            "Aviso",
                            "Ocurrio un error: " + response.error.message,
                            5000,
                            "error"
                        );
                        this.dialogRef.close(false);
                    } else {
                        this.mensaje(response);
                    }
                },
                (error) => {
                    this.app.showError(error);
                    this.app.hideLoading(loading);
                    subscription.unsubscribe();
                    this.clear();
                    this.limpiarHorariosYfechaLimite();
                    this.asignada = false;
                },
                () => {
                    this.app.hideLoading(loading);
                    subscription.unsubscribe();
                    this.asignada = false;
                    this.limpiarHorariosYfechaLimite();
                }
            );
    }

    public confirmaVisitaMultiple(respuesta: boolean): void {
        this.asignada = true;
        let horarioAgenteConfirmado = respuesta;
        let comentario = this.formAsignacion.value.comentario ? this.formAsignacion.value.comentario : null;
        this.idCobrador = +this.formAsignacion.value.idCobradorAsignado;
        let loading: number = this.app.showLoading(
            "Asignando cuenta al agente..."
        );

        let subscription: Subscription = this.accountService
            .assignAgent(
                this.data.accounts,
                this.idCobrador,
                comentario,
                this.horariosVisitas,
                this.fechaVisita,
                horarioAgenteConfirmado,
                this.fechaLimite,
            )
            .subscribe(
                (response) => {
                    this.app.hideLoading(loading);
                    this.limpiarHorariosYfechaLimite();
                    this.closeModals();
                    this.asignada = false;
                    if (!response.success) {
                        this.assignMessage = response.message;
                        this.dialogRef.close(false);
                    } else {
                        this.reloadAccountList(response);
                        this.dialogRef.close(true);
                    }
                },
                (error) => {
                    this.app.showError(error);
                    this.app.hideLoading(loading);
                    subscription.unsubscribe();
                    this.limpiarHorariosYfechaLimite();
                    this.asignada = false;
                },
                () => {
                    this.app.hideLoading(loading);
                    subscription.unsubscribe();
                    this.asignada = false;
                }
            );
    }

    private mensaje(response: any): void {
        if (response.isAsignacion) {
            this.app.showSnackbar(
                "Cuenta asignada",
                `${response.message} \nEn espera de confirmación por parte del agente.`,
                5000,
                "success"
            );
            this.dialogRef.close(true);
        } else {
            this.app.showSnackbar(
                "Cuenta no asignada",
                `${response.message}`,
                5000,
                "warning"
            );
            this.dialogRef.close(false);
        }

    }

    getHintLabel(): string {
      if (this.data.opcion === 2 || this.formAsignacion.value.comentario) {
        return 'Mínimo 30 caracteres';
      }
      return '';
    }s

}
