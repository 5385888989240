import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Pagination } from '../pagination/pagination.model';
import { VisitaCalificadaPaginator } from '../visit/visita-calificada.paginator';
import { VisitaCalificada } from '../visit/visita-calificada.model';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CalificadasVisitasServices {

  public loader: boolean;
  public loaderTxt: string;
  public filtrosForm: FormGroup;

  constructor(
    private serviciosVisitas: ServicesVisitasService,
    private fb: FormBuilder

  ) {
    this.filtrosForm = this.fb.group(
      {
        fechaInicio: ['', Validators.required],
        fechaFin: ['', Validators.required],
        folioVisita: ['', Validators.required],
        idFinanciera: ["", Validators.required]
      }
    );
   }

  callLoader(show: boolean, txt?: string) {
    this.loader = show;
    this.loaderTxt = txt;
  }

  getVisitasCalificadas(pagination: Pagination, extraParams: string, rows: number): Observable<VisitaCalificadaPaginator> {
    let start = 0;

    if (pagination.nextPage > 1) {
      start = ((pagination.nextPage - 1) * rows) + 1;
    }

    const query = `/visitas-calificadas?start=${start}${extraParams}`;

    return this.serviciosVisitas.get(`${query}`).pipe(
      map(res => {
        const response: any = res.data;
        const visitaCalificadaPaginator: VisitaCalificadaPaginator = new VisitaCalificadaPaginator();
        visitaCalificadaPaginator.pagination = new Pagination();
        visitaCalificadaPaginator.pagination.currentPage = start ? Math.ceil(start / rows) : 1;
        visitaCalificadaPaginator.pagination.pageCount = Math.ceil(response.totalRows / rows);
        visitaCalificadaPaginator.pagination.perPage = rows;
        visitaCalificadaPaginator.pagination.totalCount = response.totalRows;
        visitaCalificadaPaginator.data = [];

        for (let i = 0; i < response.pageData.length; i++) {
          const visitaCalificada: VisitaCalificada = new VisitaCalificada();

          visitaCalificada.nombreCliente = response.pageData[i].nombreCliente;
          visitaCalificada.folioVisita = response.pageData[i].folioVisita;
          visitaCalificada.fechaCalificacion = response.pageData[i].fechaCalificacion;
          visitaCalificada.nombreCompletoAgenteVisita = response.pageData[i].nombreCompletoAgenteVisita;
          visitaCalificada.calificacion = response.pageData[i].calificacion;
          visitaCalificada.comentario = response.pageData[i].comentario;
          visitaCalificada.nombreAgenteCalifica = response.pageData[i].nombreAgenteCalifica;
          visitaCalificada.nombreCompletoFinancieraCalificador =
            (response.pageData[i].nombreCompletoFinancieraCalificador || '') + ' ' +
            (response.pageData[i].apellidoPaternoFinancieraCalificador || '') + ' ' +
            (response.pageData[i].apellidoMaternoFinancieraCalificador || '');

          visitaCalificada.nombreCompletoKobraCalificador =
            (response.pageData[i].nombreCompletoKobraCalificador || '') + ' ' +
            (response.pageData[i].apellidoPaternoKobraCalificador || '') + ' ' +
            (response.pageData[i].apellidoMaternoKobraCalificador || '');

          visitaCalificadaPaginator.data.push(visitaCalificada);
        }

        return visitaCalificadaPaginator;
      })
    );
  }
}
