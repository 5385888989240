
<h1 mat-dialog-title>Asignar cuentas a agente</h1>
<mat-dialog-content class="modal">
  <div  >
    <form class="example-form" [formGroup]="formAsignacion">
      <ng-container *ngIf="mensajeAviso.length > 0">
        <alerta-informativa
            clase="info"
            titulo="¡Aviso!"
            html="{{mensajeAviso}}">
        </alerta-informativa>
      </ng-container>


      <div>
        <mat-select-search
          class="id-card-field"
          [hintLabel]="mensajeDisctancia"
          [label]="'Agente'"
          [placeHolder]="'Busca por id o nombre'"
          [value]="'option'"
          [labelField]="'nombre'"
          [icon]="'account_circle'"
          [realizarBusqueda]="agenteFilterSearch"
          formControlName="agente"
          [minBusqueda]="1"
          (ngModelChange)="mostrarMensaje()"
          >
        </mat-select-search>
      </div>

      <div *ngIf="data.opcion == 2" style="margin-top: 20px;">
        <mat-label ><b>Vuelve a visitar a:</b></mat-label>
        {{data.nombreAcreditado}}
      </div>

      <div *ngIf="data.domiciliosSeleccionados.length === 0 && data.opcion != 3">
        <mat-form-field  style="margin-top: 10px;" class="example-full-width" >
          <mat-label>Domicilio</mat-label>
          <mat-select formControlName="idDomicilio" (ngModelChange)="onChangeDomicilioAsignar()" >
            <mat-option *ngFor="let domicilio of data.domicilios" [value]="domicilio.idDomicilioAcreditado" >
              {{domicilio.selectDomicilio}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="domicilioAsignarWarning">
        <div class="ui pointing red basic label">
            Debes seleccionar un domicilio.
        </div>
      </div>

      <mat-form-field class="example-full-width" [hintLabel]="getHintLabel()">
        <mat-label>Comentario</mat-label>
        <textarea matInput textSanitizer  placeholder="Favor de escribir un comentario..." rows="2" formControlName="comentario" maxlength="255" ></textarea>
        <mat-hint align="end">{{ formAsignacion.value.comentario.length }} / 255</mat-hint>
      </mat-form-field>

      <div *ngIf="!establecerVisita">
        <div style="text-align: center; margin-bottom: 20px;">
            <mat-label align="center">Selecciona la fecha de la visita</mat-label>
        </div>
        <div >
          <mat-date-picker-formater
            [label]="'Fecha visita'"
            [min]="fechaAsignacionMinDate"
            [placeHolder]="'Capture la fecha'"
            formControlName="fechaVisita"
          />

        </div>
        <div style="margin-top: 10px; margin-bottom: 10px;">
          <mat-label>Primer horario</mat-label>
        </div>
        <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="70px">
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-form-field>
              <mat-label>De:</mat-label>
              <input matInput textSanitizer
                formControlName="horarioInicio_1"
                [ngxTimepicker]="horarioInicio_1"
                placeholder="De:">
                <mat-icon matSuffix>access_time</mat-icon>
              <ngx-material-timepicker #horarioInicio_1></ngx-material-timepicker>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-form-field>
              <mat-label>A:</mat-label>
              <input matInput textSanitizer
                formControlName="horarioFin_1"
                [ngxTimepicker]="horarioFin_1"
                placeholder="Selecciona una hora">
                <mat-icon matSuffix  class="icono-limpiar icono-flotante-hora"
                *ngIf="formAsignacion.get('horarioInicio_1').value"
                (click)="limpiarHorarioInicio()">clear</mat-icon>
                <mat-icon matSuffix>access_time</mat-icon>
              <ngx-material-timepicker #horarioFin_1></ngx-material-timepicker>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list-responsive>
        <!-- <div class="limpiar-seleccion-kobra" align="left" *ngIf="formAsignacion.get('horarioInicio_1').value">
          <a (click)="limpiarHorarioInicio()"><i>Limpiar selección</i></a>
        </div> -->
        <div style="margin-top: 10px; margin-bottom: 10px;">
            <mat-label>Segundo horario</mat-label>
        </div>
        <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="70px">
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-form-field>
              <mat-label>De:</mat-label>
              <input matInput textSanitizer
              formControlName="horarioInicio_2"
                [ngxTimepicker]="horarioInicio_2"
                placeholder="Selecciona una hora">
                <mat-icon matSuffix>access_time</mat-icon>
              <ngx-material-timepicker #horarioInicio_2></ngx-material-timepicker>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-form-field>
              <mat-label>A:</mat-label>
              <input matInput textSanitizer
                formControlName="horarioFin_2"
                [ngxTimepicker]="horarioFin_2"
                placeholder="Selecciona una hora">
                <mat-icon matSuffix  class="icono-limpiar icono-flotante-hora"
                *ngIf="formAsignacion.get('horarioInicio_2').value"
                (click)="limpiarHorario()">clear</mat-icon>
                <mat-icon matSuffix>access_time</mat-icon>
              <ngx-material-timepicker #horarioFin_2></ngx-material-timepicker>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list-responsive>
        <!-- <div class="limpiar-seleccion-kobra" align="left" *ngIf="formAsignacion.get('horarioInicio_2').value">
          <a (click)="limpiarHorario()"><i>Limpiar selección</i></a>
        </div> -->
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button  mat-flat-button class="boton-cancelar" (click)="onNoClick()" [disabled]="asignada"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button class="boton-accion-front" mat-stroked-button *ngIf="establecerVisita" (click)="establecerHorarioVisita(false)" [disabled]="asignada">
    <mat-icon >date_range</mat-icon>Especificar fecha de visita
  </button>
  <button  mat-stroked-button class="boton-accion-front" *ngIf="!establecerVisita" (click)="establecerHorarioVisita(true);" [disabled]="asignada">
    <mat-icon >date_range</mat-icon>Sin fecha de visita
  </button>
  <button mat-stroked-button class="boton-guardar" (click)="asignacion()" [disabled]="asignada"><mat-icon style="color:white !important">save</mat-icon>Asignar</button>
</mat-dialog-actions>
