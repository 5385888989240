<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; inactivarHijo();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros"
            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            matTooltip="Filtrar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
            <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>

          </mat-button-toggle>
          <mat-button-toggle value="descargas"
            [matMenuTriggerFor]="menuDescarga"
            matTooltip="Descargar listado"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">cloud_download</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            (click)="loadRequests(filtros.page || 1);"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <!-- <mat-button-toggle value="ayuda"
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle> -->
        </mat-button-toggle-group>
      </div>
    </div>

    <mat-menu #menuDescarga="matMenu">
      <button mat-menu-item
        *ngIf="!filtros.mostrarMapa"
        (click)="descargarListado(0)">
        <mat-icon class="kobra-icon">swap_vert</mat-icon>
        <span>Sin cuestionario</span>
      </button>
      <button mat-menu-item
        *ngIf="!filtros.mostrarMapa"
        (click)="descargarListado(1)">
        <mat-icon class="kobra-icon">swap_vert</mat-icon>
        <span>Con cuestionario (Vertical)</span>
      </button>
      <button mat-menu-item
        *ngIf="!filtros.mostrarMapa"
        (click)="descargarListado(2)">
        <mat-icon class="kobra-icon">swap_vert</mat-icon>
        <span>Con cuestionario (Horizontal)</span>
      </button>
    </mat-menu>

    <app-table
      #tablaRef
      [classContainer]="'tabla-completa'"
      [loading]="cargandoLista"
      [columns]="tablaSolicitudes.columns"
      [items]="requests"
      [headers]="tablaSolicitudes.headers"
      [multiSelect]="tablaSolicitudes.multiSelect"
      (sortChange)="setSortField($event)"
      (onLinkRow)="onClick($event)"
      (onCtrlClicLinkRow)="onCtrlClick($event)"
      >

      <div t-header>

      <mat-card *ngIf="ayuda" class="cardAyuda">

        <mat-card-content>
          <p><mat-icon color="primary">help</mat-icon>
            El módulo "Información Recopilada" muestra un listado histórico que recoge todos los correos recopilados
            por los agentes en cada una de sus visitas, proporcionando un registro detallado y organizado.Los
            usuarios pueden aplicar filtros basados en criterios específicos como la cuenta del cliente o la fecha
            en que se recopiló la información
          </p>
          Acciones:
          <p>
            <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda de las
            información a mostrar.
          </p>
          <p>
            <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los
            criterios de filtros aplicados.
          <p>
            <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar: </b> Ofrece la opción de descargar
            la información previamente filtrada para su análisis o uso fuera de línea.
          </p>
        </mat-card-content>
      </mat-card>
      </div>

      <ng-container t-paginator>
        <mat-paginator
          showFirstLastButtons
          [length]="requestPaginator.pagination?.totalCount"
          [pageIndex]="requestPaginator.pagination?.currentPage-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button>
    Filtros
  </mat-toolbar>
  <div class="accordion-filtros">
    <mat-accordion [multi]="false">
      <mat-expansion-panel *ngIf="sessionData.isSuperAdmin" [expanded]="true">
        <mat-expansion-panel-header>
          Súper Administrador
          <span *ngIf="this.contabilizadorDeFiltros('superAdmin') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('superAdmin') }}</span>

        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="'Cliente'"
              [placeHolder]="'Selecciona un cliente'"
              [value]="'id'"
              [labelField]="'name'"
              [icon]="'account_balance'"
              [options]="lenders"
              [(ngModel)]="filtros.lender"
              (ngModelChange)="loadEstatusFinancieras();"
            >
            </mat-select-search>

          </li>
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Excluir Demo</mat-label>
              <mat-select [(ngModel)]="filtros.excludeFinancierasDemo" (selectionChange)="this.contabilizadorDeFiltros('superAdmin');">
                <mat-option [value]="true">Sí</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="!sessionData.isSuperAdmin">
        <mat-expansion-panel-header>
          Generales
          <span *ngIf="contabilizadorDeFiltros('generales') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('generales') }}</span>

        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Folio Solicitud</mat-label>
              <input matInput textSanitizer
              [(ngModel)]="filtros.requestID"
              (ngModelChange)="this.contabilizadorDeFiltros('generales');"
              placeholder="Capture el folio de la solicitud">
              <mat-icon matSuffix  class="icono-limpiar"
              *ngIf="filtros.requestID"
              (click)="filtros.requestID = null">clear</mat-icon>
              <mat-icon matSuffix>description</mat-icon>
            </mat-form-field>
          </li>
          <li class="filtro-side" *ngIf="!isSolicitudGeneral">
              <mat-form-field  style="width: 100%;">
                <mat-label>Estatus</mat-label>
                <mat-select  [(ngModel)]="filtros.status"  (ngModelChange)="this.contabilizadorDeFiltros('generales');">
                  <mat-option *ngFor="let stat of estatus" [value]="stat.idEstatus">
                      {{stat.nombre}}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix  class="icono-limpiar-solo"
                *ngIf="filtros.status"
                (click)="filtros.status = null">clear</mat-icon>
              </mat-form-field>
            </li>
            <li class="filtro-side" *ngIf="!isSolicitudGeneral">
              <mat-form-field  style="width: 100%;">
                <mat-label>Estatus Cliente</mat-label>
                <mat-select  [(ngModel)]="filtros.statusClient"  (ngModelChange)="this.contabilizadorDeFiltros('generales');">
                  <mat-option *ngFor="let statC of estatusFinancieras" [value]="statC.idEstatus">
                      {{statC.nomEstatus}}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix  class="icono-limpiar-solo"
                *ngIf="filtros.statusClient"
                (click)="filtros.statusClient = null">clear</mat-icon>
              </mat-form-field>
            </li>
            <li class="filtro-side">
              <mat-form-field  style="width: 100%;">
                <mat-label>Tipo de solicitud</mat-label>
                <mat-select  [(ngModel)]="filtros.idTipoCredito"  (ngModelChange)="this.contabilizadorDeFiltros('generales');">
                  <mat-option *ngFor="let tipo of tiposCredito" [value]="tipo.idTipoCredito">
                      {{tipo.nombre}}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix  class="icono-limpiar-solo"
                *ngIf="filtros.idTipoCredito"
                (click)="filtros.idTipoCredito = null">clear</mat-icon>
              </mat-form-field>
            </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Solicitante
          <span *ngIf="contabilizadorDeFiltros('solicitante') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('solicitante') }}</span>

        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="'Solicitante'"
              [placeHolder]="'Busca por nombre'"
              [value]="'option'"
              [labelField]="'nombre'"
              [icon]="'account_circle'"
              [realizarBusqueda]="solicitanteFilterSearch"
              [(ngModel)]="filtros.solicitante"
              (ngModelChange)="this.contabilizadorDeFiltros('solicitante');"
            >
            </mat-select-search>

          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
            Fechas
            <span *ngIf="contabilizadorDeFiltros('fechas') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('fechas') }}</span>

          </mat-expansion-panel-header>
          <ol class="lista-filtros-side">
            <li class="filtro-side">
              <mat-accordion [multi]="true">
                <mat-expansion-panel  [expanded]="true">
                  <mat-expansion-panel-header>
                      Fecha solicitud
                      <span *ngIf="contabilizadorDeFiltros('fechas') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('fechas') }}</span>
                  </mat-expansion-panel-header>
                  <ol class="lista-filtros-side">
                    <li class="filtro-side">
                      <mat-date-picker-formater
                        [label]="'Fecha inicio'"
                        [placeHolder]="'Capture la fecha inicio'"
                        [max]="filtros.requestEndDate ? filtros.requestEndDate : today"
                        [(ngModel)]="filtros.requestStartDate"
                        (ngModelChange)="this.contabilizadorDeFiltros('fechas');"
                      />

                    </li>
                    <li class="filtro-side">
                      <mat-date-picker-formater
                        [label]="'Fecha fin'"
                        [placeHolder]="'Capture la fecha fin'"
                        [min]="filtros.requestStartDate"
                        [max]="today"
                        [(ngModel)]="filtros.requestEndDate"
                        (ngModelChange)="this.contabilizadorDeFiltros('fechas');"
                      />

                    </li>
                  </ol>
                </mat-expansion-panel>
              </mat-accordion>
            </li>
          </ol>
        </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Agente
          <span *ngIf="contabilizadorDeFiltros('agente') >= 1" class="notification-dot-filtros">{{ contabilizadorDeFiltros('agente') }}</span>

        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="'Agente'"
              [placeHolder]="'Busca por id o nombre'"
              [value]="'option'"
              [labelField]="'nombre'"
              [icon]="'account_circle'"
              [realizarBusqueda]="agenteFilterSearch"
              [(ngModel)]="filtros.agente"
              (ngModelChange)="this.contabilizadorDeFiltros('agente');"
              [minBusqueda]="1"
            >
            </mat-select-search>

          </li>

        </ol>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <ol class="lista-filtros-buttons">
    <button
    class="boton-accion-front"
      style="width: 100%;"
      mat-stroked-button
      color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; setFilter();">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="clearFilter()">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>
