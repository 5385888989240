import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApplicationService } from "../../../application/shared/application.service";
import { VisitService } from "../../../visit/visit.service";
import { environmentSelector } from '../../../../environments/environment.selector';
import { HttpHeaders, HttpEventType, HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { LoadingService } from 'src/app/shared/components/loading-bar/loading-bar.service';

@Component({
    selector: 'app-cuentas-desasignadas-dialog',
    templateUrl: 'cuentas-desasignadas-dialog.component.html',
    styleUrls: ['cuentas-desasignadas-dialog.component.css'],
    providers: [
        VisitService
    ]
  })
  export class CuentasDesasignadasDialog {
    public labelEstado: string;
    public labelMunicipio: string;
    public acreditados = [];
    public loading:boolean;
    tablaAcreditados: any;
    private environment = environmentSelector();

    
    constructor(
        private app: ApplicationService,
        public dialogRef: MatDialogRef<CuentasDesasignadasDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private visitService: VisitService,
        private http: HttpClient,
        private loadingService: LoadingService
    ) {
        this.acreditados = data.acreditados;
        this.labelMunicipio = this.environment.labelMunicipio;
        this.labelEstado = this.environment.labelEstado;
        this.crearTabla();
    }

    crearTabla(){
         this.tablaAcreditados = {
            headers: false,
            tableHeaders: false,
            multiSelect: false,
            columnas: [
                {
                    name: 'ID acreditado',
                    key: 'idAcreditado',
                    hide: true
                }, {
                    name: 'ID CuentaPG',
                    key: 'idCuenta',
                    hide: true
                }, {
                    name: 'Cliente',
                    key: 'nombreCliente',
                    hide: false
                }, {
                    name: 'ID Cuenta',
                    key: 'idExterno',
                    hide: false
                }, {
                    name: 'ID Subcuenta',
                    key: 'subIdExternoCliente',
                    hide: false
                }, {
                    name: 'Nombre',
                    key: 'nombreCompleto',
                    hide: false
                }, {
                    name: 'Producto',
                    key: 'nombreProducto',
                    hide: false
                }, {
                    name: 'Vencida',
                    key: 'vencida',
                    hide: false
                }, {
                    name: 'Folio Domicilio',
                    key: 'folioDomicilio',
                    hide: false
                }, {
                    name: 'ID Domicilio Acreditado',
                    key: 'idAcreditadoCuentaDomicilio',
                    hide: true
                }, {
                    name: this.labelMunicipio,
                    key: 'municipio',
                    hide: false
                }, {
                    name: this.labelEstado,
                    key: 'estado',
                    hide: false
                }
            ]
        }
    }
  
    onNoClick(): void {
      this.dialogRef.close();
    }

    public descargarExcel(): void {
        this.loadingService.setLoading(true);  // Asegúrate de que loadingService esté inyectado en tu componente
    
        this.visitService.descargarListadoCuentas('', this.data.idAgente).subscribe(event => {
            if (event.type === HttpEventType.Response) {
                this.loadingService.setLoading(false);
                const blob = new Blob([event.body], { type: event.body.type });
                const fechaActual = moment().format('YYYYMMDD'); // Formato de fecha, ajustable según necesidad
                saveAs(blob, `Cuentas Desasignadas ${this.data.idAgente} ${fechaActual}`);
            }
        }, error => {
            this.loadingService.setLoading(false);
            this.app.showSnackbar(
                "Aviso",
                "Ocurrió un problema al intentar descargar el archivo.",
                3000,
                "error"
            );
        });
    }

  }