import { AnswerComponent } from './../../../lender/product-checklist/answer/answer.component';
import { QuestionComponent } from './../../../lender/product-checklist/question/question.component';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { Checklist } from "../modelos/checklist.model";
import { ChecklistPregunta } from "../modelos/checklist-pregunta.model";
import { ChecklistRespuesta } from "../modelos/checklist-respuesta.model";
import { LenderService } from "../../../lender/lender.service";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ChecklistService } from '../../../checklist/checklist.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PosicionPreguntaDialog } from '../modales/posicion-pregunta/posicion-pregunta-dialog.component';
import { Location } from '@angular/common';
import { ConfirmacionDialog } from 'src/app/modales-genericos/confirmacion/confirmacion-dialog.component';
import { TextAreaDialogComponent } from 'src/app/shared/components/dialog/text-area-dialog/text-area-dialog.component';
import {ModalFormatosAfectadosComponent} from '../modales/formatos-afectados/formatos-afectados.component';
import { VisitService } from 'src/app/visit/visit.service';
import { GrupoVariable } from '../interfaces/variables/variables.interface';
declare var $: any;

@Component({
  selector: 'app-checklists-editar',
  templateUrl: './checklists-editar.component.html',
  styleUrls: ['./checklists-editar.component.css'],
  providers: [LenderService, ChecklistService, VisitService]
})
export class ChecklistsEditarComponent implements OnInit {
  checklistForm: FormGroup;
  sessionData: SessionData;
  public idFinanciera: number;
  public financieras: any;
  public checklist: Checklist;
  private checklistSinCambios: String;
  public nombreChecklist: String;
  public clienteData: any;
  public nombreFinanciera: string;
  accordionOpen: number;
  tiposRespuestas = [];
  tiposRespuestaAnswer = [];
  acciones = [];
  accionesRecomendadas = [];
  //Se usara para agregar los ids disponibles
  disponibles = [];

  public loadings: any = {
    checklists: false
  };
  public nombreCliente: string;
  idCliente: number;
  idTipoCredito: number;
  idProducto: number;
  idChecklistCliente: number;
  checklistOriginal: any;
  loadVariablesCuentas: boolean = true;
  variablesCuentas: GrupoVariable[] = [];


  constructor(
    private lenderService: LenderService,
    private checklistService: ChecklistService,
    private visitService: VisitService,
    private formBuilder: FormBuilder,
    private app: ApplicationService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private router: Router,
    private rootElement: ElementRef,
    private location: Location
    )
  {
    this.checklist = new Checklist();
    this.sessionData = this.localStorageService.getSessionData();
  }

  ngOnInit() {
    this.idCliente = +this.route.snapshot.queryParamMap.get('idCliente');
    const queryParamValue = this.route.snapshot.queryParamMap.get('idTipoCredito');
    this.idTipoCredito = queryParamValue !== null ? +queryParamValue : null;
    
    this.nombreChecklist = this.route.snapshot.queryParamMap.get('nombreCheckList');
    this.nombreCliente = this.route.snapshot.queryParamMap.get('nombreCliente');
    this.idProducto = +this.route.snapshot.queryParamMap.get('idProducto');
    this.idChecklistCliente = +this.route.snapshot.queryParamMap.get('idChecklistCliente');


    if (!this.idCliente || !this.nombreChecklist || !this.nombreCliente || !this.idProducto || !this.idChecklistCliente) {
      this.app.showSnackbar('¡Aviso!', 'Faltan parámetros en la URL.', 2000, 'error');
      this.router.navigate([`catalogos/formatos`]);
    }else{
      this.obtenerInformacion();
    }
  }

  async obtenerInformacion() {
    this.loadings.checklists = true;

    try {
      // Obtener tipos de respuestas
      this.tiposRespuestas = await this.checklistService.getTypesAnswers().toPromise();
      this.tiposRespuestaAnswer = this.tiposRespuestas.map(t => ({ ...t, value: t.id }));

      // Obtener acciones de respuestas
      const actionsAnswers = await this.checklistService.getActionsAnswers().toPromise();
      this.acciones = actionsAnswers.map(a => ({ ...a, value: a.name }));
      this.acciones.sort((a, b) => Number.parseInt(a.idAccionRespuesta) - Number.parseInt(b.idAccionRespuesta));

      // Filtrar acciones recomendadas
      this.accionesRecomendadas = this.acciones.filter(accion => accion.id == 1/* || accion.id == 17*/);

      // Obtener checklist por producto
      const checklist: any = await this.lenderService.getChecklistByProduct(this.idCliente, this.idProducto, this.idTipoCredito).toPromise();

      if (checklist) {
        // Construir preguntas del checklist
        this.checklist.preguntas = ChecklistPregunta.mapArray(checklist.preguntas);
        this.checklist.idCliente = checklist.idCliente;
        this.checklist.idProducto = checklist.idProducto;
        this.checklist.idChecklistCliente = this.idChecklistCliente;
        this.checklist.idChecklist = checklist.idChecklist;
        this.checklist.numVersion = checklist.idVersion;
        this.checklist.idTipoCredito = this.idTipoCredito;
        this.checklist.etiquetasAsignadas = checklist.etiquetasAsignadas;

        // Construir formulario del checklist
        this.checklistForm = this.formBuilder.group({
          idChecklist: new FormControl(checklist.idChecklist),
          idCliente: new FormControl(checklist.idCliente),
          numVersion: new FormControl(checklist.idVersion),
          idTipoCredito: new FormControl(this.idTipoCredito),
          idChecklistCliente: new FormControl(this.idChecklistCliente),
          idProducto: new FormControl(checklist.idProducto),
          idVersion: new FormControl(checklist.idVersion),
          preguntas: this.buildAsks(checklist)
        });

        // Obtener condición para mostrar
        this.obtenerCondicionMostrar();

        //Obtiene las variables de cuentas
        this.obtenerVariables();

        // Convertir checklist a cadena JSON y codificar en Base64
        const checklistsString = JSON.stringify(this.checklist);
        this.checklistSinCambios = this.utf8ToBase64(checklistsString);
        this.checklistOriginal = JSON.parse(checklistsString);

        // Crear copia de preguntas
        const preguntasCopia = ChecklistPregunta.mapArray(JSON.parse(checklistsString).preguntas);
        this.obtenerDisponibles(preguntasCopia);
        this.loadings.checklists = false;
      }
    } catch (error) {
      this.app.showError(error);
    } finally {
      this.loadings.checklists = false;
    }
  }


  private utf8ToBase64(str){
    return btoa(unescape(encodeURIComponent(str)));
  }

  private obtenerDisponibles(preguntas: ChecklistPregunta[]){
    var disponibleActual: number = 1;
    preguntas.sort(function(a, b) {
      return (a.idChecklistPreguntaCondicion > b.idChecklistPreguntaCondicion)
        ? 1
        : -1
      ;
    });

    for (let index = 0; index < preguntas.length; index++) {
      const pregunta = preguntas[index];
      if(pregunta.idChecklistPreguntaCondicion > disponibleActual){
        this.disponibles.push(disponibleActual);
      }
      disponibleActual = pregunta.idChecklistPreguntaCondicion + 1;
    }
  }

  private reordenarPreguntas(){
    for (let i = 0; i < this.checklist.preguntas.length; i++) {
      this.checklist.preguntas[i].orden = i+1;

    }
  }

  private obtenerCondicionMostrar(){
    this.checklist.preguntas.forEach(pregunta => {
      if(pregunta.idChecklistPreguntaPadre){
        var posiblePadre = this.checklist.preguntas.find(preguntaPadre => preguntaPadre.idChecklistPreguntaCondicion == pregunta.idChecklistPreguntaPadre);
        if(posiblePadre && posiblePadre.respuestas.some((respuesta)=>respuesta.condiciones.length>0)){
          pregunta.condicionMostrar = `Esta pregunta depende de la pregunta "${posiblePadre.orden} - ${posiblePadre.nombrePregunta || 'Nueva pregunta'}"`;
          pregunta.margen = posiblePadre.margen + 10;
        }
        else{
          pregunta.idChecklistPreguntaPadre = null;
          pregunta.condicionMostrar = null;
        }
      }
      else{
        pregunta.condicionMostrar = null;
      }
    });
  }

  private limpiarEditando(){
    this.checklist.preguntas.forEach(preguntaActual => {
      preguntaActual.editando=false;
      preguntaActual.respuestas.forEach(respuestaActual => {
        respuestaActual.editando = false;
      });
    });
  }

  public editarNombrePregunta(pregunta: ChecklistPregunta, i : number): void {
    this.limpiarEditando();
    pregunta.editando = true;
    var id = `#pregunta_${i}`;
    setTimeout(() => {
      $(id).focus();
    });
  }

  public editarNombreRespuesta(respuesta: ChecklistRespuesta, i : number, j: number): void {
    this.limpiarEditando();
    if(this.checklist.preguntas[i].respuestas.length > 1){
      respuesta.editando = true;
      var id = `#respuesta_${i}_${j}`;
      respuesta.nombreRespuesta = respuesta.nombreRespuesta || "";
      setTimeout(() => {
        $(id).focus();
      });
    }
  }

  valueRespuesta(pregunta: ChecklistPregunta, i: number){
    const control = this.checklistForm.get('preguntas')['controls'][i].get('nombrePregunta');
    pregunta.nombrePregunta = control.value;
    if (control.value && control.value.length > 200) {
      this.app.showSnackbar('¡Aviso!', 'No puede escribir más de 200 caracteres en el nombre de la pregunta.', 3000, 'warning');
      control.setValue(control.value.substring(0, 200)); // Limitar a 200 caracteres
    }
  }

  public onKeyPresNombrePregunta(event, pregunta: ChecklistPregunta, i: number){
    const control = this.checklistForm.get('preguntas')['controls'][i].get('nombrePregunta');
    if (control.value && control.value.length >= 200 && event.key !== 'Backspace' && event.key !== 'Delete') {
      event.preventDefault();
      this.app.showSnackbar('¡Aviso!', 'No puede escribir más de 200 caracteres en el nombre de la pregunta.', 3000, 'warning');
    }
    if (event.key === 'Enter') {
      pregunta.editando = false;
      event.preventDefault();
    }
  }




  public onKeyPresNombreRespuesta(event, respuesta: ChecklistRespuesta, i: number, j: number){
    if(event.key == 'Enter'){
      respuesta.editando = false;
      event.preventDefault = true;
    }
  }

  cancel(dialogo: boolean = false) {
    if(!dialogo && this.checklistSinCambios != this.utf8ToBase64(JSON.stringify(this.checklist))){
      const dialogRef = this.dialog.open(ConfirmacionDialog, {
        data:{
          titulo: "Se han detectado cambios en el cuestionario",
          mensaje: "¿Desea cerrar sin guardar los cambios realizados?",
          icono: "check",
          colorIcono: "warn",
          boton1: "No",
          boton2: "Sí",
          texto: null,
          claseAccion: "boton-accion-eliminar"
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.cancel(true);
        }
      });
      return;
    }
    localStorage.removeItem('checklisTemp');
    localStorage.removeItem('nombre');
    this.location.back();
  }

  compararEtiquetas() {
    const etiquetasOriginales = this.extraerEtiquetas(this.checklistOriginal);
    const etiquetasActuales = this.extraerEtiquetas(this.checklist);

    const etiquetasAgregadas = etiquetasActuales.filter(etiqueta =>
      !etiquetasOriginales.some(etiquetaOriginal => etiquetaOriginal.id === etiqueta.id && etiquetaOriginal.label === etiqueta.label)
    );

    const etiquetasEliminadas = etiquetasOriginales.filter(etiqueta =>
      !etiquetasActuales.some(etiquetaActual => etiquetaActual.id === etiqueta.id && etiquetaActual.label === etiqueta.label)
    );

    return {
      etiquetasAgregadas,
      etiquetasEliminadas
    };
  }

  extraerEtiquetas(checklist) {
    return checklist.preguntas.flatMap(pregunta =>
      pregunta.respuestas
        // Aquí agregamos la validación para asegurarnos que la respuesta sea de tipo etiqueta
        .filter(respuesta => respuesta.idAccionRespuesta === 4)
        .flatMap(respuesta =>
          respuesta.options.map(option => ({
            id: option.id,
            label: option.label
          }))
        )
    );
  }

  //realiza las validaciones necesarias para continuar con el guardado de la información
  validarGuardado(): boolean{
    const checklist = this.checklist;//this.checklistForm.getRawValue();

    if(this.checklistSinCambios == this.utf8ToBase64(JSON.stringify(this.checklist))){
      this.app.showSnackbar('¡Aviso!', 'No se encontraron cambios en el cuestionario.', 3000, 'warning');
      return false;
    }

    let preguntaRepetida = this.validarPreguntasRepetidas();
    if(preguntaRepetida){
      this.app.showSnackbar('¡Aviso!', `La pregunta con nombre '${preguntaRepetida}' se encuentra repetida, por favor revisa tu cuestionario`, 3000, 'warning');
      return false;
    }

    let preguntaAccionRepetida = this.validarPreguntasAccionRepetida();
    if(preguntaAccionRepetida){
      this.app.showSnackbar('¡Aviso!', `La(s) acción(es) se encuentra(n) repetida(s): "${preguntaAccionRepetida}", por favor revisa tu cuestionario`, 3000, 'warning');
      return false;
    }

    let preguntaAccionesPromesa = this.validarPreguntasAccionesPromesa();
    if(preguntaAccionesPromesa){
      this.app.showSnackbar('¡Aviso!', `Para activar la opción de promesa de pago, asegúrate de que las siguientes acciones estén también habilitadas: "${preguntaAccionesPromesa}"`, 3000, 'warning');
      return false;
    }

    if(!this.validarRespuestas()){
      this.app.showSnackbar('¡Aviso!', 'Algunas respuestas requieren nombre.', 3000, 'warning');
      return false;
    }

    const { etiquetasAgregadas, etiquetasEliminadas } = this.compararEtiquetas();

    // Crear el campo de etiquetas en el checklist
    checklist.etiquetasModificadas = {
      agregadas: etiquetasAgregadas.map(etiqueta => ({ id: etiqueta.id, nombreEtiqueta: etiqueta.label })),
      eliminadas: etiquetasEliminadas.map(etiqueta => ({ id: etiqueta.id, nombreEtiqueta: etiqueta.label }))
    };

    let accionesRecomendadas = this.validarAccionesRecomendadas();
    if(accionesRecomendadas && this.idTipoCredito == null){
      this.app.showSnackbar('¡Aviso!', `A tu cuestionario le falta la siguiente accion obligatoria "${accionesRecomendadas}".`, 3000, 'warning');
      return false;
    }

    return true;
  }

  capturarComentario(){
    if(!this.validarGuardado()){
      return;
    }

    const dialogRef = this.dialog.open(TextAreaDialogComponent, {
        width: '50%',
        data:{
          title: "Información de cambios",
          subtitle: ``,
          action: "boton-guardar",
          label: "Descripción",
          placeHolder: "Descripción del cambio",
          buttonOk: 'Aceptar',
          buttonOkIcon: "save"
        }
    });

    dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.save(result);
        }
    });
  }

  save(descripcion: string) {
    const checklist = this.checklist;//this.checklistForm.getRawValue();

    const loading = this.app.showLoading('Estamos guardando el cuestionario...');
    this.loadings.checklists = true;

    checklist.checklistsInactivos = [];
    checklist.descripcion = descripcion;
    this.lenderService.saveChecklist(this.idCliente, checklist).subscribe(async (checklist) => {
      this.checklistForm = null;
      this.loadings.checklists = false;
      this.app.hideLoading(loading);
      if(checklist.formatosAfectados.length > 0) {
        this.openDialogFormato(checklist.formatosAfectados);
      }else {
        this.cancel(true);
      }
      this.app.showSnackbar('¡Aviso!', 'Se han guardado los cambios correctamente.', 3000, 'success');
      //this.cancel();
    }, (error) => {
      this.loadings.checklists = false;
      this.app.showError(error)
      this.app.hideLoading(loading);
    });
  }

  private obtenerVariables(): void {
    this.loadVariablesCuentas = true;
    this.visitService.obtienerVariable(this.idCliente, this.checklist.idChecklist, this.checklist.numVersion, this.idProducto, 3)
        .subscribe(
          variables => {
                this.variablesCuentas = variables.data;
                this.loadVariablesCuentas = false;
            },
            error => this.app.showError(error)
        );
  }

  private validarPreguntasRepetidas(){
    var respuesta: String = '';
    for (let i = 0; i < this.checklist.preguntas.length; i++) {
      const pregunta = this.checklist.preguntas[i];
      if(this.checklist.preguntas.filter((pr, ip) => pr.nombrePregunta.trim() == pregunta.nombrePregunta.trim()).length > 1){
        respuesta = pregunta.nombrePregunta;
        i = this.checklist.preguntas.length;
      }
    }
    return respuesta;
  }

  private validarPreguntasAccionRepetida(){
    var respuesta: String = '';
    let accionesRepetidas: number[] = [];
    let nombreAccionesRepetidas: String[] = []
    let preguntasRevisar: ChecklistPregunta[] = this.checklist.preguntas.filter((preguntaFiltro) => preguntaFiltro.respuestas.length == 1 && preguntaFiltro.respuestas[0].idAccionRespuesta);
    for (let index = 0; index < preguntasRevisar.length; index++) {
      const preguntaActual: ChecklistPregunta = preguntasRevisar[index];
      if(accionesRepetidas.some((a)=> a == preguntaActual.respuestas[0].idAccionRespuesta)){
        if(!nombreAccionesRepetidas.some((a)=> a==preguntaActual.respuestas[0].accion)){
          nombreAccionesRepetidas.push(preguntaActual.respuestas[0].accion);
        }
      }
      else{
        accionesRepetidas.push(preguntaActual.respuestas[0].idAccionRespuesta);
      }
    }
    if(nombreAccionesRepetidas.length>0){
      respuesta = nombreAccionesRepetidas.join(', ');
    }
    return respuesta;
  }

  private validarPreguntasAccionesPromesa(){
    var respuesta: String = '';
    let accionesRepetidas: number[] = [];
    let nombreAccionesPendientes: String[] = []
    let preguntasRevisar: ChecklistPregunta[] = this.checklist.preguntas.filter((preguntaFiltro) => preguntaFiltro.respuestas.length == 1 && preguntaFiltro.respuestas[0].idAccionRespuesta == 5);
    if(preguntasRevisar.length > 0){
      let preguntaAccionesPromesa: ChecklistPregunta[] = this.checklist.preguntas.filter((preguntaFiltro) => preguntaFiltro.respuestas.length == 1 && preguntaFiltro.respuestas[0].idAccionRespuesta == 6);
      if(preguntaAccionesPromesa.length == 0){
        nombreAccionesPendientes.push('promesa-pago-fecha');
      }

      preguntaAccionesPromesa = this.checklist.preguntas.filter((preguntaFiltro) => preguntaFiltro.respuestas.length == 1 && preguntaFiltro.respuestas[0].idAccionRespuesta == 7);
      if(preguntaAccionesPromesa.length == 0){
        nombreAccionesPendientes.push('promesa-pago-metodo');
      }

      preguntaAccionesPromesa = this.checklist.preguntas.filter((preguntaFiltro) => preguntaFiltro.respuestas.length == 1 && preguntaFiltro.respuestas[0].idAccionRespuesta == 8);
      if(preguntaAccionesPromesa.length == 0){
        nombreAccionesPendientes.push('promesa-pago-monto');
      }
    }

    if(nombreAccionesPendientes.length>0){
      respuesta = nombreAccionesPendientes.join(', ');
    }
    return respuesta;
  }

  private validarAccionesRecomendadas(){
    var respuesta: String = '';
    let accionesPendientes: string[] = [];
    let agregarAccionPendiente: boolean = true;
    for (let iar = 0; iar < this.accionesRecomendadas.length; iar++) {
      const accion = this.accionesRecomendadas[iar];
      agregarAccionPendiente = true;
      for (let i = 0; i < this.checklist.preguntas.length; i++) {
        const pregunta = this.checklist.preguntas[i];
        if(pregunta.respuestas.length>0 && pregunta.respuestas.length<2){
          for (let index = 0; index < pregunta.respuestas.length; index++) {
            const respuesta = pregunta.respuestas[index];
            if(respuesta.idAccionRespuesta == accion.id){
              agregarAccionPendiente = false;
              index = pregunta.respuestas.length;
              i = this.checklist.preguntas.length;
            }
          }
        }
      }
      if(agregarAccionPendiente){
        accionesPendientes.push(accion.name);
      }
    }

    respuesta = accionesPendientes.join('<br>');
    return respuesta;
  }

  private validarRespuestas(): boolean{
    var respuesta = true;
    for (let i = 0; i < this.checklist.preguntas.length; i++) {
      const pregunta = this.checklist.preguntas[i];
      if(pregunta.respuestas.length>1){
        for (let index = 0; index < pregunta.respuestas.length; index++) {
          const respuesta = pregunta.respuestas[index];
          if(!respuesta.nombreRespuesta){
            return false;
          }
        }
      }
    }
    return respuesta;
  }

  buildAsks(checklist: Checklist) {
    let preguntas: FormArray = this.formBuilder.array([]);

    checklist.preguntas.forEach(pregunta => {
      const respuestas = this.buildAnswers(pregunta);

      if (respuestas.length===0) {
        respuestas.setErrors({ required: true });
      }

      let preguntaGroup = this.formBuilder.group({
        idChecklistPregunta: new FormControl(pregunta.idChecklistPregunta),
        idChecklistPreguntaPadre: new FormControl(pregunta.idChecklistPreguntaPadre),
        llaveCliente: new FormControl(pregunta.llaveCliente),
        nombrePregunta: new FormControl({
          value: pregunta.nombrePregunta,
          disabled: false
        }, [Validators.required, Validators.maxLength(200)]),
        //preguntasHijas: this.buildAsksChildren(pregunta),
        respuestas
      });

      preguntas.push(preguntaGroup);
    });

    return preguntas;
  }

  buildAnswers(pregunta: ChecklistPregunta) {
    let respuestas: FormArray = this.formBuilder.array([]);

    if (!Array.isArray(pregunta.respuestas)) {
      return respuestas;
    }

    pregunta.respuestas.forEach(respuesta => {
      let respuestaGroup = this.formBuilder.group({
        idChecklistRespuesta: new FormControl(respuesta.idChecklistRespuesta),
        idAccionRespuesta: new FormControl(respuesta.idAccionRespuesta),
        accion: new FormControl(respuesta.accion),
        idTipoRespuesta: new FormControl(respuesta.idTipoRespuesta),
        mostrarPreguntasCuando: new FormControl(respuesta.mostrarPreguntasCuando || false),
        minCaracteres: new FormControl(respuesta.minCaracteres || 0),
        maxCaracteres: new FormControl(respuesta.maxCaracteres || 0),
        nombreRespuesta: new FormControl(respuesta.nombreRespuesta),
        options: new FormControl(respuesta.options.length > 0 ? respuesta.options.slice() : []),
        columnas: new FormControl(respuesta.columnas.length > 0 ? respuesta.columnas.slice() : []),
        requerida: new FormControl(respuesta.requerida || false),
        preLlenarRespuesta: new FormControl(respuesta.valorPreLlenado ? true : false),
        valorPreLlenado: new FormControl(respuesta.valorPreLlenado || '')
      });
      respuestas.push(respuestaGroup);
    });

    return respuestas;
  }

  /*buildAsksChildren(pregunta: any) {
    let preguntasHijas = this.formBuilder.array([]);

    pregunta.preguntasHijas.forEach(preguntaHija => {
      preguntasHijas.push(this.formBuilder.group({
        idChecklistPregunta: new FormControl(preguntaHija.idChecklistPregunta),
        idChecklistPreguntaPadre: new FormControl(preguntaHija.idChecklistPreguntaPadre),
        llaveCliente: new FormControl(preguntaHija.llaveCliente),
        nombrePregunta: new FormControl(preguntaHija.nombrePregunta, Validators.required),
        respuestas: this.buildAnswers(preguntaHija)
      }));
    });

    return preguntasHijas;
  }*/

  afterExpand(index) {
    this.accordionOpen = index;
  }

  afterCollapse(index) {
    if (this.accordionOpen === index) {
      this.accordionOpen = null;
    }
  }

  drop(event: CdkDragDrop<string[]>, esPadre, preguntaChecklist, preguntaPadre) {
    this.moverIndicePregunta(event.previousIndex, event.currentIndex);
    /*if(this.checklistForm && this.checklist){
      const questions = esPadre ? this.checklistForm.get('preguntas') : preguntaPadre.get('preguntasHijas');
      const array = esPadre ? this.checklist.preguntas : preguntaChecklist.preguntasHijas;

      moveItemInArray(questions['controls'], event.previousIndex, event.currentIndex);
      moveItemInArray(array, event.previousIndex, event.currentIndex);
      this.checklistForm.markAsDirty();
    }
    this.reordenarPreguntas();
    this.obtenerCondicionMostrar();*/
  }

  moverIndicePregunta(indexAnterior: number, indexNvo: number, preguntaMover: any = null){
    let indexAnteriorUtilizar: number = preguntaMover ? preguntaMover.orden-1 : indexAnterior;
    if(this.checklistForm && this.checklist){
      const questions = this.checklistForm.get('preguntas');
      const array = this.checklist.preguntas;

      moveItemInArray(questions['controls'], indexAnteriorUtilizar, indexNvo);
      moveItemInArray(array, indexAnteriorUtilizar, indexNvo);
      this.checklistForm.markAsDirty();
    }
    this.reordenarPreguntas();
    this.obtenerCondicionMostrar();
  }

  dropRespuesta(event: CdkDragDrop<string[]>, i: number) {
    if(this.checklistForm && this.checklist){
      const respuesta = this.checklistForm.get('preguntas')['controls'][i].get('respuestas') ;
      const array = this.checklist.preguntas[i].respuestas;

      moveItemInArray(respuesta['controls'], event.previousIndex, event.currentIndex);
      moveItemInArray(array, event.previousIndex, event.currentIndex);
      this.checklistForm.markAsDirty();
    }
  }

  // #region Control preguntas y respuestas
  validarAgregarRespuesta(pregunta: ChecklistPregunta){
    if(pregunta.respuestas.length > 0 && pregunta.respuestas[0].accion){
      return false;
    }
    return true;
  }

  addParentQuestion(accion: any = null) {
    const questions = this.checklistForm.get('preguntas');
    const respuestas: FormArray = this.formBuilder.array([]);
    var idChecklistCondicion: number = this.checklist.preguntas.length + 1;

    if(this.disponibles.length > 0){
      idChecklistCondicion = this.disponibles[0];
      this.disponibles.splice(0, 1);
    }

    respuestas.push(this.formBuilder.group({
      idChecklistRespuesta: new FormControl(null),
      idAccionRespuesta: new FormControl(accion != null ? accion.id : null),
      accion: new FormControl(accion != null ? accion.name : null),
      idTipoRespuesta: new FormControl(1),
      mostrarPreguntasCuando: new FormControl(false),
      nombreRespuesta: new FormControl(null),
      minCaracteres: new FormControl(0),
      maxCaracteres: new FormControl(0),
      options: new FormControl([]),
      columnas: new FormControl([]),
      requerida: new FormControl(true),
      preLlenarRespuesta: new FormControl(false),
      valorPreLlenado: new FormControl(null),
      condiciones: []
    }));

    questions['push'](this.formBuilder.group({
      idChecklistPregunta: new FormControl(null),
      idChecklistPreguntaPadre: new FormControl(null),
      nombrePregunta: new FormControl(accion != null ? accion.accion : null, [Validators.required, Validators.maxLength(200)]),
      llaveCliente: new FormControl(null),
      preguntasHijas: this.formBuilder.array([]),
      respuestas
    }));

    var preguntaAgregar: ChecklistPregunta = {
      idChecklistPregunta: null,
      idChecklistPreguntaPadre: null,
      idChecklistPreguntaCondicion: idChecklistCondicion,
      orden: this.checklist.preguntas.length + 1,
      nombrePregunta: null,
      llaveCliente: null,
      editando: false,
      seleccionada: false,
      condicionMostrar: null,
      visible: false,
      margen: 0,
      respuestas: [{
        idChecklistRespuesta: null,
        idAccionRespuesta: null,
        accion: null,
        idTipoRespuesta: 1,
        mostrarPreguntasCuando: false,
        minCaracteres: 0,
        maxCaracteres: 0,
        nombreRespuesta: null,
        options: [],
        columnas: [],
        requerida: false,
        editando: false,
        preLlenarRespuesta: false,
        valorPreLlenado: null,
        condiciones: []
      } as ChecklistRespuesta]
    };
    this.checklist.preguntas.push(preguntaAgregar);

    this.checklistForm.markAsDirty();

    this.accordionOpen = this.checklist.preguntas.length - 1;
    this.editarNombrePregunta(preguntaAgregar, this.checklist.preguntas.length -1);
  }

  removeParentQuestion(questionIndex, forzarBorradoConAccion: boolean = false) {
    const pregunta = this.checklist.preguntas[questionIndex];

    if(pregunta.respuestas && pregunta.respuestas.length > 0 && pregunta.respuestas[0].accion && !forzarBorradoConAccion){
      const dialogRef = this.dialog.open(ConfirmacionDialog, {
          data:{
            titulo: "Pregunta con acción",
            mensaje: "¿Estas seguro de eliminar la pregunta con acción?",
            icono: "delete_forever",
            colorIcono: "warn",
            boton1: "No",
            boton2: "Sí",
            texto: null,
            claseAccion: "boton-accion-eliminar"
          }
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.removeParentQuestion(questionIndex, true);
          }
      });
      return;
    }

    this.disponibles.push(this.checklist.preguntas[questionIndex].idChecklistPreguntaCondicion);
    this.disponibles.sort(function(a, b) {
      return (Number.parseInt(a) > Number.parseInt(b))
        ? 1
        : -1
      ;
    });

    this.removerDependencias(this.checklist.preguntas[questionIndex].idChecklistPreguntaCondicion);

    this.checklist.preguntas.splice(questionIndex, 1);

    this.checklistForm.get('preguntas')['removeAt'](questionIndex);

    this.checklistForm.markAsDirty();
    this.reordenarPreguntas();
    this.obtenerCondicionMostrar();
  }

  private removerDependencias(idChecklistPadre: number){
    for (let i = 0; i < this.checklist.preguntas.length; i++) {
      if(this.checklist.preguntas[i].idChecklistPreguntaPadre == idChecklistPadre){
        this.checklist.preguntas[i].idChecklistPreguntaPadre = null;
      }
    }
  }

  abrirDialogoCambiarIndice(index: number){
    let respuestaDialogo = function(nuevoIndice: number){
      this.moverIndicePregunta(index, nuevoIndice);
    }
    const dialogRef = this.dialog.open(PosicionPreguntaDialog, {
      disableClose: false,
      /*width: '50%',*/
      data: {
        title: '¿A qué posición deseas cambiar la pregunta?',
        currentValue: index+1,
        maxValue: this.checklist.preguntas.length,
        respuesta: respuestaDialogo.bind(this)
      }

    });

    dialogRef.afterClosed().subscribe(data => {});
  }

  addQuestion(questionIndex) {
    const dialogRef = this.dialog.open(QuestionComponent, {
      width: '40%',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(question => {
      if (question) {
        const questions = this.checklistForm.get('preguntas')['controls'][questionIndex].get('preguntasHijas');

        questions.push(this.formBuilder.group({
          idChecklistPregunta: new FormControl(question.idChecklistPregunta),
          idChecklistPreguntaPadre: new FormControl(question.idChecklistPreguntaPadre),
          nombrePregunta: new FormControl(question.nombrePregunta),
          llaveCliente: new FormControl(question.llaveCliente),
          respuestas: this.buildAnswers(question)
        }));

        //this.checklist.preguntas[questionIndex].preguntasHijas.push(question);

        this.checklistForm.markAsDirty();
      }
    });
  }

  removeQuestion(questionIndex, questionChildIndex) {
    /*const pregunta = this.checklist.preguntas[questionIndex].preguntasHijas[questionChildIndex];

    if(pregunta.respuestas && pregunta.respuestas[0].accion){
      this.app.showSnackbar('¡Aviso!', 'No se pueden eliminar preguntas con acción.', 3000, 'warning');

      return;
    }
    //this.checklist.preguntas[questionIndex].preguntasHijas.splice(questionChildIndex, 1);

    this.checklistForm.get('preguntas')['controls'][questionIndex].get('preguntasHijas').removeAt(questionChildIndex);

    this.checklistForm.markAsDirty();*/
  }

  editQuestion(questionIndex, questionChildIndex) {
    const checklist = this.checklistForm.getRawValue();
    const questionFormGroup = checklist.preguntas[questionIndex].preguntasHijas[questionChildIndex];

    const dialogRef = this.dialog.open(QuestionComponent, {
      width: '40%',
      disableClose: true,
      data: { pregunta: questionFormGroup }
    });

    dialogRef.afterClosed().subscribe(question => {
      if (question) {
        const questionFormGroup = this.checklistForm.get('preguntas')['controls'][questionIndex].get('preguntasHijas')['controls'][questionChildIndex];

        questionFormGroup.get('idChecklistPregunta').setValue(question.idChecklistPregunta);
        questionFormGroup.get('idChecklistPreguntaPadre').setValue(question.idChecklistPreguntaPadre);
        questionFormGroup.get('nombrePregunta').setValue(question.nombrePregunta);
        questionFormGroup.get('llaveCliente').setValue(question.llaveCliente);

        const respuestas = questionFormGroup.get('respuestas') as FormArray;

        for (let i = respuestas.length-1; i >= 0; i--) {
          respuestas.removeAt(i);
        }

        const answersFormArray = this.buildAnswers(question);

        for(let i = 0; i<answersFormArray.length; i++) {
          questionFormGroup.get('respuestas').push(answersFormArray['controls'][i]);
        }

        //this.checklist.preguntas[questionIndex].preguntasHijas[questionChildIndex] = question;

        this.checklistForm.markAsDirty();
      }
    });
  }

  getTypeAnswerName(answer) {
    var tipoRespuesta = this.tiposRespuestas.find(t => t.id === answer.idTipoRespuesta);
    return tipoRespuesta ? tipoRespuesta.name : 'Sin tipo de respuesta';
  }

  addAnswer(questionIndex) {
    const answersFormGroup = this.checklistForm.get('preguntas')['controls'][questionIndex].get('respuestas')['controls'][0];
    //const questionChilds = this.checklistForm.get('preguntas')['controls'][questionIndex].get('preguntasHijas').length;

   /* if (answersFormGroup && answersFormGroup.get('idTipoRespuesta').value === 8 && questionChilds > 0) {
      this.app.showSnackbar('¡Aviso!', 'Para agregar mas de una respuesta ocupas borrar las preguntas hijas.', 3000, 'warning');

      return;
    }*/

    const checklist = this.checklistForm.getRawValue();

    const dialogRef = this.dialog.open(AnswerComponent, {
      width: '80%',
      //disableClose: true,
      data: {
        esCredito: this.idTipoCredito,
        tiposRespuestas: this.tiposRespuestaAnswer,
        acciones: this.acciones,
        etiquetasAsignadas: this.checklist.etiquetasAsignadas,
        nameAnswerRequired: checklist.preguntas[questionIndex].respuestas.length > 0,
        soloCheckBox: checklist.preguntas[questionIndex].respuestas.length > 0 && this.checklist.preguntas[questionIndex].respuestas[0].idTipoRespuesta == 6,
        preguntaActual: this.checklist.preguntas[questionIndex],
        preguntas: this.checklist.preguntas,
        variablesCuentas: this.variablesCuentas
      }
    });

    dialogRef.afterClosed().subscribe(answer => {
      if (answer) {
        const answers = this.checklistForm.get('preguntas')['controls'][questionIndex].get('respuestas');
        const controls = answers['controls'];

        if (controls.length === 1) {
          for (let control of controls) {
            const nameAnswer = control.get('nombreRespuesta');

            nameAnswer.setValidators(Validators.required);
            nameAnswer.updateValueAndValidity();
          }
        }

        answers.push(this.formBuilder.group({
          idChecklistRespuesta: new FormControl(answer.idChecklistRespuesta),
          idAccionRespuesta: new FormControl(answer.idAccionRespuesta),
          accion: new FormControl(answer.accion),
          idTipoRespuesta: new FormControl(answer.idTipoRespuesta),
          mostrarPreguntasCuando: new FormControl(answer.mostrarPreguntasCuando || false),
          nombreRespuesta: new FormControl(answer.nombreRespuesta, controls.length > 0 ? Validators.required : null),
          minCaracteres: new FormControl(answer.minCaracteres || 0),
          maxCaracteres: new FormControl(answer.maxCaracteres || 0),
          options: new FormControl(answer.options.slice()),
          columnas: new FormControl(answer.columnas ? answer.columnas.slice() : []),
          requerida: new FormControl(answer.requerida),
          valorPreLlenado: new FormControl(answer.valorPreLlenado || null),
          preLlenarRespuesta: new FormControl(answer.valorPreLlenado ? true : false)
        }));

        this.checklist.preguntas[questionIndex].respuestas.push(answer);

        this.checklistForm.markAsDirty();
      }
    });
  }

  editAnswer(questionIndex, answerIndex) {
    const checklist = this.checklistForm.getRawValue();
    const answerFormGroup = checklist.preguntas[questionIndex].respuestas[answerIndex];
    answerFormGroup.condiciones = this.checklist.preguntas[questionIndex].respuestas[answerIndex].condiciones;
    answerFormGroup.columnas = this.checklist.preguntas[questionIndex].respuestas[answerIndex].columnas;
    answerFormGroup.blasters = this.checklist.preguntas[questionIndex].respuestas[answerIndex].blasters;

    const dialogRef = this.dialog.open(AnswerComponent, {
      width: '80%',
      //disableClose: true,
      data: {
        esCredito: this.idTipoCredito,
        tiposRespuestas: this.tiposRespuestaAnswer,
        acciones: this.acciones,
        etiquetasAsignadas: this.checklist.etiquetasAsignadas,
        answer: answerFormGroup,
        nameAnswerRequired: checklist.preguntas[questionIndex].respuestas.length > 1,
        soloCheckBox: checklist.preguntas[questionIndex].respuestas.length > 1 && this.checklist.preguntas[questionIndex].respuestas[1].idTipoRespuesta == 6,
        preguntaActual: this.checklist.preguntas[questionIndex],
        preguntas: this.checklist.preguntas,
        variablesCuentas: this.variablesCuentas
      }
    });

    dialogRef.afterClosed().subscribe(answer => {
      if (answer) {
        const answerFormGroup = this.checklistForm.get('preguntas')['controls'][questionIndex].get('respuestas')['controls'][answerIndex];
        const respuestaActual = this.checklist.preguntas[questionIndex].respuestas[answerIndex];
        const previousAnswer = checklist.preguntas[questionIndex].respuestas[answerIndex];
        respuestaActual.idAccionRespuesta = answer.idAccionRespuesta;
        respuestaActual.accion = answer.accion;
        this.checklistForm.get('preguntas')['controls'][questionIndex].get('respuestas')['controls'][answerIndex].get('idAccionRespuesta').setValue(answer.idAccionRespuesta);
        this.checklistForm.get('preguntas')['controls'][questionIndex].get('respuestas')['controls'][answerIndex].get('accion').setValue(answer.accion);

        answerFormGroup.get('idChecklistRespuesta').setValue(answer.idChecklistRespuesta);
        answerFormGroup.get('idAccionRespuesta').setValue(answer.idAccionRespuesta);
        answerFormGroup.get('accion').setValue(answer.accion);
        answerFormGroup.get('idTipoRespuesta').setValue(answer.idTipoRespuesta);
        answerFormGroup.get('mostrarPreguntasCuando').setValue(answer.mostrarPreguntasCuando || false);
        answerFormGroup.get('nombreRespuesta').setValue(answer.nombreRespuesta);
        answerFormGroup.get('options').setValue(answer.options.slice());
        answerFormGroup.get('valorPreLlenado').setValue(answer.valorPreLlenado || null);
        answerFormGroup.get('preLlenarRespuesta').setValue(answer.valorPreLlenado ? true : false);
        if(answer.columnas){
          answerFormGroup.get('columnas').setValue(answer.columnas ? answer.columnas.slice() : []);
          this.checklist.preguntas[questionIndex].respuestas[answerIndex].columnas = answer.columnas;
        }

        answerFormGroup.get('requerida').setValue(answer.requerida);

        this.checklist.preguntas[questionIndex].respuestas[answerIndex].idAccionRespuesta = answer.idAccionRespuesta;
        this.checklist.preguntas[questionIndex].respuestas[answerIndex].accion = answer.accion;
        this.checklist.preguntas[questionIndex].respuestas[answerIndex].idTipoRespuesta = answer.idTipoRespuesta;
        this.checklist.preguntas[questionIndex].respuestas[answerIndex].nombreRespuesta = answer.nombreRespuesta;
        this.checklist.preguntas[questionIndex].respuestas[answerIndex].options = answer.options;
        this.checklist.preguntas[questionIndex].respuestas[answerIndex].requerida = answer.requerida;
        this.checklist.preguntas[questionIndex].respuestas[answerIndex].condiciones = answer.condiciones;
        this.checklist.preguntas[questionIndex].respuestas[answerIndex].blasters = answer.blasters;
        this.checklist.preguntas[questionIndex].respuestas[answerIndex].minCaracteres = answer.minCaracteres;
        this.checklist.preguntas[questionIndex].respuestas[answerIndex].maxCaracteres = answer.maxCaracteres;
        this.checklist.preguntas[questionIndex].respuestas[answerIndex].valorPreLlenado = answer.valorPreLlenado || null;
        this.checklist.preguntas[questionIndex].respuestas[answerIndex].preLlenarRespuesta = answer.valorPreLlenado ? true : false;

        this.checklistForm.markAsDirty();
      }
    });
  }

  private limpiarCondiciones(preguntaActual: ChecklistPregunta,respuesta: ChecklistRespuesta){
    respuesta.condiciones = [];
    for (let i = 0; i < this.checklist.preguntas.length; i++) {
      const pregunta = this.checklist.preguntas[i];
      if(pregunta.idChecklistPreguntaPadre == preguntaActual.idChecklistPreguntaCondicion){
        pregunta.idChecklistPreguntaPadre = null;
        pregunta.condicionMostrar = null;
      }
    }
  }

  removeAnswer(questionIndex, answerIndex, forzarBorradoConAccion: boolean = false) {
    const answersFormGroup = this.checklistForm.get('preguntas')['controls'][questionIndex].get('respuestas')['controls'][0];
    const questionChilds = 0;
    const checklist = this.checklistForm.getRawValue();

    if (answersFormGroup.get('idTipoRespuesta').value === 8 && questionChilds > 0) {
      this.app.showSnackbar('¡Aviso!', 'Para eliminar la respuesta ocupas borrar las preguntas hijas.', 3000, 'warning');

      return;
    }

    if(checklist.preguntas[questionIndex].respuestas[answerIndex].accion && !forzarBorradoConAccion){
      const dialogRef = this.dialog.open(ConfirmacionDialog, {
          data:{
            titulo: "Respuesta con acción",
            mensaje: "¿Estas seguro de eliminar la respuesta con acción?",
            icono: "delete_forever",
            colorIcono: "warn",
            boton1: "No",
            boton2: "Sí",
            texto: null,
            claseAccion: "boton-accion-eliminar"
          }
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.removeAnswer(questionIndex, answerIndex, true);
          }
      });

      return;
    }

    //Si tiene condiciones hay que eliminar todas las validaciones de la condicion
    if(this.checklist.preguntas[questionIndex].respuestas[answerIndex].condiciones.length > 0){
      this.limpiarCondiciones(this.checklist.preguntas[questionIndex], this.checklist.preguntas[questionIndex].respuestas[answerIndex]);
    }

    this.checklist.preguntas[questionIndex].respuestas.splice(answerIndex, 1);

    const answers = this.checklistForm.get('preguntas')['controls'][questionIndex].get('respuestas');

    answers.removeAt(answerIndex);

    if (this.checklist.preguntas[questionIndex].respuestas.length === 1) {
      const controls = answers['controls'];
      this.checklist.preguntas[questionIndex].respuestas[0].nombreRespuesta = '';

      for (let control of controls) {
        const nameAnswer = control.get('nombreRespuesta');
        nameAnswer.value = '';
        nameAnswer.setValidators([]);
        nameAnswer.updateValueAndValidity();
      }
    }

    if (answers.length === 0) {
      answers.setErrors({ required: true });
    }

    this.checklistForm.markAsDirty();
  }

  openDialogFormato(formatosAfectados: any): void {
    const dialogRef = this.dialog.open(ModalFormatosAfectadosComponent, {
      disableClose: true,
      width: '40%',
      data:{
            idCliente:this.idCliente,
            archivos: formatosAfectados,
            mensaje: 'Las plantillas mostradas se han visto afectadas por los cambios en el cuestionario. Por favor, verifica y corrige las plantillas correspondientes.'
          }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.cancel(true);
      }
    });
  }

  // #endregion
}
