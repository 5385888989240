import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function urlValidator(tipo: 'general' | 'imagen'): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || control.value.trim() === '') {
      return null; // No validar si está vacío
    }

    // Expresiones regulares para cada tipo de URL
    const generalRegex = /^(https?:\/\/)([\w\-]+(\.[\w\-]+)+)(:[0-9]+)?(\/[\w\-]*)*(\?[\w\-=&]*)?(#[\w\-]*)?$/i;
    const imagenRegex = /^(https?:\/\/)([\w\-]+(\.[\w\-]+)+)(:[0-9]+)?(\/[\w\-]*)*(\?[\w\-=&]*)?(#[\w\-]*)?(\.(jpg|jpeg|png|gif|bmp|webp|svg))$/i;

    const isValidGeneral = generalRegex.test(control.value);
    const isValidImagen = imagenRegex.test(control.value);


    // Validar según el tipo
    if (tipo === 'general' && isValidGeneral) {
      return null;
    }
    if (tipo === 'imagen' && isValidGeneral ) {
      return null;
    }

    // Si no pasa la validación, retorna un error
    return { invalidUrl: true };
  };
}
