export class Tutorial {
    idTutorial: number;
    titulo: string;
    idFinanciera: number;
    idCliente: number;
    nombreCliente: string;
    idProducto: number;
    editable: boolean;
    nombreProducto: string;
    idUsuario: number;
    nombreUsuario: string;
    fechaCreacion: Date;

    public static map(data: any): Tutorial{
        let instance:Tutorial = new Tutorial();
        instance.idTutorial = data.idTutorial || 0;
        instance.titulo = data.titulo || '';
        instance.idFinanciera = data.idFinanciera || 0;
        instance.idCliente = data.idCliente || 0;
        instance.nombreCliente = data.nombreCliente || '';
        instance.idProducto = data.idProducto || 0;
        instance.editable = data.editable || true;
        instance.nombreProducto = data.nombreProducto || '';
        instance.idUsuario = data.idUsuario || 0;
        instance.nombreUsuario = data.nombreUsuario || '';
        instance.fechaCreacion = data.fechaCreacion || '';

        return instance;
    }

    public static mapArray(data: any[]): Tutorial[]{
        let instance:Tutorial[] = [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            instance.push(Tutorial.map(element));
        }
        return instance;
    }
}



