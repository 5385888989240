import { FiltrosMap } from './../../application/shared/filtros.service';
import { Lender } from "../../lender/lender.model";



export class FiltrosAgentesRecorridos extends FiltrosMap {
  financiera: Lender | null;
  idCliente: number;
  idAgente: number;
  idCobrador: number;
  fecRecorrido: Date | null;
  mostrarNombre: boolean;
  mostrarLinea: boolean;

    constructor() {
      super();
      this.map({});
    }

    public override map(data: any): void {
        this.financiera = data.financiera ? Lender.map(data.financiera) : null;
        this.idCliente = data.idCliente || null;
        this.idAgente = data.idAgente || null;
        this.idCobrador = data.idCobrador || null;
        this.fecRecorrido = data.fecRecorrido ? new Date(data.fecRecorrido) : null;
        this.mostrarNombre = data.mostrarNombre === false ? false : true;
        this.mostrarLinea = data.mostrarLinea === false ? false : true;
    }
}
