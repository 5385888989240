import { Injectable } from '@angular/core';
import { Observable, throwError } from "rxjs";
import { Pagination } from "../pagination/pagination.model";
import { Request } from "./request.model";
import { Estatus } from "./estatus.model";
import { EstatusFinancieras } from "./estatus-financieras.model";
import { TiposCredito } from "./tipos-credito.model";
import { RequestPaginator } from "./request.paginator";
import { map, catchError } from "rxjs/operators";
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { StorageKobraService } from '../core/http/storage-kobra/storage-kobra.service';
import { environmentSelector } from 'src/environments/environment.selector';
import * as moment from 'moment';
import { Configuracion } from './configuracion.interface';
import { ServiciosKobraResponse } from '@servicios/servicios-kobra-response';

@Injectable()
export class RequestService {
  baseURL: string = environmentSelector().kobraServices.visitas;


  private apiService: string = '/v2/visits';
  private serviceURL: string = '/visitas';
  private resource: string = '/visitas';
  private leanServicesURL = '/visitas';
  public momentFecha;


  constructor(
    private serviciosVisitas: ServicesVisitasService,
    private serviciosStorage: StorageKobraService
  ) { this.momentFecha = moment; }


  public obtenerListado( pagination: Pagination, extraParams: string, rows: number): Observable<RequestPaginator> {
    let start = 0;

    if (pagination.nextPage > 1) {
      start = ((pagination.nextPage - 1) * rows) + 1;
    }
    let query: string = '/clientes/creditos/solicitudes?start=' + start + extraParams;

    return this.serviciosVisitas.get(query).pipe(
      map(res => {
        let response: any = res.data;
        let requestPaginator: RequestPaginator = new RequestPaginator();
        requestPaginator.pagination = new Pagination();
        requestPaginator.pagination.currentPage = response.page ? response.page : 1;
        requestPaginator.pagination.pageCount = response.pages;
        requestPaginator.pagination.perPage = rows;
        requestPaginator.pagination.totalCount = response.totalRows;
        requestPaginator.data = [];

        for (let i=0; i < response.pageData.length; i++){
            let request: Request = new Request();
            request.idSolicitudCredito = response.pageData[i].idSolicitudCredito;
            request.folio = response.pageData[i].folio;
            request.nombreTipoCredito = response.pageData[i].nombreTipoCredito;
            request.idTipoCredito = response.pageData[i].idTipoCredito;
            request.nombre = response.pageData[i].nombre;
            request.primerApellido = response.pageData[i].primerApellido;
            request.segundoApellido = response.pageData[i].segundoApellido;
            request.nombreCompleto = response.pageData[i].nombre +' '+ (response.pageData[i].primerApellido ? response.pageData[i].primerApellido : '') +' '+ (response.pageData[i].segundoApellido ? response.pageData[i].segundoApellido: '');
            request.idGenero = response.pageData[i].idGenero;
            request.genero = response.pageData[i].genero;
            request.fechaNacimiento = response.pageData[i].fechaNacimiento;
            request.telefonoCelular = response.pageData[i].telefonoCelular;
            request.telefonoTrabajo = response.pageData[i].telefonoTrabajo;
            request.telefonoCasa = response.pageData[i].telefonoCasa;
            request.email = response.pageData[i].email;
            request.curp = response.pageData[i].curp;
            request.rfc = response.pageData[i].rfc;
            request.calle = response.pageData[i].calle;
            request.numeroExterior = response.pageData[i].numeroExterior;
            request.numeroInterior = response.pageData[i].numeroInterior;
            request.entreCalles = response.pageData[i].entreCalles;
            request.idColonia = response.pageData[i].idColonia;
            request.colonia = response.pageData[i].colonia;
            request.idCodigoPostal = response.pageData[i].idCodigoPostal;
            request.codigoPostal = response.pageData[i].codigoPostal;
            request.idMunicipio = response.pageData[i].idMunicipio;
            request.municipio = response.pageData[i].municipio;
            request.idEstado = response.pageData[i].idEstado;
            request.estado = response.pageData[i].estado;
            request.latitudFin = response.pageData[i].latitudFin;
            request.longitudFin = response.pageData[i].longitudFin;
            request.archivoFirmaRuta = response.pageData[i].archivoFirmaRuta;
            request.archivoFirmaNombre = response.pageData[i].archivoFirmaNombre;
            request.montoSolicitado = response.pageData[i].montoSolicitado;
            request.montoPrestado = response.pageData[i].montoPrestado;
            request.idEstatus = response.pageData[i].idEstatus;
            request.estatus = response.pageData[i].estatus;
            request.estatusFinanciera = response.pageData[i].estatusFinanciera;
            request.idAgente = response.pageData[i].idAgente;
            request.nombreCompletoAgente = response.pageData[i].nombreCompletoAgente;
            request.idCliente = response.pageData[i].idCliente;
            request.nombreCliente = response.pageData[i].nombreCliente;
            request.fechaRegistro = response.pageData[i].fechaRegistro;
            request.idFinanciera = response.pageData[i].idFinanciera;
            request.agente = response.pageData[i].idCobrador + ' - ' + response.pageData[i].nombreCompletoAgente;

            if(response.pageData[i].idTipoPersona === 1) {
              request.tipoPersona = 'Fisica';
            }else {
              request.tipoPersona = 'Moral';
            }

            requestPaginator.data.push(request);
        }

        return requestPaginator;
      })
    );
  }

  public getEstatus(): Observable<Estatus[]> {

    return this.serviciosVisitas.get('/clientes/creditos/estatus').pipe(
      map((res) => <Estatus[]> res.data)
    );
  }

  public getEstatusFinancieras( idFinanciera: number ): Observable<EstatusFinancieras[]> {
    return this.serviciosVisitas.get( '/clientes/creditos/estatus-financiera?idFinanciera=' + idFinanciera ).pipe(
      map((res) => <EstatusFinancieras[]> res.data)
    );
  }

  public getTiposCredito(): Observable<TiposCredito[]> {

    return this.serviciosVisitas.get('/clientes/creditos/tipos').pipe(
      map((res) => <TiposCredito[]> res.data)

    );
  }

  public getOne(id, idFinanciera , queryParams) : Observable<Request>{
    let query: string = '/clientes/'+idFinanciera+'/creditos/solicitudes/'+ id;

    return this.serviciosVisitas.get(query).pipe(
      map(res => {
        let response: any = res.data;

        let request: Request = new Request();


        request.idSolicitudCredito = response.idSolicitudCredito;
        request.nombreTipoCredito = response.nombreTipoCredito;
        request.idTipoCredito = response.idTipoCredito;
        request.nombre = response.nombre;
        request.primerApellido = (response.primerApellido ? response.primerApellido : '');
        request.segundoApellido = (response.segundoApellido ? response.segundoApellido : '');
        request.idGenero = response.idGenero;
        request.genero = response.genero;
        request.fechaNacimiento = response.fechaNacimiento;
        request.telefonoCelular = response.telefonoCelular;
        request.telefonoTrabajo = response.telefonoTrabajo;
        request.telefonoCasa = response.telefonoCasa;
        request.email = response.email;
        request.curp = response.curp;
        request.rfc = response.rfc;
        request.calle = response.calle;
        request.numeroExterior = response.numeroExterior;
        request.numeroInterior = response.numeroInterior;
        request.entreCalles = response.entreCalles;
        request.idColonia = response.idColonia;
        request.colonia = response.colonia;
        request.idCodigoPostal = response.idCodigoPostal;
        request.codigoPostal = response.codigoPostal;
        request.idMunicipio = response.idMunicipio;
        request.municipio = response.municipio;
        request.idEstado = response.idEstado;
        request.estado = response.estado;
        request.latitudInicio = response.latitudInicio;
        request.longitudInicio = response.longitudInicio;
        request.latitudFin = response.latitudFin;
        request.longitudFin = response.longitudFin;
        request.archivoFirmaRuta = response.archivoFirmaRuta;
        request.archivoFirmaNombre = response.archivoFirmaNombre;
        request.montoSolicitado = response.montoSolicitado;
        request.montoPrestado = response.montoPrestado;
        request.idEstatus = response.idEstatus;
        request.estatus = response.estatus;
        request.idAgente = response.idAgente;
        request.nombreCompletoAgente = response.nombreCompletoAgente;
        request.idCliente = response.idCliente;
        request.nombreCliente = response.nombreCliente;
        request.fechaRegistro = this.momentFecha(response.fechaRegistro).format('DD/MM/YYYY');
        request.idFinanciera = response.idFinanciera;

        if(response.idTipoPersona === 1) {
          request.tipoPersona = 'Fisica';
        }else {
          request.tipoPersona = 'Moral';
        }

        return request;
      })
    );
  }

  public getSignature(idSolicitud, idFinanciera){
    let query: string = '/clientes/'+idFinanciera+'/creditos/solicitudes/'+idSolicitud+'/firma';
    return this.serviciosStorage.get(query).pipe(
      map(res => {
        let response: any = res.data;
        return response;
      })
    );
  }

  public obtenerConfiguracionValor(idFinanciera: number): Observable<Configuracion[]> {
    return this.serviciosVisitas.get(`/clientes/${idFinanciera}/configuraciones`).pipe(
      map((res: ServiciosKobraResponse) => res.data)
    );
  }

  public descargarSolicitudConCuestionario(params:string){
    return this.serviciosVisitas.get( `/solicitudes-grid-download${params}`);
  }
}
