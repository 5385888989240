<router-outlet (activate)="hijosActivos = true" (deactivate)="hijosActivos = false; inactivarHijo()"></router-outlet>
<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <ol class="lista-filtros-side">
    <div class="div-filtros-side">
      <li class="filtro-side">
        <mat-select-search
          class="id-card-field" 
          [label]="'Cliente'"
          [placeHolder]="'Selecciona un cliente'"
          [value]="'idFinanciera'"
          [labelField]="'nombre'"
          [icon]="'account_balance'"
          [options]="lenders"
          [(ngModel)]="idLender"
          (ngModelChange)="onChangeLender($event);"
          [ngModelOptions]="{standalone: true}"
          (input)="truncateInput($event, 'financiera')"
        >
        </mat-select-search>
      
      </li>
      <li class="filtro-side">
        <mat-select-search
              [label]="'Producto'"
              [placeHolder]="'Selecciona un producto'"
              [value]="'idProducto'"
              [labelField]="'nomProducto'"
              [icon]="'inventory_2'"
              [options]="productos"
              [(ngModel)]="idProducto"
              (ngModelChange)="onSelectProduct($event)"
              class="id-card-field"
              (input)="truncateInput($event, 'idProducto')"
              >
        </mat-select-search>
          
        <!-- <div align="left" *ngIf="filters.nombre" class="limpiar-seleccion-kobra">
          <a (click)="filters.nombre = null"><i>Limpiar</i></a>
        </div> -->
      </li>
    </div>
    
    <button
    class="boton-accion-front"
      style="width: 100%;"
      mat-stroked-button
      color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; filtrar(1)">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="limpiarFiltros()">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>

<div class="app content tabla kobra" *ngIf="tutoriales && !hijosActivos">
  <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros" 
              (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
              matTooltip="Filtrar tutoriales"
              mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">filter_alt</mat-icon>
              <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>

          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            (click)="loadTutoriales(filters.page ? filters.page : 1);"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda;">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
  </div>

  <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="agregarTutorial()">
          <mat-icon class="kobra-icon">add</mat-icon>
          <span>Nuevo</span>
      </button>
      <button mat-menu-item [disabled]="itemsSelected.length !== 1 || loadings.borrando || !itemsSelected[0].editable" (click)="editarTutorial()">
        <mat-icon class="kobra-icon">edit</mat-icon>
        <span>Editar</span>
      </button>
      <button mat-menu-item [disabled]="itemsSelected.length !== 1 || loadings.borrando || !itemsSelected[0].editable" (click)="openDialogConfirmacion(itemsSelected[0])">
      <mat-icon color="warn">delete_forever</mat-icon>
      <span>Eliminar</span>
      </button>
  </mat-menu>

  <app-table class="table-margen"
        [classContainer]="'tabla-completa'"
        [loading]="loadings.conceptosProductos"
        [columns]="tabla.columns"
        [items]="tutoriales"
        [headers]="tabla.headers"
        [multiSelect]="tabla.multiSelect"
        (onSelectedRows)="onSelected($event)"
        (sortChange)="sortChange($event)"
        (onDoubleClick)="onDoubleClick($event)"
        >
        <div t-header>
            <mat-card *ngIf="ayuda" class="cardAyuda">
            <mat-card-content>
            <p><mat-icon color="primary">help</mat-icon> 
                El módulo de Tutoriales está diseñado para ofrecer un control detallado sobre los tutoriales que se ligan a loss clientes y a los productos para que cuando un agente en campo pueda visualizar cuales son las cosas especificas que debe hacerse en la visita, para lograr una mejor calidad.
                Las características y funcionalidades principales de esta vista son:
                </p> 
            Acciones:
            <p>
                <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda y el acceso a los tutoriales por Cliente y Producto.
            </p>
            <p>
                <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de filtros aplicados.
            </p>
            <p>
                <mat-icon class="kobra-icon">add</mat-icon><b> Nuevo: </b> Los usuarios pueden añadir nuevos tutoriales al sistema.
            </p>
            <p>
                <mat-icon class="kobra-icon">edit</mat-icon><b> Editar: </b> Esta función posibilita la actualización y modificación de los tutoriales, asegurando que la información sea precisa y esté actualizada.
            </p>
            <p>
                <mat-icon color="warn">delete_forever</mat-icon><b> Eliminar: </b> Ofrece la opción de eliminar tutoriales del sistema, una funcionalidad crucial para mantener la relevancia y precisión del catálogo de tutoriales.
            </p>
            </mat-card-content>
        </mat-card> 
        </div>

        <ng-container t-paginator>
            <mat-paginator 
            showFirstLastButtons
            [length]="pagination?.totalRows"
            [pageIndex]="pagination?.page-1"
            [pageSize]="datosConsulta.rows"
            (page)="onPage($event)"
            aria-label="Select page of periodic elements">
            </mat-paginator>
        </ng-container>
  </app-table>
</div>
